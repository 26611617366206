import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import Box from '@material-ui/core/Box';
import { ChipsAndInput } from '../../components';
import { cvInfoActions } from '../../store/cvInfo';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        flexDirection: "column",
        justifyContent: 'center',
    },
    textField: {
        display: 'flex',
        marginTop: theme.spacing(1)
    },
    chip: {
        margin: theme.spacing(0.5)
    }
  }));

export const InterestsAndHobbies: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch();
  const cvInfo = useSelector((state: RootState) => state.cvInfo.byId[id]);
  const classes = useStyles();

  const onAddItem = (value: string) => {
    dispatch(cvInfoActions.hobbiesAdded({ value }));
  };

  const onDeleteItem = (index: number) => {
    dispatch(cvInfoActions.hobbiesDeleted({ index }));
  };

  return (
    <>
      <ChipsAndInput
        title={"Hobbies and Interests"}
        placeholder={"e.g. Digital painting"}
        chipsData={cvInfo.hobbies}
        onAddItem={onAddItem}
        onDeleteItem={onDeleteItem}
      />
    </>
  );
};