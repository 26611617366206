import React, { useEffect, useReducer, useState, Fragment, useRef, LegacyRef } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MailIcon, MenuIcon, PhoneIcon, XIcon } from "@heroicons/react/outline";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

import { Header3 } from "../../components/Header";
import { Body1, Button, Card, TextInput, TextInputOld } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { OutlinedTextFieldProps } from "@material-ui/core/TextField";
import { validateEmail } from "../../utils";
import { authActions } from "../../store/auth";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { CheckCircleIcon } from '@heroicons/react/solid'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
    flexDirection: "column",
    justifyContent: "center",
    marginTop: theme.spacing(4),
  },
  title: {
    marginLeft: theme.spacing(7),
  },
  textInput: {
    display: "flex",
  },
  leftTextInputsContainer: {
    flex: 0.4,
    paddingRight: theme.spacing(4),
  },
  card: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    justifyContent: "stretch",
    alignItems: "center",
  },
  messageInput: {
    flex: 0.6,
  },
  sendButtonContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(4),
  },
}));

export const ContactOld = () => {
  const globalDispatch = useDispatch();
  const sendMessageStatus = useSelector(
    (state: RootState) => state.auth.sendMessageStatus
  );
  const userEmail = useSelector((state: RootState) => state.auth.email);
  const classes = useStyles();
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    email: {
      value: userEmail,
    },
  });
  useEffect(() => {
    if (userEmail)
      dispatch(valueChangedAction({ field: "email", value: userEmail }));
  }, [userEmail, dispatch]);
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (sendMessageStatus === "success") {
      dispatch(clearFormRequestedAction());
      setIsSnackBarOpen(true);
      timeout = setTimeout(() => {
        setIsSnackBarOpen(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [sendMessageStatus]);

  const onChange = (
    field: "email" | "name" | "message"
  ): OutlinedTextFieldProps["onChange"] => ({ target: { value } }) => {
    dispatch(valueChangedAction({ field, value }));
  };

  const onSendClick = () => {
    if (!isFormValid(state)) {
      dispatch(validateFormRequestedAction());
      return;
    }

    globalDispatch(
      authActions.sendMessageRequested({
        email: state.email.value!,
        name: state.name.value!,
        message: state.message.value!,
      })
    );
  };

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Header3 className={classes.title}>Contact Us</Header3>
      <Body1 className={classes.title}>
        Have comments, questions, or feedback to share? We would love to hear
        from you. Please submit your message below.
      </Body1>
      <Box mt={2}>
        <Card>
          <div className={classes.card}>
            <div className={classes.leftTextInputsContainer}>
              <Box mb={2}>
                <TextInput
                  value={state.email.value}
                  onChange={onChange("email")}
                  label="email"
                  placeholder="yo"
                  type="email"
                  error={state.email.error}
                />
              </Box>
              <TextInput
                value={state.name.value}
                onChange={onChange("name")}
                label="name"
                error={state.name.error}
              />
            </div>
            <TextInput
              value={state.message.value}
              onChange={onChange("message")}
              label="message"
              error={state.message.error}
            />
          </div>
          <div className={classes.sendButtonContainer}>
            <Button
              isLoading={sendMessageStatus === "loading"}
              onClick={onSendClick}
              color="primary"
            >
              Send
            </Button>
          </div>
        </Card>
      </Box>
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "center", horizontal: "center" } as any}
      >
        <Alert severity={"success"}>Successfully sent your message</Alert>
      </Snackbar>
    </Container>
  );
};

type ContactState = {
  email: {
    value?: string;
    error?: string;
  };
  name: {
    value?: string;
    error?: string;
  };
  message: {
    value?: string;
    error?: string;
  };
};

type Field = "email" | "name" | "message";

const VALUE_CHANGED = "VALUE_CHANGED";
const valueChangedAction = ({
  field,
  value,
}: {
  field: "email" | "name" | "message";
  value: string;
}) => ({
  type: VALUE_CHANGED as typeof VALUE_CHANGED,
  field,
  value,
});

const VALIDATE_FORM_REQUESTED = "VALIDATE_FORM_REQUESTED";
const validateFormRequestedAction = () => ({
  type: VALIDATE_FORM_REQUESTED as typeof VALIDATE_FORM_REQUESTED,
});

const CLEAR_FORM_REQUESTED = "CLEAR_FORM_REQUESTED";
const clearFormRequestedAction = () => ({
  type: CLEAR_FORM_REQUESTED as typeof CLEAR_FORM_REQUESTED,
});

type Action =
  | ReturnType<typeof valueChangedAction>
  | ReturnType<typeof validateFormRequestedAction>
  | ReturnType<typeof clearFormRequestedAction>;

const initialState: ContactState = {
  email: {},
  name: {},
  message: {},
};

const reducer = (state: ContactState, action: Action): ContactState => {
  switch (action.type) {
    case "VALUE_CHANGED":
      return {
        ...state,
        [action.field]: {
          value: action.value,
          error: undefined,
        },
      };
    case "VALIDATE_FORM_REQUESTED":
      const newState = { ...state };
      const validityArray = validateForm(state);
      validityArray.forEach(({ field, isValid }) => {
        newState[field] = {
          ...newState[field],
          error: isValid ? undefined : fieldToErorMap[field],
        };
      });

      return newState;
    case "CLEAR_FORM_REQUESTED":
      return {
        ...state,
        email: {
          ...state.email,
          error: undefined,
        },
        name: {
          value: "",
        },
        message: {
          value: "",
        },
      };
    default:
      return state;
  }
};

const validateForm = (
  state: ContactState
): { field: Field; isValid: boolean }[] => {
  const isEmailValid = state.email.value
    ? validateEmail(state.email.value)
    : false;
  const isNameValid = !!state.name.value;
  const isMessageValid = !!state.message.value;

  return [
    { field: "email", isValid: isEmailValid },
    { field: "name", isValid: isNameValid },
    { field: "message", isValid: isMessageValid },
  ];
};

const isFormValid = (state: ContactState) =>
  !validateForm(state).find(({ isValid }) => !isValid);

const fieldToErorMap: { [key in Field]: string } = {
  email: "Please, enter a valid email",
  message: "Please, enter a message",
  name: "Please, enter your name",
};

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          'gray': colors.warmGray,
          indigo: colors.indigo,
        }
      }
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/

const navigation = [
  { name: "Changelog", href: "#" },
  { name: "About", href: "#" },
  { name: "Partners", href: "#" },
  { name: "News", href: "#" },
];
const offices = [
  {
    id: 1,
    city: "Los Angeles",
    address: ["4556 Brendan Ferry", "Los Angeles, CA 90210"],
  },
  {
    id: 2,
    city: "New York",
    address: ["886 Walter Streets", "New York, NY 12345"],
  },
  {
    id: 3,
    city: "Toronto",
    address: ["7363 Cynthia Pass", "Toronto, ON N3Y 4H8"],
  },
  { id: 4, city: "London", address: ["114 Cobble Lane", "London N1 2EF"] },
];

export const Contact = () => {
    const sendMessageStatus = useSelector(
        (state: RootState) => state.auth.sendMessageStatus
      );

  return (
    <div className="min-h-screen bg-gray-50">
      <main className="overflow-hidden">
        {/* Header */}
        <div className="bg-gray-50">
          <div className="pt-16 pb-14">
            <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
                Get in touch
              </h1>
              <p className="mt-6 text-xl text-gray-500 max-w-3xl">
                Do you have questions or feedback to share? We would love to hear from you.
              </p>
            </div>
          </div>
        </div>

        {/* Contact section */}
        <section
          className="relative bg-gray-50"
          aria-labelledby="contactHeading"
        >
          <div
            className="absolute w-full h-full bg-gray-50 pb-20"
            aria-hidden="true"
          />
          {/* Decorative dot pattern */}
          <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <svg
              className="absolute z-0 top-0 right-0 transform -translate-y-16 translate-x-1/2 sm:translate-x-1/4 md:-translate-y-24 lg:-translate-y-72"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              />
            </svg>
          </div>
          <div className="max-w-7xl mx-auto pb-20 px-4 sm:px-6 lg:px-8">
            <div className="relative bg-white shadow-xl rounded-lg">
              <h2 id="contactHeading" className="sr-only">
                Contact us
              </h2>

              <div className="grid grid-cols-1 lg:grid-cols-3">
                {/* Contact information */}
                <div className="rounded-tr-lg rounded-tl-lg lg:rounded-tr-none lg:rounded-bl-lg relative overflow-hidden py-10 px-6 bg-gradient-to-b from-indigo-500 to-indigo-600 sm:px-10 xl:p-12">
                  {/* Decorative angle backgrounds */}
                  <div
                    className="absolute inset-0 pointer-events-none sm:hidden"
                    aria-hidden="true"
                  >
                    <svg
                      className="absolute inset-0 w-full h-full"
                      width={343}
                      height={388}
                      viewBox="0 0 343 388"
                      fill="none"
                      preserveAspectRatio="xMidYMid slice"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-99 461.107L608.107-246l707.103 707.107-707.103 707.103L-99 461.107z"
                        fill="url(#linear1)"
                        fillOpacity=".1"
                      />
                      <defs>
                        <linearGradient
                          id="linear1"
                          x1="254.553"
                          y1="107.554"
                          x2="961.66"
                          y2="814.66"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#fff" />
                          <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div
                    className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none sm:block lg:hidden"
                    aria-hidden="true"
                  >
                    <svg
                      className="absolute inset-0 w-full h-full"
                      width={359}
                      height={339}
                      viewBox="0 0 359 339"
                      fill="none"
                      preserveAspectRatio="xMidYMid slice"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-161 382.107L546.107-325l707.103 707.107-707.103 707.103L-161 382.107z"
                        fill="url(#linear2)"
                        fillOpacity=".1"
                      />
                      <defs>
                        <linearGradient
                          id="linear2"
                          x1="192.553"
                          y1="28.553"
                          x2="899.66"
                          y2="735.66"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#fff" />
                          <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div
                    className="hidden absolute top-0 right-0 bottom-0 w-1/2 pointer-events-none lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="absolute inset-0 w-full h-full"
                      width={160}
                      height={678}
                      viewBox="0 0 160 678"
                      fill="none"
                      preserveAspectRatio="xMidYMid slice"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M-161 679.107L546.107-28l707.103 707.107-707.103 707.103L-161 679.107z"
                        fill="url(#linear3)"
                        fillOpacity=".1"
                      />
                      <defs>
                        <linearGradient
                          id="linear3"
                          x1="192.553"
                          y1="325.553"
                          x2="899.66"
                          y2="1032.66"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#fff" />
                          <stop offset={1} stopColor="#fff" stopOpacity={0} />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <h3 className="text-lg font-medium text-white">
                    Contact information
                  </h3>
                  <p className="mt-6 text-base text-indigo-50 max-w-3xl">
                    Feel free to send us an email or fill out the form.
                  </p>
                  <dl className="mt-8 space-y-6">
                    {/* <dt>
                      <span className="sr-only">Phone number</span>
                    </dt>
                    <dd className="flex text-base text-indigo-50">
                      <PhoneIcon
                        className="flex-shrink-0 w-6 h-6 text-indigo-200"
                        aria-hidden="true"
                      />
                      <span className="ml-3">+1 (555) 123-4567</span>
                    </dd> */}
                    <dt>
                      <span className="sr-only">Email</span>
                    </dt>
                    <dd className="flex text-base text-indigo-50">
                      <MailIcon
                        className="flex-shrink-0 w-6 h-6 text-indigo-200"
                        aria-hidden="true"
                      />
                      <span className="ml-3">info@dreamcv.io</span>
                    </dd>
                  </dl>
                </div>

                {/* Contact form */}
                <div className=" py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
                  {sendMessageStatus === "success" ? (
                    <SuccessMessage />
                  ) : (
                    <ContactForm />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

const ContactForm = () => {
    const email = useSelector((state: RootState) => state.auth.email);
    const sendMessageStatus = useSelector(
        (state: RootState) => state.auth.sendMessageStatus
      );
    const dispatch = useDispatch();
    const emailRef = useRef<HTMLInputElement | null>();
    const nameRef = useRef<HTMLInputElement | null>();
    const messageRef = useRef<HTMLTextAreaElement | null>();

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        
        const name = nameRef.current?.value;
        const email = emailRef.current?.value;
        const message = messageRef.current?.value;

        if (name && email && message)
            dispatch(
                authActions.sendMessageRequested({
                    email,
                    name,
                    message,
                })
            );
    }
    
    return (
        <div className="py-10 px-6 sm:px-10 lg:col-span-2 xl:p-12">
            <h3 className="text-lg font-medium text-gray-900">
            Send us a message
            </h3>
            <form
            onSubmit={onSubmit}
            action="https://inupyd5yba.execute-api.eu-west-1.amazonaws.com/dev/send-message"
            method="POST"
            className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
            <div>
                <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-900"
                >
                Email
                </label>
                <div className="mt-1">
                <input
                    id="email"
                    name="email"
                    type="email"
                    ref={ref => {
                      emailRef.current = ref;
                    }}
                    required
                    defaultValue={email}
                    autoComplete="email"
                    className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
                </div>
            </div>
            <div>
                <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-900"
                >
                    Name
                </label>
                <div className="mt-1">
                <input
                    type="text"
                    name="name"
                    id="name"
                    ref={ref => {
                        nameRef.current = ref; 
                    }}
                    required
                    autoComplete="name"
                    className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    aria-describedby="phone-optional"
                />
                </div>
            </div>
            <div className="sm:col-span-2">
                <div className="flex justify-between">
                <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-900"
                >
                    Message
                </label>
                <span
                    id="message-max"
                    className="text-sm text-gray-500"
                >
                    Max. 500 characters
                </span>
                </div>
                <div className="mt-1">
                <textarea
                    id="message"
                    name="message"
                    ref={ref => {
                    messageRef.current = ref;
                    }}
                    rows={4}
                    required
                    className="py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    aria-describedby="message-max"
                    defaultValue={""}
                />
                </div>
            </div>
            <div className="sm:col-span-2 sm:flex sm:justify-end">
                <Button
                isLoading={sendMessageStatus === "loading"}
                className="mt-2 w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none sm:w-auto"
                type="submit"
                >
                Submit
                </Button>
            </div>
            </form>
        </div>
    );
}

const SuccessMessage = () => {
    return (
        <div className="flex h-full items-center">
            <div className="w-full rounded-md bg-green-50 p-4">
                <div className="flex">
                <div className="flex-shrink-0">
                    <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-green-800">Your message has been received</h3>
                    <div className="mt-2 text-sm text-green-700">
                    <p>Thank you! We'll get back to you as soon as possible.</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
  }