import React, { FC } from 'react';
import ReactPDF, { Document, Page, Text, View, StyleSheet, Canvas, Link, Image, Font } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import searchBar from './assets/images/SearchBar.png';
import searchIcon from './assets/images/searchIcon.png';
import dictationIcon from './assets/images/dictationIcon.png';
import googleX from './assets/images/googleX.png';
import dropdownIcon from './assets/images/dropdownIcon.png';
import { CVInfo, EducationItem, ExperienceItem, Language, Project } from '../../../../types';

import ArialCE from './assets/fonts/ArialRegular.ttf';
import ArialCEMTBlack from './assets/fonts/ArialCEMTBlack.ttf';
import { shouldRenderEducations, shouldRenderExperiences, shouldRenderLanguages, shouldRenderLinks, shouldRenderProjects, shouldRenderStringArray } from '../utils';

Font.register({
    family: 'ArialCE',
    format: "truetype",
    src: ArialCE
});

Font.register({
    family: 'ArialCEMTBlack',
    format: "truetype",
    src: ArialCEMTBlack
});

const textBlack = '#222';
const textBlue = '#1A0DAB';
const textGray = '#70757A';
const borderGray = '#DFE1E5';

export const Google: FC<{ cvInfo: CVInfo, isPro: boolean }> = ({ cvInfo, isPro }) => {
    return (
        <Document
            title={`${cvInfo.firstName}${cvInfo.lastName}Resume`}
            author={`${cvInfo.firstName} ${cvInfo.lastName}`}
        >
            <Page size="A4" style={styles.page}>
                <SearchBar firstName={cvInfo.firstName} lastName={cvInfo.lastName} />
                <Text style={{ fontFamily: 'ArialCE', fontSize: 11, color: '#70757A', paddingHorizontal: 20 }}>
                    {`About 1.580 results (0.57 seconds)`}
                </Text>
                <View style={{ marginHorizontal: 20 }}>
                    {/* Name */}
                    <Text style={{ fontFamily: 'ArialCEMTBlack', fontSize: 20, color: textBlack, marginTop: 10, marginBottom: 2 }}>
                        {`${cvInfo.firstName} ${cvInfo.lastName}`}
                    </Text>

                    {/* Contacts */}
                    <ContactText label="email">
                        {cvInfo.email}
                    </ContactText>
                    <ContactText label="phone">
                        {cvInfo.phone}
                    </ContactText>
                    <ContactText label="address">
                        {cvInfo.address}
                    </ContactText>

                    {/* Links */}
                    <LinkButtons links={cvInfo.links} />

                    {/* Main body */}
                    <View style={{ flexDirection: 'row', marginTop: 15, justifyContent: 'space-between' }}>
                        {/* Experience */}
                        <ExperienceSection experienceItems={cvInfo.experienceItems} />
                        <View style={{ flex: 0.45 }}>
                            {(shouldRenderEducations(cvInfo.educationItems) || shouldRenderStringArray(cvInfo.awards) || shouldRenderStringArray(cvInfo.skills)) ? (
                                <View style={{ borderWidth: 1, borderColor: borderGray, borderRadius: 8, marginLeft: 10, overflow: 'hidden' }}>
                                    <EducationSection educationItems={cvInfo.educationItems} />
                                    <AwardsSection awards={cvInfo.awards} />
                                    <SkillsSection skills={cvInfo.skills} />
                                    <ProjectsSection projects={cvInfo.projects} />
                                    <LanguagesSection languages={cvInfo.languages} />
                                </View>
                            ) : null}
                            <HobbiesSection hobbies={cvInfo.hobbies} firstName={cvInfo.firstName} />
                        </View>
                    </View>
                </View>
                {isPro && (
                    <Text fixed style={{ position: 'absolute', left: 0, right: 0, bottom: 0, height: 20, textAlign: 'center', fontFamily: 'ArialCE', fontSize: 11, color: textBlack }} render={({ pageNumber, totalPages }) => (
                        totalPages > 1 ? `Page ${pageNumber} of ${totalPages}` : ''
                    )} />
                )}
                {!isPro && (
                    <View
                    fixed
                    style={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        bottom: 0,
                        height: 35,
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "white"
                    }}
                    >
                    <Text
                        style={{
                        fontSize: 11,
                        color: textBlue,
                        fontFamily: "ArialCE",
                        }}
                    >
                        {"Created using "}
                        <Link src="https://www.dreamcv.io/">
                        <Text
                            style={{
                            fontSize: 11,
                            color: textBlue,
                            fontFamily: "ArialCEMTBlack",
                            }}
                        >
                            dreamcv.io
                        </Text>
                        </Link>
                    </Text>
                    </View>
                )}
            </Page>
        </Document>
    )
}

const HobbiesSection: React.FC<{ hobbies: string[], firstName: string }> = ({ hobbies, firstName }) => {
    if (!shouldRenderStringArray(hobbies))
        return null;
    
    return (
        <View style={{ paddingLeft: 15, marginTop: 15 }} wrap={false}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderBottomWidth: 1, borderBottomColor: borderGray, alignItems: 'center', paddingBottom: 8 }}>
                <Text style={{ fontFamily: 'ArialCE', fontSize: 15, color: textBlack }}>
                    People also ask
                </Text>
                <Image src={dropdownIcon} style={{ width: 24, height: 24 }} />
            </View>
            <Text style={{ fontFamily: 'ArialCE', fontSize: 12, color: textBlack, marginTop: 7 }}>
                {`What are ${firstName}'s hobbies?`}
            </Text>
            <Text style={{ fontFamily: 'ArialCE', fontSize: 11, color: textBlack, marginTop: 7, lineHeight: 1.3 }}>
                {`${firstName}'s hobbies include: `}
                {hobbiesToComponents(hobbies)}
            </Text>
        </View>
    );
}

const hobbiesToComponents = (hobbies: string[]) => {
    const shouldAddComma = (index: number) => index < hobbies.length - 1 && index !== hobbies.length - 2;

    let components: any = [];
    hobbies.forEach((hobby, index) => {
        components.push(
            <Text style={{ fontFamily: 'ArialCEMTBlack', fontSize: 11, color: textBlack }}> 
                {hobby + (shouldAddComma(index) ? ', ' : '')}
            </Text>
        );
    });

    if (components.length > 1) {
        const index = components.length - 1;
        components.splice(index, 0, (
            <Text style={{ fontFamily: 'ArialCE', fontSize: 11, color: textBlack }}> 
                {` and `}
            </Text>
        ));
    }

    return components;
}

const SkillsSection: React.FC<{ skills: string[] }> = ({ skills }) => {
    if (!shouldRenderStringArray(skills))
        return null;
    
    return (
        <View style={{ paddingVertical: 15, paddingLeft: 15, paddingRight: 10 }}> 
            <Text style={{ fontFamily: 'ArialCE', fontSize: 15, color: textBlack, marginBottom: 8 }}>
                Skills
            </Text>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {skills.map(skill => {
                    if (!skill)
                        return null;
                    return (
                        <View style={{ marginRight: 7, marginTop: 7, borderRadius: 13, backgroundColor: '#F1F3F4', paddingVertical: 5, paddingHorizontal: 10 }}>
                            <Text style={{fontFamily: 'ArialCE', fontSize: 11, color: textBlack }}>
                                {skill}
                            </Text>
                        </View>
                    )
                })}
            </View>
        </View>
    )
}

const AwardsSection: React.FC<{ awards: string[] }> = ({ awards }) => {
    if (!shouldRenderStringArray(awards))
        return null;
    
    return (
        <View style={{ borderBottomColor: borderGray, borderBottomWidth: 1, padding: 15 }}> 
            <Text style={{ fontFamily: 'ArialCE', fontSize: 15, color: textBlack, marginBottom: 8 }}>
                Awards
            </Text>
            {awards.map(award => {
                if (!award)
                    return null;
                return (
                    <View style={{ flexDirection: 'row', marginTop: 7 }}>
                        <Text style={{fontFamily: 'ArialCE', fontSize: 11, color: textBlack}}>
                            {'•  '}
                        </Text>
                        <Text style={{fontFamily: 'ArialCE', fontSize: 11, color: textBlack, flexWrap: 'wrap', paddingRight: 10 }}>
                            {award}
                        </Text>
                    </View>
                )
            })}
        </View>
    )
}

const EducationSection: React.FC<{ educationItems: EducationItem[] }> = ({ educationItems }) => {
    if (!shouldRenderEducations(educationItems))
        return null;
    
    return (
        <View style={{ borderBottomWidth: 1, borderBottomColor: borderGray, padding: 15 }}>
            <Text style={{ fontFamily: 'ArialCE', fontSize: 15, color: textBlack }}>
                Education
            </Text>
            {educationItems.map((educationItem) => (
                <EducationItemComponent educationItem={educationItem} />
            ))}
        </View>
    )
};

const EducationItemComponent: React.FC<{ educationItem: EducationItem }> = ({ educationItem }) => {
    return (
        <View style={{ marginTop: 15 }}>
            <Text style={{ fontSize: 12, fontFamily: 'ArialCEMTBlack', color: textBlack }}>
                {educationItem.instituteName}
            </Text>
            <Text style={{ fontSize: 12, fontFamily: 'ArialCEMTBlack', color: textBlack, marginTop: 5 }}>
                {educationItem.location}
            </Text>
            <Text style={{ fontSize: 12, fontFamily: 'ArialCE', color: textBlack, marginTop: 5 }}>
                {educationItem.degree}
            </Text>
            <Text style={{ fontSize: 11, fontFamily: 'ArialCE', color: textGray, marginTop: 5 }}>
                {educationItem.dates}
            </Text>
        </View>
    )
}

const ExperienceSection: React.FC<{ experienceItems: CVInfo['experienceItems'] }> = ({ experienceItems }) => {
    if (!shouldRenderExperiences(experienceItems))
        return null;
    
    return (
        <View style={{ flex: 0.5 }}>
            <Text style={{ fontFamily: 'ArialCE', fontSize: 15, color: textBlack }}>
                Experience
            </Text>
            <>
                {experienceItems.map(experienceItem => (
                    <ExperienceComponent experienceItem={experienceItem} />
                ))}
            </>
        </View>
    );
}

const ExperienceComponent: React.FC<{ experienceItem: ExperienceItem }> = ({ experienceItem }) => {
    return (
        <View style={{ marginTop: 15 }}>
            <Text style={{ fontSize: 12, fontFamily: 'ArialCEMTBlack', color: textBlue }}>
                {experienceItem.website ? (
                    <Link src={experienceItem.website}>
                        {experienceItem.companyName}
                    </Link>
                ) : (
                    experienceItem.companyName
                )}
                <Text style={{ fontSize: 12, fontFamily: 'ArialCE', color: textBlue }}>
                    {' – ' + experienceItem.position}
                </Text>
            </Text>
            <Text style={{ fontSize: 11, fontFamily: 'ArialCE', color: textGray, marginTop: 5 }}>
                {experienceItem.dates + (experienceItem.location ? (' | ' + experienceItem.location) : '')}
            </Text>
            {experienceItem.achievements.map(achievement => {
                if (!achievement)
                    return null;

                return (
                    <View style={{ flexDirection: 'row', marginTop: 7 }} wrap={false}>
                        <Text style={{ fontSize: 11, fontFamily: 'ArialCE', color: textBlack }}>
                            {'•  '}
                        </Text>
                        <Text style={{ fontSize: 11, fontFamily: 'ArialCE', color: textBlack }}>
                            {achievement}
                        </Text>
                    </View>
                );
            })}
        </View>
    );
}

const LinkButtons: React.FC<{ links: CVInfo['links'] }>  = ({ links }) => {
    if (!shouldRenderLinks(links))
        return null;
    return (
        <View style={{ flexDirection: 'row', marginTop: 10 }}>
            {links.map((link, index) => (
                <View style={{ flexDirection: 'row' }}>
                    <Link src={link.link} style={{ fontSize: 11, fontFamily: 'ArialCE', color: textBlue }}>
                        {link.title}
                    </Link>
                    {index !== links.length - 1 && (
                        <Text style={{ fontSize: 11, fontFamily: 'ArialCE', color: textBlue, marginHorizontal: 5 }}>
                            •
                        </Text>
                    )}
                </View>
            ))}
        </View>
    );
}

const ContactText: React.FC<{ label: string; children: string }> = ({ label, children }) => {
    if (!children)
        return null;

    return (
        <Text style={{ fontFamily: 'ArialCEMTBlack', fontSize: 11, color: textBlack, marginTop: 3 }}>
            {label + ': '}
            <Text style={{ fontFamily: 'ArialCE', fontSize: 11 }}>
                {children}
            </Text>
        </Text>
    );
}

const SearchBar: React.FC<{ firstName?: string, lastName?: string }> = ({ firstName, lastName }) => {
    return (
        <View style={{ height: 42 }}>
            <Image src={searchBar} style={{ height: 42 }} />
            <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, borderRadius: 15, paddingHorizontal: 20, justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ fontFamily: 'ArialCE', fontSize: 11, color: textBlack }}>
                    {`${firstName} ${lastName}`}
                </Text>
                <View style={{ flexDirection: 'row', height: 18, marginRight: 3 }}>
                    <View style={{ paddingRight: 10, borderRightWidth: 1, borderRightColor: '#dfe1e5' }}>
                        <Image src={googleX} />
                    </View>
                    <Image src={dictationIcon} style={{ marginHorizontal: 10 }} />
                    <Link src={`https://www.google.com/search?q=${firstName}+${lastName}`}>
                        <Image src={searchIcon} />
                    </Link>
                </View>
            </View>
        </View>
    )
}

const LanguagesSection: React.FC<{ languages: Language[] }> = ({ languages }) => {
    if (!shouldRenderLanguages(languages))
        return null;
    
    return (
        <View style={{ borderTopColor: borderGray, borderTopWidth: 1, padding: 15 }}> 
            <Text style={{ fontFamily: 'ArialCE', fontSize: 15, color: textBlack, marginBottom: 8 }}>
                Languages
            </Text>
            {languages.map(language => {
                if (!language.name)
                    return null;
                return (
                    <View style={{ flexDirection: 'row', marginTop: 7 }}>
                        <Text style={{fontFamily: 'ArialCE', fontSize: 11, color: textBlack}}>
                            {'•  '}
                        </Text>
                        <Text style={{fontFamily: 'ArialCEMTBlack', fontSize: 11, color: textBlack, flexWrap: 'wrap' }}>
                            {language.name}
                        </Text>
                        <Text style={{fontFamily: 'ArialCE', fontSize: 11, color: textBlack, flexWrap: 'wrap', marginLeft: 5 }}>
                            {' –  ' + language.fluency}
                        </Text>
                    </View>
                )
            })}
        </View>
    )
}

const ProjectsSection: React.FC<{ projects: Project[] }> = ({ projects }) => {
    if (!shouldRenderProjects(projects))
        return null;
    
    return (
        <View style={{ borderTopColor: borderGray, borderTopWidth: 1, padding: 15 }}> 
            <Text style={{ fontFamily: 'ArialCE', fontSize: 15, color: textBlack, marginBottom: 8 }}>
                Projects
            </Text>
            {projects.map(project => {
                return (
                    <View style={{ marginTop: 7 }}>
                        <Link src={project.link} style={{ fontSize: 12, fontFamily: 'ArialCE', color: textBlue }}>
                            {project.title}
                        </Link>
                        <Text style={{ fontSize: 11, fontFamily: 'ArialCE', color: textGray, marginTop: 5 }}>
                            {project.description}
                        </Text>
                    </View>
                )
            })}
        </View>
    )
}

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        paddingTop: 5,
        paddingBottom: 20,
        paddingHorizontal: 5
    },
    nameText: {
        color: 'black',
        fontSize: 36, 
        fontWeight: 'extrabold', 
        fontFamily: 'BebasNeue'
    },
    sectionTitle: {
        fontSize: 18, 
        fontWeight: 'bold', 
        color: 'white', 
        fontFamily: 'HelveticaNeueBold'
    },
    section: {
        padding: 10,
        margin: 0
    },
    wrapper: {
        flex: 1,
    },
    regularText: {
        color: 'white', 
        fontSize: 14 
    }
});