import React, { FC } from 'react';
import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { Delete } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { cvInfoActions } from '../../store/cvInfo';
import { EducationItem } from '../../types';
import { Card, TextInput } from "../../components";


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        flexDirection: "column",
        justifyContent: 'center',
    },
    textField: {
        display: 'flex'
    }
  }));

export const EducationsForm: React.FC<{ id: string }> = ({ id }) => {
  const cvInfo = useSelector((state: RootState) => state.cvInfo.byId[id]);
  const classes = useStyles();
  const shouldRenderDeleteButton = cvInfo.educationItems.length > 1;

  return (
    <>
      {cvInfo.educationItems.map((educationItem, index) => (
        <EducationInputItem
          key={index}
          index={index}
          educationItem={educationItem}
          shouldRenderDeleteButton={shouldRenderDeleteButton}
        />
      ))}
    </>
  );
};

type EducationItemFields = keyof EducationItem;
type FormField = {
    field: EducationItemFields,
    type?: string,
    multiline?: boolean,
};

const fieldToLabel: { [key in EducationItemFields]: string } = {
    'instituteName' : 'Institute Name',
    'location': 'Location',
    'degree' : 'Degree',
    'dates' : 'Dates',
}

const fieldToPlaceHolder: { [key in EducationItemFields]: string } = {
    'instituteName' : 'e.g. Stanford University',
    'location' : 'e.g. California, U.S.',
    'degree' : 'e.g. B.S. in Computer Science',
    'dates' : 'e.g. Sept 2013 - May 2017',
}

const formFields: FormField[] = [
  { field: "instituteName", type: "text" },
  { field: "location", type: "text" },
  { field: "degree", type: "text" },
  { field: "dates", type: "text" },
];

const EducationInputItem: FC<{ index: number, educationItem: EducationItem, shouldRenderDeleteButton: boolean }> = ({ index, educationItem, shouldRenderDeleteButton }) => {
    const dispatch = useDispatch();
    
    const onChangeField = (field: FormField['field']): OutlinedTextFieldProps['onChange'] => ({ target: { value } }) => {
        dispatch(cvInfoActions.educationChanged({ index, field, value }));
    }

    const onAddClick = (position: 'before' | 'after') => () => {
        dispatch(cvInfoActions.educationAdded({ index, position }));
    }

    const onDeleteClick = () => {
        dispatch(cvInfoActions.educationDeleted({ index }));
    }

    return (
      <>
        {index === 0 && <AddButton onClick={onAddClick("before")} />}
        <Card contentClassName="border border-gray-200">
          {shouldRenderDeleteButton && (
              <Box mb={3}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={onDeleteClick}
                  startIcon={<Delete />}
                >
                  Delete
                </Button>
              </Box>
            )}
            {formFields.map((formField, index) => (
              <Box
                mb={index === formFields.length - 1 ? 0 : 2}
                key={formField.field}
              >
                <TextInput
                  label={fieldToLabel[formField.field]}
                  placeholder={fieldToPlaceHolder[formField.field]}
                  multiline={formField.multiline}
                  type={formField.type}
                  onChange={onChangeField(formField.field)}
                  value={educationItem[formField.field]}
                />
              </Box>
            ))}
        </Card>
        <AddButton onClick={onAddClick("after")} />
      </>
    );
}

const AddButton: FC<{ onClick: () => void }> = ({ onClick }) => (
    <Box mt={3} mb={3} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Button variant="outlined" color="primary" onClick={onClick} size='small'>
            Add another education here
        </Button>
    </Box>
);