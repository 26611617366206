import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { LanguagesForm } from './LanguagesForm';
import { ContactsForm } from './ContactsForm';
import { ExperiencesForm } from './ExperiencesForm';
import { EducationsForm } from './EducationsForm';
import { ProjectsForm } from './ProjectsForm';
import { ToolsAndSkillsForm } from './ToolsAndSkillsForm';
import { AwardsForm } from './AwardsForm';
import { InterestsAndHobbies } from "./InterestsAndHobbies";
import { Transition } from '@headlessui/react';
import { LinksForm } from "./LinksForm";
import { useDispatch, useSelector } from 'react-redux';
import { cvInfoActions } from '../../store/cvInfo';
import { RootState } from '../../store';
import { LoadingIndicator } from '../../components';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flex: 1,
    display: "flex",
    height: "100%",
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  flex1: {
    flex: 1,
    maxWidth: "100%",
  },
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  const classes = useStyles();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      className={classes.flex1}
      {...other}
    >
      <Transition
          show={value === index}
          enter="transform transition duration-[400ms]"
          enterFrom="opacity-0 translate-y-16"
          enterTo="opacity-100 translate-y-0"
          leave="transform duration-200 transition ease-in-out"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-16"
        >
        {children}
      </Transition>
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

type TabContent = {
  title: string;
  Component: React.FC<{ id: string }>;
}

const tabContents: TabContent[] = [
  {
    title: "Contacts",
    Component: ContactsForm
  },
  {
    title: "Links",
    Component: LinksForm
  },
  {
    title: "Experience",
    Component: ExperiencesForm
  },
  {
    title: "Education",
    Component: EducationsForm
  },
  {
    title: "Awards",
    Component: AwardsForm
  },
  {
    title: "Skills",
    Component: ToolsAndSkillsForm
  },
  {
    title: "Projects",
    Component: ProjectsForm
  },
  {
    title: "Languages",
    Component: LanguagesForm
  },
  {
    title: "Hobbies",
    Component: InterestsAndHobbies
  },
];

export const EdiComponent: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch();
  const email = useSelector((state: RootState) => state.auth.email);
  const selectedTabIndex = useSelector((state: RootState) => state.cvInfo.selectedEditTabIndex);
  const saveStatus = useSelector((state: RootState) => state.cvInfo.byId[id].updateStatus);

  const handleChange = (event: React.ChangeEvent<{}>, index: number) => {
    dispatch(cvInfoActions.setSelectedEditTabIndex({ index }));
  };

  return (
    <div className={"flex flex-1 flex-col sm:flex-row"}>
      <BigScreenTabs value={selectedTabIndex} handleChange={handleChange} />
      <SmallScreenTabs value={selectedTabIndex} handleChange={handleChange} />
      <div className="ml-0 sm:ml-4 w-full">
        {!!email && (
          <div className="flex row items-center text-xs text-gray-500 ml-3 mb-3">
            {saveStatus === 'loading' 
              ? <LoadingIndicator className="text-gray-500 h-3 w-3 mr-1" />
              : <CheckIcon />
            }
            {saveStatus === 'loading' ? 'Saving' : 'All changes saved'}
          </div>
        )}
        {
          tabContents.map(({ Component }, index) => (
            <TabPanel value={selectedTabIndex} index={index} key={index}>
              <div className="ml-0 sm:ml-3">
                <Component id={id} />
              </div>
            </TabPanel>
          ))
        }
      </div>
    </div>
  );
};

const BigScreenTabs: React.FC<{ value: number, handleChange(event: React.ChangeEvent<{}>, newValue: number): void }> = ({ value, handleChange }) => {
  return (
    <div className="hidden sm:flex min-h-full">
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className={"border-r border-gray-300 h-full"}
      >
        {
          tabContents.map(({ title }, index) => (
            <Tab
              className="focus:outline-none"
              label={title}
              {...a11yProps(index)}
            />
          ))
        }
      </Tabs>
    </div>
  )
}

const SmallScreenTabs: React.FC<{ value: number, handleChange(event: React.ChangeEvent<{}>, newValue: number): void }> = ({ value, handleChange }) => {
  return (
    <div className="flex self-center w-screen-1/3 sm:hidden mb-4">
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        scrollButtons="on"
      >
        {
          tabContents.map(({ title }, index) => (
            <Tab
              className="focus:outline-none"
              label={title}
              {...a11yProps(index)}
            />
          ))
        }
      </Tabs>
    </div>
  );
}

const CheckIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
  </svg>
)