import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'

const settings = [
  { name: 'Public access', description: 'This project would be available to anyone who has the link' },
  { name: 'Private to Project Members', description: 'Only members of this project would be able to access' },
  { name: 'Private to you', description: 'You are the only one able to access this project' },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

type RadioButtonOption = {
    name: string; 
    description: string;
    id: string;
}

type Props = {
    options: RadioButtonOption[]; 
    className?: string;
    onSelect(option: RadioButtonOption): void;
}

export const RadioButtons: React.FC<Props> = ({ options, className, onSelect }) => {
  const [selected, setSelected] = useState(options[0]);

  const onChange = (option: RadioButtonOption) => {
    setSelected(option);
    onSelect(option);
  }

  return (
    <RadioGroup value={selected} onChange={onChange} className={className}>
      <div className="bg-white rounded-md -space-y-px">
        {options.map((option, optionIdx) => (
          <RadioGroup.Option
            key={option.name}
            value={option}
            className={({ checked }) =>
              classNames(
                optionIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                optionIdx === options.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-indigo-500' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium text-left')}
                  >
                    {option.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm text-left')}
                  >
                    {option.description}
                  </RadioGroup.Description>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
