import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../store/auth';
import { RootState } from '../../store';
import { useHistory } from 'react-router';
import { AuthForm } from './AuthForm';
import { LogoButton, TextInput } from '../../components';
import { validateEmail } from '../../utils';

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/
export function Login() {
    const loginStatus = useSelector((root: RootState) => root.auth.loginStatus);
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
      if (loginStatus === "success") {
        history.push("/resumes");
        return;
      }
    }, [loginStatus, history]);

    const onSubmit = ({
      email,
      password,
    }: {
      email: string;
      password: string;
    }) => {
      dispatch(authActions.loginRequested({ email, password }));
    };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col">
        <LogoButton
          className="self-center"
          onClick={() => {
            history.push("/");
          }}
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Or{" "}
          <button
            className="font-medium text-indigo-600 hover:text-indigo-500"
            onClick={() => {
              history.push("/signup");
            }}
          >
            create an account
          </button>
        </p>
      </div>
      <AuthForm
        submitButtonTitle="Sign in"
        requestStatus={loginStatus}
        onSubmit={onSubmit}
      />
    </div>
  );
};