import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useDispatch, useSelector } from "react-redux";

import { PDFDocumentWrapper } from "./PDFDocumentWrapper";

import { CVTheme } from "../../types";
import { RootState } from "../../store";
import { Alert, Card } from "../../components";
import { cvInfoActions } from "../../store/cvInfo";
import { isProSelector } from "../../store/selectors";


// Using an object here not to forget to add new themes
const themesMap: { [key in CVTheme]: key } = {
  Google: 'Google',
  Netflix: 'Netflix',
  Twitter: 'Twitter',
  Github: 'Github',
  Wolt: 'Wolt'
};
const themes = Object.values(themesMap);

export const PreviewResume: React.FC<{ id: string; previewOnly?: boolean }> = ({
  id,
  previewOnly,
}) => {
  const cvInfo = useSelector((state: RootState) => state.cvInfo.byId[state.cvInfo.selectedId]);
  const [value, setValue] = useState(themes.findIndex(value => value === cvInfo.theme));
  const email = useSelector((state: RootState) => state.auth.email);
  const isPro = useSelector(isProSelector);
  const dispatch = useDispatch();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    dispatch(cvInfoActions.themeSelected({ theme: themes[newValue] }));
  };

  return (
    <Card contentClassName="flex flex-col md:flex-row">
      <BigScreenTabs value={value} handleChange={handleChange} />
      <SmallScreenTabs value={value} handleChange={handleChange} />
      <div className="flex flex-col w-full">
        {(!isPro && !previewOnly) && (
          <Alert type="info" className="mb-4">
              <p>
                <a
                  href={email ? "/pricing" : "/signup"}
                  className="font-medium underline"
                >
                  Upgrade your account
                </a>
                {'  to remove the dreamcv branding from your resume.'}
              </p>
          </Alert>
        )}
        <PDFDocumentWrapper id={id} previewOnly={previewOnly} />
      </div>
    </Card>
  );
};

const BigScreenTabs: React.FC<{
  value: number;
  handleChange(event: React.ChangeEvent<{}>, newValue: number): void;
}> = ({ value, handleChange }) => {
  return (
    <div className="hidden md:block min-h-full">
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className="border-r border-gray-300 mr-5 h-full"
      >
        {themes.map((theme) => (
          <Tab 
            className="flex row focus:outline-none" 
            label={theme} 
          />
        ))}
      </Tabs>
    </div>
  );
};

const SmallScreenTabs: React.FC<{
  value: number;
  handleChange(event: React.ChangeEvent<{}>, newValue: number): void;
}> = ({ value, handleChange }) => {
  return (
    <div className="block self-center md:hidden max-w-xxs sm:max-w-lg">
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        value={value}
        scrollButtons="on"
        onChange={handleChange}
        aria-label="Vertical tabs example"
        className="mb-4"
      >
        {themes.map((theme) => (
          <Tab className="focus:outline-none" label={theme} />
        ))}
      </Tabs>
    </div>
  );
};
