import { Fragment, useEffect, useState } from 'react'
import { Popover, Transition, Menu } from '@headlessui/react'
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
  DocumentTextIcon,
  CurrencyDollarIcon,
  MailIcon,
  UserCircleIcon as UserCircleIconOutlined,
  DocumentDuplicateIcon,
} from "@heroicons/react/outline";
import { UserCircleIcon } from '@heroicons/react/solid'
import { Button, LogoButton } from '../components'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import { useHistory, useLocation } from 'react-router-dom'
import { authActions } from '../store/auth'

const features = [
    {
        name: 'Profile settings',
        path: '/account',
        icon: UserCircleIconOutlined
    },
    {
        name: 'Resume builder',
        path: '/resume',
        icon: DocumentTextIcon
    },
    {
        name: 'Pricing',
        path: '/pricing',
        icon: CurrencyDollarIcon
    },
    {
        name: 'Contact us',
        path: '/contact',
        icon: MailIcon
    }
];

const loggedInPages = [
  {
    name: "Profile settings",
    path: "/account",
    icon: UserCircleIconOutlined,
  },
  {
    name: "Dashboard",
    path: "/resumes",
    icon: DocumentDuplicateIcon,
  },
  {
    name: "Pricing",
    path: "/pricing",
    icon: CurrencyDollarIcon,
  },
  {
    name: "Contact us",
    path: "/contact",
    icon: MailIcon,
  },
];

const loggedOutPages = [
  {
    name: "templates",
    path: "/#templates",
    icon: UserCircleIconOutlined,
  },
  {
    name: "Pricing",
    path: "/pricing",
    icon: CurrencyDollarIcon,
  },
  {
    name: "Contact us",
    path: "/contact",
    icon: MailIcon,
  },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ')
}

const isNavbarVisible = (pathname: string) => {
    if (pathname === "/")
        return true;
    
    const pathsWithoutNavbar = ["/login", "/signup"];
    return !pathsWithoutNavbar.includes(pathname);
}

export const NavBar = () => {
    const [open, setOpen] = useState(false);
    const email = useSelector((state: RootState) => state.auth.email);
    const history = useHistory();
    
    const location = useLocation();
    const shouldRenderNavbar = isNavbarVisible(location.pathname);
    
    if (!shouldRenderNavbar)
        return null;

    return (
      <Popover className="relative bg-white shadow overflow-visible">
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 overflow-visible">
            <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <LogoButton
                  onClick={() => {
                    history.push("/");
                  }}
                />
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                <Button
                  size="xs"
                  variant="text"
                  onClick={() => {
                    setOpen(true);
                  }}
                  className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Button>
              </div>
              <div className="hidden md:flex space-x-10">
                {!!email && (
                  (
                  <Button
                      size="xs"
                      variant="text"
                      onClick={() => {
                        history.push("/resumes");
                      }}
                    >
                      Dashboard
                    </Button>
                )
                )}
                <Button
                    size="xs"
                    variant="text"
                    onClick={() => {
                        history.push("/");
                    }}
                >
                    <a href="/#templates">
                        Templates
                    </a>
                </Button>
                <Button
                  size="xs"
                  variant="text"
                  onClick={() => {
                    history.push("/pricing");
                  }}
                >
                  Pricing
                </Button>
                <Button
                  size="xs"
                  variant="text"
                  onClick={() => {
                    history.push("/contact");
                  }}
                >
                  Contact us
                </Button>
              </div>
              <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0 overflow-visible">
                {!email ? (
                  <>
                    <Button
                      variant="text"
                      onClick={() => {
                        history.push("/login");
                      }}
                    >
                      Sign in
                    </Button>
                    <Button
                      className="ml-8"
                      onClick={() => {
                        history.push("/signup");
                      }}
                    >
                      Sign up
                    </Button>
                  </>
                ) : (
                  <ProfileMenu />
                )}
              </div>
            </div>
          </div>
          <MobileMenu
            open={open}
            onClose={() => {
              setOpen(false);
            }}
          />
        </>
      </Popover>
    );
};

const ProfileMenu = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    
    return (
        <Menu as="div" className="ml-3 relative">
            {({ open }) => (
            <>
                <div>
                <Menu.Button className="flex text-sm rounded-full focus:outline-none">
                    <span className="sr-only">Open user menu</span>
                    <UserCircleIcon
                        className="text-indigo-600 h-10 w-10 rounded-full"
                    />
                </Menu.Button>
                </div>
                <Transition
                    show={open}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                <Menu.Items
                    static
                    className="z-40 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <Menu.Item>
                        {({ active }) => (
                            <button 
                                className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                                )}
                                onClick={() => {
                                    history.push('/account');
                                }}        
                            >
                                Profile settings
                            </button>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <button 
                                className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                                )}
                                onClick={() => {
                                    history.push('/resumes');
                                }}        
                            >
                                My resumes
                            </button>
                        )}
                    </Menu.Item>
                    <Menu.Item>
                        {({ active }) => (
                            <button 
                                className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                                )}
                                onClick={() => {
                                    dispatch(authActions.logOutRequested({ history }));
                                }}
                            >
                                Sign out
                            </button>
                        )}
                    </Menu.Item>
                </Menu.Items>
                </Transition>
            </>
            )}
        </Menu>
    )
}

const MobileMenu: React.FC<{ open: boolean, onClose(): void }> = (props) => {
    const history = useHistory();
    const email = useSelector((state: RootState) => state.auth.email);
    const dispatch = useDispatch();
    const pages = email ? loggedInPages : loggedOutPages;

    return (
      <Transition
        show={props.open}
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          static
          className="absolute top-0 inset-x-0 z-40 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <LogoButton
                    onClick={() => {
                      props.onClose();
                      history.push("/");
                    }}
                  />
                </div>
                <div className="-mr-2">
                  <Button
                    size="xs"
                    variant="text"
                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Button>
                </div>
              </div>
              <div className="mt-10 mb-5">
                <nav className="grid gap-y-8">
                  {pages.map((item) => (
                    <Button
                      size="xs"
                      variant="text"
                      key={item.name}
                      className="flex items-center"
                      onClick={() => {
                        props.onClose();
                        history.push(item.path);
                      }}
                    >
                      <item.icon
                        className="flex-shrink-0 h-6 w-6 text-indigo-600"
                        aria-hidden="true"
                      />
                      <span className="ml-3 text-base font-medium text-gray-900">
                        {item.name}
                      </span>
                    </Button>
                  ))}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5 space-y-6">
              <div>
                {!email ? (
                  <>
                    <Button
                      fullWidth
                      onClick={() => {
                        props.onClose();
                        history.push("/signup");
                      }}
                      className="justify-center"
                    >
                      Sign up
                    </Button>
                    <p className="mt-3 text-center text-base font-medium text-gray-500">
                      Existing customer?
                      <Button
                        onClick={() => {
                          props.onClose();
                          history.push("/login");
                        }}
                        variant="text"
                        className="text-indigo-600 hover:text-indigo-500"
                      >
                        Sign in
                      </Button>
                    </p>
                  </>
                ) : (
                  <Button
                    onClick={() => {
                      dispatch(authActions.logOutRequested({ history }));
                    }}
                    variant="text"
                    className="text-indigo-600 hover:text-indigo-500"
                  >
                    Sign out
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    );
}