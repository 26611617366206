import React, { FC } from 'react';
import Paper from '@material-ui/core/Paper';
import { Box, Button, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignSelf: 'center',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    title: {
        fontWeight: 'bold',
        textAlign: 'center',
        marginLeft: '-20px'
    },
    subtitle: {
        display: 'flex',
        alignItems: 'center'
    },
    frequency: {
        textAlign: 'center',
        marginTop: '-10px',
    },
    checkIcon: {
        marginRight: theme.spacing(1)
    },
    buttonContainer: {
        marginTop: theme.spacing(4), 
        display: 'flex', 
        justifyContent: 'center', 
        flexDirection: 'row'
    }
  }));

type Props = {
    price: string;
    frequency: 'per month' | 'per year';
    sellingPoints: string[];
    onSelect(): void;
    isLoading?: boolean;
};

export const PriceItem: FC<Props> = ({ isLoading, price, frequency, sellingPoints, onSelect }) => {
    const classes = useStyles();

    return (
        <Paper elevation={3} className={classes.root}>
            <Box p={4}>
                <Typography variant="h4" gutterBottom className={classes.title}>
                    {'€' + price}
                </Typography>
                <Typography variant="subtitle1" gutterBottom className={classes.frequency}>
                    {frequency}
                </Typography>
                {
                    sellingPoints.map(point => (
                        <Typography variant="subtitle1" gutterBottom className={classes.subtitle}>
                            <CheckIcon fontSize="small" className={classes.checkIcon} />
                            {point}
                        </Typography>
                    ))
                }
                <div className={classes.buttonContainer}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onSelect}
                        fullWidth
                    >
                        {isLoading
                            ? <CircularProgress color="inherit" size={25} />
                            : 'select plan'
                        }
                    </Button>
                </div>
            </Box>
        </Paper>
    );
}