import { all, call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { API, Auth } from 'aws-amplify';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { authActions } from '../auth';
import { RootState } from '..';
import { cvInfoActions } from '../cvInfo';
import { CVInfo, Subscription } from '../../types';
import { stripePublicKey } from '../../services';
import { SELECTED_PLAN_KEY } from '../../utils/constants';

const stripePromise = loadStripe(stripePublicKey);

function* login(
  action: ReturnType<typeof authActions.loginRequested>,
) {
  const { email, password } = action.payload;
  try {
    yield call([Auth, 'signIn'], {
      username: email,
      password,
    });
    yield put(authActions.loginSucceeded({ email }));
  } catch (e) {
    console.error('Error in login saga: ', e);
    yield put(authActions.loginFailed({ error: e }));
  }
}

// function* loginWithSocial(
//   action: ReturnType<typeof authActions.loginWithSocialRequested>,
// ) {
//   try {
//     Auth.federatedSignIn({ provider: 'Google' as any }).then((data ) => {
//       console.log('GOOGLE: ', data);
//     }).then(e => {
//       console.log('GOOGLE error: ', e);
//     });
//     // yield call([Auth, 'signIn'], {
//     //   username: email,
//     //   password,
//     // });
//     // yield put(authActions.loginSucceeded({ email }));
//   } catch (e) {
//     // console.error('Error in login saga: ', e);
//     // yield put(authActions.loginFailed({ error: e }));
//   }
// }

function* signUp(
  action: ReturnType<typeof authActions.signUpRequested>,
) {
  const { email, password, priceId } = action.payload;
  try {
    yield call([Auth, 'signUp'], {
      username: email,
      password,
    });
    yield call([Auth, 'signIn'], {
      username: email,
      password,
    });
    
    const cvInfo: Partial<CVInfo> = yield select(
      (rootState: RootState) =>
        rootState.cvInfo.byId[rootState.cvInfo.selectedId]
    );
    let cvInfoMutable = { ...cvInfo };
    delete cvInfoMutable.updateStatus;
    yield call([API, 'post'], 'dreamCVApi', `/resumes/`, {
        body: cvInfoMutable
    });

    if (priceId !== 'free') {
      const { sessionId }: { sessionId: string } = yield call(
        [API, 'post'],
        'dreamCVApi',
        '/checkout-session',
        {
          body: {
            priceId,
            email
          }
        }
      );
      const stripe: Stripe = yield stripePromise;
      
      yield call([stripe, 'redirectToCheckout'], {
        sessionId
      });
    }
    yield put(authActions.loginSucceeded({ email }));
  } catch (e) {
    console.error('Error in sign up saga: ', e);
    yield put(authActions.signUpFailed({ error: e }));
  }
}

function* logOut(
  action: ReturnType<typeof authActions.logOutRequested>,
) {
  const { history } = action.payload;
  try {
    yield call([Auth, 'signOut']);
    history.push("/");
    yield put(authActions.logOutSucceeded());
  } catch (e) {
    console.error('Error in log out saga: ', e);
    yield put(authActions.logOutSucceeded());
  }
}

function* localUserInfo(_: ReturnType<typeof authActions.localUserInfoRequested>) {
  try {
    const user: { attributes: { email: string } } = yield call([Auth, 'currentAuthenticatedUser']);
    yield put(authActions.loginSucceeded({ email: user.attributes.email }));
  } catch (e) {
    console.error('Error in local user saga: ', e);
    yield put(authActions.loginFailed({ error: e }));
  }
}

function* planSelected(action: ReturnType<typeof authActions.planSelected>) {
  try {
    const { priceId } = action.payload;
    let email: string = yield select((rootState: RootState) => rootState.auth.email);
    const { sessionId }: { sessionId: string } = yield call(
      [API, 'post'],
      'dreamCVApi',
      '/checkout-session',
      {
        body: {
          priceId,
          email
        }
      }
    );
    const stripe: Stripe = yield stripePromise;
    yield call([stripe, 'redirectToCheckout'], {
      sessionId
    });
  } catch (e) {
    console.error('Error in planSelected saga: ', e);
  }
}

function* getSubscriptionStatus() {
  try {
    const { data: subscription }: { data: Subscription } = yield call([API, 'get'], 'dreamCVApi', '/subscriptions', {});
    yield put(authActions.getSubscriptionStatusSucceeded({ subscription }));
  } catch (e) {
    console.error('Error in getSubscriptionStatus saga: ', e);
  }
}

function* cancelSubscription() {
  try {
    yield call(
      [API, 'post'],
      'dreamCVApi',
      '/cancel-subscription',
      {}
    );
    yield put(authActions.cancelSubscriptionSucceeded());
  } catch (e) {
    console.error("Error in cancel subscription saga: ", e);
    yield put(authActions.cancelSubscriptionFailed());
  }
}

function* changeEmail(action: ReturnType<typeof authActions.changeEmailRequested>) {
  const { email } = action.payload;
  try {
    yield call(
      [API, 'post'],
      'dreamCVApi',
      '/change-email',
      { body: { email } }
    );
    yield put(authActions.changeEmailSucceded());
  } catch (e) {
    console.error("Error in changeEmail saga: ", e);
    yield put(authActions.changeEmailFailed());
  }
}

function* deleteAccount(action: ReturnType<typeof authActions.deleteAccountRequested>) {
  const { history } = action.payload;
  let email: string = yield select((rootState: RootState) => rootState.auth.email);
  if (!email) {
    console.error("No user was found in deleteAccount saga: ");
    history.push("/");
    yield put(authActions.deleteAccountFailed());
    return;
  }

  try {
    yield call(
      [API, 'del'],
      'dreamCVApi',
      '/delete-account',
      {}
    );
    yield put(authActions.deleteAccountSucceded());
    yield put(authActions.logOutRequested({ history }));
  } catch (e) {
    console.error("Error in deleteAccount saga: ", e);
    yield put(authActions.deleteAccountFailed());
  } finally {
    history.push("/");
  }
}

function* authSucceeded(_: ReturnType<typeof authActions.localUserInfoSucceeded>) {
  yield put(authActions.getSubscriptionStatusRequested());
  yield put(cvInfoActions.getSavedRequested());
}

function* cancelSubscriptionSucceeded() {
  yield put(authActions.getSubscriptionStatusRequested());
}

function* logOutSucceeded() {
  yield call([localStorage, 'removeItem'], 'cvInfo');
}

function* signUpPlanChanged(action: ReturnType<typeof authActions.signupSelectedPlanIdChanged>) {
  localStorage.setItem(SELECTED_PLAN_KEY, action.payload.id);
}

function* sendMessage(action: ReturnType<typeof authActions.sendMessageRequested>) {
  const { email, name, message } = action.payload;
  try {
    yield call(
      [API, 'post'],
      'dreamCVApi',
      '/send-message',
      {
        body: {
          email,
          name,
          message
        }
      }
    );
    yield put(authActions.sendMessageSucceded());
  } catch (e) {
    console.error("Error in send message saga: ", e);
    yield put(authActions.sendMessageFailed());
  }
}

export function* authSagas() {
  yield all([
    takeLatest(authActions.loginRequested.type, login),
    takeLatest(authActions.localUserInfoRequested.type, localUserInfo),
    takeLatest(authActions.signUpRequested.type, signUp),
    takeLatest(authActions.logOutRequested.type, logOut),
    takeLatest(authActions.planSelected.type, planSelected),
    takeLatest(authActions.localUserInfoSucceeded.type, authSucceeded),
    takeLatest(authActions.loginSucceeded.type, authSucceeded),
    takeLatest(authActions.logOutSucceeded.type, logOutSucceeded),
    takeLatest(authActions.getSubscriptionStatusRequested.type, getSubscriptionStatus),
    takeLatest(authActions.cancelSubscriptionRequested.type, cancelSubscription),
    takeLatest(authActions.changeEmailRequested.type, changeEmail),
    takeLatest(authActions.sendMessageRequested.type, sendMessage),
    takeLatest(authActions.deleteAccountRequested.type, deleteAccount),
    // takeLatest(authActions.loginWithSocialRequested.type, loginWithSocial),
    takeLatest(authActions.signupSelectedPlanIdChanged.type, signUpPlanChanged),
    debounce(2000, authActions.cancelSubscriptionSucceeded.type, cancelSubscriptionSucceeded),
  ]);
}