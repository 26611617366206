import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { images } from "../assets/images";
import { CVTheme } from "../types";

const resumeTemplates: (ResumeTemplate | InProgressResumeTemplate)[] = [
  {
    theme: 'Wolt',
    imageUrl: images.woltResume,
    isNew: true
  },
  {
    theme: "Netflix",
    imageUrl: images.netflixResume,
  },
  {
    theme: "Github",
    imageUrl: images.githubResume,
  },
  {
    theme: "Twitter",
    imageUrl: images.twitterResume,
  },
  {
    theme: "Google",
    imageUrl: images.googleResume,
  },
  {
    theme: 'Reddit',
  },
];

type ComingCVTheme = 'Reddit';

export type ResumeTemplate = {
  theme: CVTheme;
  imageUrl: string;
  isNew?: boolean
};

type InProgressResumeTemplate = {
  theme: ComingCVTheme;
}

const isResumeTemplate = (template: ResumeTemplate | InProgressResumeTemplate): template is ResumeTemplate => {
  return template.theme !== 'Reddit';
}

export const ResumeTemplates: React.FC<{
  onSelectTheme(theme: CVTheme): void;
}> = ({ onSelectTheme }) => {
  const [selectedTemplate, setSelectedTemplate] = useState<ResumeTemplate>({
    theme: "Netflix",
    imageUrl: images.netflixResume,
  });
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);

  const onSelectClick = (template: ResumeTemplate) => {
    setIsTemplateOpen(false);
    onSelectTheme(template.theme as CVTheme);
  };

  const onCloseClick = () => {
    setIsTemplateOpen(false);
  };

  return (
    <>
      <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
        {resumeTemplates.map((template) => (
          <div
            key={template.theme}
            className="group relative flex flex-col transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105"
          >
            <p className="text-xl font-semibold text-gray-900 mb-2">
              {template.theme}
              {isResumeTemplate(template) && template.isNew ? (
                <span className="ml-2 p-1 text-xs text-white rounded-lg bg-gradient-to-r from-indigo-500 to-indigo-600">
                  NEW
                </span>
              ) : null}
            </p>
            {
              isResumeTemplate(template) ? (
                <button
                  onClick={() => {
                    setIsTemplateOpen(true);
                    setSelectedTemplate(template);
                  }}
                  className="focus:outline-none"
                >
                  <img
                    className="rounded-lg shadow-2xl h-full w-full object-cover border border-gray-200"
                    src={template.imageUrl}
                    alt=""
                  />
                </button>
              ) : (
                <div className="h-screen-13/20 sm:h-screen-2/3 lg:h-full flex items-center justify-center rounded-lg shadow-2xl w-full object-cover bg-gradient-to-r from-indigo-500 to-indigo-600">
                  <p className="text-xl text-white">
                    COMING SOON
                  </p>
                </div>
              )
            }
            
          </div>
        ))}
      </div>
      <ResumeDialogue
        template={selectedTemplate}
        isOpen={isTemplateOpen}
        onCloseClick={onCloseClick}
        onSelectClick={onSelectClick}
      />
    </>
  );
};

export const ResumeDialogue: React.FC<{
  template: ResumeTemplate;
  isOpen: boolean;
  onCloseClick(): void;
  onSelectClick(template: ResumeTemplate): void;
}> = ({ template, isOpen, onCloseClick, onSelectClick }) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={isOpen}
        onClose={onCloseClick}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full sm:p-6">
              <div>
                <img
                  className="rounded-lg shadow-2xl h-full w-full object-cover border border-gray-200"
                  src={template.imageUrl}
                  alt=""
                />
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:col-start-2 sm:text-sm"
                  onClick={() => onSelectClick(template)}
                >
                  Select this template
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={onCloseClick}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
