import { Button } from './Button';

export const LogoButton: React.FC<{ onClick?(): void, className?: string }> = ({ onClick, className }) => {
    return (
        <button
            onClick={onClick}
            className={"focus:outline-none " + className}
        >
            <svg width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.9091 0H0V37.8182H33.4545V14.5455L18.9091 0Z" fill="#4F46E5" />
                <path d="M10.9091 26.9092V10.9092H17.4545V16.0001H22.5455V26.9092H10.9091Z" fill="white" />
                <path d="M21.8182 12.3636V8L26.1818 12.3636H21.8182Z" fill="white" />
            </svg>
        </button>
    );
};