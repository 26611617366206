import {
  DocumentDuplicateIcon,
  DocumentTextIcon,
  AdjustmentsIcon,
  CheckIcon,
  BanIcon
} from '@heroicons/react/outline'
import { useHistory } from 'react-router-dom';

import { Button, CreateNewResumeDialogue, LoadingIndicator, ResumeTemplates, UpgradePlanDialogue } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { cvInfoActions } from '../../store/cvInfo';
import { RootState } from "../../store";
import { CVTheme } from '../../types';
import { RotatingTemplates } from './RotatingTemplates';
import { useState } from 'react';
import { planIds, stripePlans } from '../../services';
import { isProSelector } from '../../store/selectors';
import { isOneOfThePlansLoading, plansActions } from '../../store/plans';
import { authActions } from '../../store/auth';

const features = [
  {
    name: "Intuitive resume builder",
    description:
      "Build your standout resume in minutes without leaving your browser and export it in PDF.",
    icon: AdjustmentsIcon,
  },
  {
    name: "Approved templates",
    description:
              "Our professinally designed templates have been approved by the top European recruiters.",
    icon: DocumentTextIcon,
  },
  {
    name: "Manage multiple resumes",
    description:
      "It's important to customize your resume for each role you apply for. Easily manage your resumes from one dashboard.",
    icon: DocumentDuplicateIcon,
  },
];

export function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  const cvIds = useSelector((state: RootState) => state.cvInfo.ids);
  const isPro = useSelector(isProSelector);
  const email = useSelector((state: RootState) => state.auth.email);
  const [createResumeDialogueOpen, setCreateResumeDialogueOpen] = useState(false);
  const [proDialogueOpen, setProDialogueOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState<CVTheme | undefined>();

  const onSelectTheme = (theme: CVTheme) => {
    if (!email) {
      dispatch(cvInfoActions.themeSelected({ theme }));
      history.push("/signup");
      return;
    }
    if (cvIds.length && !isPro) {
      setProDialogueOpen(true);
      return;
    }

    setSelectedTheme(theme);
    setCreateResumeDialogueOpen(true);
  };

  const onClickCTA = () => {
    if (!email) {
      history.push("/signup");
      return;
    }
    history.push("/resumes");
  }

  return (
    <div className="bg-white">
      <div className="relative overflow-hidden">
        <main>
          <div className="bg-white pb-8 sm:pb-12 lg:pb-12">
            <div className="pt-8 sm:pt-12 lg:relative lg:py-56">
              <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
                <div>
                  <div>
                    <div className="mt-6 sm:max-w-xl">
                      <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                        Make your resume
                        <br />
                        <span className="text-indigo-600">stand out</span>
                      </h1>
                      <p className="mt-6 text-xl text-gray-500">
                        Now you can apply for your dream job with a
                        professionally designed resume that showcases your
                        skills and experience in a captivating theme.
                      </p>
                    </div>
                    <div className="flex row mt-10">
                      <Button
                        fullWidth
                        size="md"
                        className="w-full z-10 flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                        onClick={onClickCTA}
                      >
                        Create my DreamCV
                      </Button>
                      {!email && (
                        <div className="flex flex-col content-center -mt-5 -ml-12">
                          <div className="text-md transform rotate-12 text-gray-400 font-handwritten text-center w-full">
                            Try without signing up
                          </div>
                          <div className="text-xs top-0 transform scale-x-negative rotate-12"><svg xmlns="http://www.w3.org/2000/svg" height="100" width="147" viewBox="0 0 100 100" className="h-10 text-gray-400 fill-current"><path fill="fillCurrent" d="M41.4 62.7c4.7 4.4 10.3 7.6 16.2 10.1 4.5 1.8 9.2 3.2 13.9 4.3-5 .9-10 2.3-14.7 4.3-1.7.7 1.4 4.2 2.8 3.6 6.3-2.6 13-4.3 19.8-5 1.9-.2.1-2.9-1.4-3.5-.1-.2-.2-.4-.3-.5-2.9-4.1-5.6-8.3-8-12.7-.5-.9-1.5-1.9-2.6-1.9s-.9 1.1-.5 1.8c2.2 3.9 4.6 7.6 7.1 11.3-10.3-2.4-20.9-5.2-29.2-12.1-4.2-3.5-7.8-7.9-10.8-12.5-2.8-4.4-5.2-9.2-6.9-14.2-2-5.7-3.1-11.7-3.5-17.7-.1-1.1-1.1-2.1-2-2.5-.7-.3-1.8-.5-1.7.6.7 11.4 4.2 22.7 10.2 32.4 3.3 5 7.1 10 11.6 14.2z"></path></svg></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
                <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
                  <div className="hidden sm:block">
                    <div className="absolute inset-y-0 left-1/2 w-screen bg-gray-50 rounded-l-3xl lg:left-80 lg:right-0 lg:w-full" />
                    <svg
                      className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                      width={404}
                      height={392}
                      fill="none"
                      viewBox="0 0 404 392"
                    >
                      <defs>
                        <pattern
                          id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                          x={0}
                          y={0}
                          width={20}
                          height={20}
                          patternUnits="userSpaceOnUse"
                        >
                          <rect
                            x={0}
                            y={0}
                            width={4}
                            height={4}
                            className="text-gray-200"
                            fill="currentColor"
                          />
                        </pattern>
                      </defs>
                      <rect
                        width={404}
                        height={392}
                        fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                      />
                    </svg>
                  </div>
                  <RotatingTemplates onSelectTheme={onSelectTheme} />
                  {/* <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
                    <img
                      className="w-full lg:h-full lg:w-auto lg:max-w-none"
                      src={Background}
                      alt=""
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* Feature section with grid */}
          <div className="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
              <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">
                Built for you
              </h2>
              <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                Everything you need to land your dream job
              </p>
              <p className="mt-5 max-w-pindigo mx-auto text-xl text-gray-500">
                {/* We designed */}
              </p>
              <div className="mt-12">
                <div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
                  {features.map((feature) => (
                    <div key={feature.name} className="pt-6">
                      <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                        <div className="-mt-6">
                          <div>
                            <span className="inline-flex items-center justify-center p-3 bg-gradient-to-r from-indigo-500 to-indigo-600 rounded-md shadow-lg">
                              <feature.icon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                            {feature.name}
                          </h3>
                          <p className="mt-5 text-base text-gray-500">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Testimonial section */}
          {/* <div className="pb-16 bg-gradient-to-r from-teal-500 to-cyan-600 lg:pb-0 lg:z-10 lg:relative">
            <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
              <div className="relative lg:-my-8">
                <div
                  aria-hidden="true"
                  className="absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden"
                />
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0 lg:h-full">
                  <div className="aspect-w-10 aspect-h-6 rounded-xl shadow-xl overflow-hidden sm:aspect-w-16 sm:aspect-h-7 lg:aspect-none lg:h-full">
                    <img
                      className="object-cover lg:h-full lg:w-full"
                      src="https://images.unsplash.com/photo-1520333789090-1afc82db536a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2102&q=80"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="mt-12 lg:m-0 lg:col-span-2 lg:pl-8">
                <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                  <blockquote>
                    <div>
                      <svg
                        className="h-12 w-12 text-white opacity-25"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <p className="mt-6 text-2xl font-medium text-white">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Sed urna nulla vitae laoreet augue. Amet feugiat est
                        integer dolor auctor adipiscing nunc urna, sit.
                      </p>
                    </div>
                    <footer className="mt-6">
                      <p className="text-base font-medium text-white">
                        Judith Black
                      </p>
                      <p className="text-base font-medium text-cyan-100">
                        CEO at PureInsights
                      </p>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div> */}

          {/* Blog section */}
          <div
            id="templates"
            className="relative bg-white py-16 sm:py-24 lg:py-32"
          >
            <div className="relative">
              <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">
                  Designed for your success
                </h2>
                <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                  All resume templates
                </p>
                <p className="mt-5 mx-auto max-w-pindigo text-xl text-gray-500">
                  {/* Phasellus lorem quam molestie id quisque diam aenean nulla in.
                  Accumsan in quis quis nunc, ullamcorper malesuada. Eleifend
                  condimentum id viverra nulla. */}
                </p>
              </div>
              <ResumeTemplates onSelectTheme={onSelectTheme} />
            </div>
          </div>

          {/* Pricing */}
          <PricingSection />

          {/* CTA section */}
          <div className="relative py-10 sm:py-24">
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
              <div className="relative rounded-2xl px-6 py-10 bg-indigo-500 overflow-hidden shadow-2xl sm:px-12 sm:py-14">
                <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                  <svg
                    className="absolute inset-0 h-full w-full"
                    preserveAspectRatio="xMidYMid slice"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 1463 360"
                  >
                    <path
                      className="text-indigo-400 text-opacity-40"
                      fill="currentColor"
                      d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                    />
                    <path
                      className="text-indigo-600 text-opacity-40"
                      fill="currentColor"
                      d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                    />
                  </svg>
                </div>
                <div className="relative">
                  <div className="sm:text-center">
                    <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                      Get your DreamCV today
                    </h2>
                    <p className="mt-6 mx-auto max-w-2xl text-lg text-indigo-100">
                      Build your standout resume in minutes and apply for your dream job.
                    </p>
                  </div>
                  <div className="flex w-full mt-10 justify-center">
                    <button
                      type="submit"
                      className="flex rounded-md border border-transparent px-5 py-3 bg-gray-800 text-base font-medium text-white shadow hover:bg-gray-900 focus:outline-none sm:px-10"
                      onClick={onClickCTA}
                    >
                      Get started
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <UpgradePlanDialogue 
            open={proDialogueOpen} 
            onClose={() => { setProDialogueOpen(false) }} 
            message="You're over the resume limit. Only one resume is available on the free plan. Upgrade your plan and get access to:" 
          />
          <CreateNewResumeDialogue open={createResumeDialogueOpen} onClose={() => { setCreateResumeDialogueOpen(false) }} cvTheme={selectedTheme} />
        </main>
      </div>
    </div>
  );
}

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

const pricing = {
  tiers: [
    {
      id: 'free',
      title: 'Free',
      price: 0,
      frequency: '',
      description: '',
      features: [
        { title: '1 resume', isFreeTier: true}, 
        { title: 'Access to all templates', isFreeTier: true}, 
        { title: 'DreamCV branding on your resume', isFreeTier: true}, 
      ],
      cta: 'Get started',
      mostPopular: false,
    },
    {
      id: planIds.annual,
      title: 'Annual',
      price: 5.59,
      frequency: '/month',
      description: 'Billed yearly (€67)',
      features: [
        { title: "30% OFF" },
        { title: "Unlimited resumes" },
        { title: "Access to all templates" },
        { title: "No branding" },
      ],
      cta: 'Get started',
      mostPopular: true,
    },
    {
      id: planIds.monthly,
      title: 'Monthly',
      price: 7.99,
      frequency: '/month',
      description: 'Billed every month (€7.99)',
      features: [
        { title:"Unlimited resumes" },
        { title:"Access to all templates" },
        { title:"No branding" },
      ],
      cta: 'Get started',
      mostPopular: false,
    },
  ],
}

const PricingSection = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const planStatus = useSelector((state: RootState) => state.plans.planStatus);
  const email = useSelector((state: RootState) => state.auth.email);
  const isPro = useSelector(isProSelector);
  const isLoggedIn = !!email;
  const isLoading = isOneOfThePlansLoading(planStatus);
  const onSelectPlan = (id: string) => () => {
    if (isLoading) return;
    if (!isLoggedIn) {
      dispatch(authActions.signupStepChanged({ step: 2 }));
      dispatch(authActions.signupSelectedPlanIdChanged({ id }));
      history.push("/signup");
      return;
    }
    if (isPro || id === 'free') {
      history.push("/settings");
      return;
    }
    
    dispatch(plansActions.selectPlanRequested({ priceId: id }));
  };
  
  return (
    <section className="relative bg-gray-50 py-16 sm:py-24 lg:py-24" aria-labelledby="pricing-heading">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl mb-12">
        <h2 className="text-base font-semibold tracking-wider text-indigo-600 uppercase">
          Pricing
        </h2>
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          Pick a plan that suits you the best
        </p>
      </div>

      {/* Tiers */}
      <div className="max-w-2xl mx-auto px-4 space-y-12 sm:px-6 lg:max-w-7xl lg:space-y-0 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        {pricing.tiers.map((tier) => (
          <div
            key={tier.title}
            className="relative p-8 bg-white border border-gray-200 rounded-2xl shadow-sm flex flex-col"
          >
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-gray-900">{tier.title}</h3>
              {tier.mostPopular ? (
                <p className="absolute top-0 py-1.5 px-4 bg-indigo-600 rounded-full text-xs font-semibold uppercase tracking-wide text-white transform -translate-y-1/2">
                  Most popular
                </p>
              ) : null}
              <p className="mt-4 flex items-baseline text-gray-900">
                <span className="text-5xl font-extrabold tracking-tight">€{tier.price}</span>
                <span className="ml-1 text-xl font-semibold">{tier.frequency}</span>
              </p>
              <p className="mt-6 text-gray-500">{tier.description}</p>

              {/* Feature list */}
              <ul role="list" className="mt-6 space-y-6">
                {tier.features.map(({ title, isFreeTier } : { title: string, isFreeTier?: boolean }) => (
                  <li key={title} className="flex">
                    <CheckIcon
                      className={`flex-shrink-0 h-6 w-6 ${isFreeTier ? 'text-gray-300' : 'text-green-500'}`}
                      aria-hidden="true"
                    />
                    <span className="ml-3 text-gray-500">{title}</span>
                  </li>
                ))}
              </ul>
            </div>

            <button
              className={classNames(
                tier.mostPopular
                  ? 'bg-indigo-600 text-white hover:bg-indigo-700'
                  : 'bg-indigo-50 text-indigo-700 hover:bg-indigo-100',
                'flex row justify-center items-center mt-8 w-full py-3 px-6 border border-transparent rounded-md text-center font-medium'
              )}
              onClick={onSelectPlan(tier.id)}
            >
              {planStatus[tier.id] === "loading" && (
                <LoadingIndicator className="mr-3" />
              )}
              {tier.cta}
            </button>
          </div>
        ))}
      </div>
    </section>
  )
}
