import React, { FC } from 'react';

type Size = "xs" | "sm" | "md" | "lg";
type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
  isLoading?: boolean;
  variant?: "outlined" | "contained" | "text";
  size?: Size;
  fullWidth?: boolean;
  leftHandChildren?: React.ReactNode;
};

const sizeToPadding: { [key in Size]: string } = {
    'xs': '',
    'sm': 'px-4 py-2',
    'md': 'px-5 py-3',
    'lg': 'px-5 py-3',
};

export const Button: FC<Props> = ({ children, isLoading, fullWidth, className, size = 'sm', variant = 'contained', leftHandChildren, ...props }) => {
    let defaultClassName =
      "inline-flex items-center rounded-md border border-transparent bg-indigo-600 text-base font-medium text-white shadow hover:bg-indigo-700 focus:outline-none hover:invisible";
    if (variant === 'text') {
        defaultClassName =
          "inline-flex items-center whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900 focus:outline-none";
    }
    if (variant === 'outlined') {
      defaultClassName = "inline-flex items-center px-4 py-2 border border-indigo-600 shadow-sm text-indigo-600 font-medium rounded-md bg-white hover:bg-indigo-50 focus:outline-none focus:ring-0";
    }
    if (fullWidth) {
        defaultClassName += " block w-full";
    }
    if (className) {
        defaultClassName += " " + className;
    }
    defaultClassName += " " + sizeToPadding[size];

    return (
      <button
        name="submit"
        type="submit"
        className={defaultClassName}
        {...props}
      >
        {isLoading && <LoadingIndicator />}
        {leftHandChildren ? leftHandChildren : null}
        {children}
      </button>
    );
};

const LoadingIndicator = () => {
    return (
      <svg
        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    );
}