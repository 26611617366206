import React, { FC } from 'react';
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import { makeStyles } from '@material-ui/core/styles';
import { TextField, TextFieldProps } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        flexDirection: "column",
        justifyContent: 'center',
    },
    textField: {
        display: 'flex',
    }
  }));

export const TextInputOld: FC<TextFieldProps> = (props) => {
  const classes = useStyles();

  return (
    <TextField className={classes.textField} variant="outlined" {...props} />
  );
};

type CommonProps = {
  label?: string;
  descr?: string;
  textInputType?: "default" | "url";
  rows?: number;
  error?: string;
  rightHandChildren?: React.ReactNode;
};

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  CommonProps & {
    multiline?: false;
  };

type TextAreaProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
> &
  CommonProps & {
    multiline?: true;
  };

export type TextInputProps = InputProps | TextAreaProps;

const isTextArea = (props: TextInputProps): props is TextAreaProps =>
  props.multiline === true;

export const TextInput: FC<TextInputProps> = (props) => {
  const {
    label,
    error,
    rightHandChildren,
    textInputType = "default",
    descr,
  } = props;
  
  if (textInputType === "url")
    return <TextInputURL {...props} />;
  
  const errorProps = error
    ? {
        "aria-invalid": true,
        "aria-describedby": "input-error",
      }
    : {};
  const errorClassName =
    "block w-full pr-10 border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md";
  const defaultClassName =
    "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md";
  let inputClassName = error ? errorClassName : defaultClassName;
  let textAreaClassname =
    "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md pr-28";
  if (rightHandChildren) {
    textAreaClassname += " pr-28";
    inputClassName += " pr-28";
  }

  if (props.className) {
    inputClassName += " " + props.className;
    textAreaClassname += " " + props.className;
  }

  const describedBy = error ? "error" : "descr";

  return (
    <div>
      {!!label && (
        <label
          htmlFor={props.label}
          className="block text-sm font-medium text-gray-700 text-left"
        >
          {label}
        </label>
      )}
      <div className="mt-1 relative rounded-md shadow-sm">
        {isTextArea(props) ? (
          <textarea
            id={props.label}
            type={props.type || "text"}
            name={props.label}
            {...props}
            className={textAreaClassname}
          />
        ) : (
          <input
            id={props.label}
            type={props.type || "text"}
            name={props.label}
            aria-describedby={describedBy}
            {...errorProps}
            {...props}
            className={inputClassName}
          />
        )}
        {rightHandChildren ? (
          <div className="absolute inset-y-0 right-2 pr-3 flex items-center">
            {rightHandChildren}
          </div>
        ) : null}
      </div>
      {!!error && (
        <p className="mt-2 text-left text-sm text-red-600" id="error">
          {error}
        </p>
      )}
      {!!descr && !error && (
        <p className="mt-2 text-left text-sm text-gray-500" id="descr">
          {descr}
        </p>
      )}
    </div>
  );
};

const TextInputURL: FC<TextInputProps> = (props) => {
  if (isTextArea(props))
    return null;

  return (
    <div>
      <label
        htmlFor="company_website"
        className="block text-sm font-medium text-gray-700"
      >
        {props.label}
      </label>
      <div className="mt-1 flex rounded-md shadow-sm">
        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
          http://
        </span>
        <input
          type="text"
          name={props.label}
          id={props.label}
          className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
          placeholder="www.example.com"
          {...props}
        />
      </div>
    </div>
  );
};