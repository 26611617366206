import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MaterialUISwitch, { SwitchProps } from '@material-ui/core/Switch';


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        flexDirection: "column",
        justifyContent: 'center',
    },
    textField: {
        display: 'flex',
    }
  }));

export const Switch: FC<SwitchProps> = (props) => {
    const classes = useStyles();

    return (
        <MaterialUISwitch color="primary" {...props} />
    );
}