import { Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'

type Props = {
    open: boolean;
    title?: string;
    message?: string;
    hideButtons?: boolean;
    onClose(): void;
    onConfirm(): void;
    closeButtonTitle?: string;
    confirmButtonTitle?: string;
    Icon?: typeof CheckIcon;
}

export const Dialogue: React.FC<Props> = (props) => {
    const { 
        open, 
        title, 
        message, 
        onClose, 
        onConfirm,
        hideButtons,
        closeButtonTitle = 'Close',
        confirmButtonTitle = 'Confirm',
        children,
        Icon
    } = props;
    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed z-10 inset-0 overflow-y-auto"
                initialFocus={cancelButtonRef}
                open={open}
                onClose={onClose}
            >
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                        <div>
                            {
                                Icon 
                                    ? (
                                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                                            <Icon className="h-6 w-6 text-green-600" aria-hidden="true" />
                                        </div>
                                    ) 
                                    : null
                            }
                            <div className={Icon ? "mt-3 sm:mt-5" : ""}>
                                {!!title && (
                                    <Dialog.Title as="h3" className="text-center text-lg leading-6 font-medium text-gray-900">
                                        {title}
                                    </Dialog.Title>
                                )}
                                <div className={title ? "mt-2" : ""}>
                                    <p className={"text-sm text-gray-500 " + (children ? "text-left" : "text-center")}>
                                        {message}
                                    </p>
                                    <div className={message ? "mt-2" : ""}>
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div className={`${hideButtons ? 'hidden' : ''} mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-densemt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense`}>
                                <button
                                    type="button"
                                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:col-start-2 sm:text-sm"
                                    onClick={onConfirm}
                                >
                                    {confirmButtonTitle}
                                </button>
                                <button
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
                                    onClick={onClose}
                                    ref={cancelButtonRef}
                                >
                                    {closeButtonTitle}
                                </button>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
