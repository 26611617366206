import { RootState } from "../";

export const isProSelector = (state: RootState) => {
    const { subscription } = state.auth;

    if (!subscription)
      return false;
    if (subscription.status === 'active')
      return true;
    if (subscription.status === 'canceled' && new Date(subscription.end_date) > new Date())
      return true;
      
    return false;
}