import { Button, Card } from "../../components";

export const LoadingCVItem = () => (
  <Card contentClassName="w-72 max-w-xs">
    <div className="animate-pulse flex flex-col">
      <div className="flex-1">
        <a className="block">
          <div className="rounded-2xl h-7 w-2/3 bg-gray-300"></div>
          <div className="rounded-xl mt-3 h-5 w-6/12 bg-gray-300"></div>
          <div className="rounded-xl mt-3 h-5 w-6/12 bg-gray-300"></div>
        </a>
      </div>
      <div className="mt-3 flex flex-col">
        <div className="inline-flex items-center px-4 py-3 rounded-2xl w-full border border-gray-300">
          <div className="rounded-lg mr-2 h-5 w-5 bg-gray-300" />
          <div className="rounded-md h-3 w-3/12 bg-gray-300" />
        </div>
        <div className="mt-2 inline-flex items-center px-4 py-3 rounded-2xl w-full border border-gray-300">
          <div className="rounded-lg mr-2 h-5 w-5 bg-gray-300" />
          <div className="rounded-md h-3 w-6/12 bg-gray-300" />
        </div>
        <div className="mt-2 inline-flex items-center px-4 py-3 rounded-2xl w-full border border-gray-300">
          <div className="rounded-lg mr-2 h-5 w-5 bg-gray-300" />
          <div className="rounded-md h-3 w-5/12 bg-gray-300" />
        </div>
      </div>
    </div>
  </Card>
);
