import { CVInfo, EducationItem, ExperienceItem, Language, Link, Project } from "../../../types";

export const shouldRenderExperiences = (experiences: ExperienceItem[]) => {
  if (!experiences.length) return false;
  if (experiences.length > 1) return true;

  const experience = experiences[0];
  if (experience.companyName) return true;
  if (experience.position) return true;
  if (experience.dates) return true;
  if (experience.location) return true;
  if (experience.website) return true;
  for (let i = 0; i < experience.achievements.length; i++)
    if (experience.achievements[i]) return true;

  return false;
};

export const shouldRenderEducations = (educations: EducationItem[]) => {
  for(let i = 0; i < educations.length; i++) {
    const education = educations[i];
    if (education.instituteName || education.degree || education.dates) 
      return true;
  }

  return false;
};

export const shouldRenderStringArray = (arr: string[]) => {
  for (let i = 0; i < arr.length; i++) if (arr[i]) return true;

  return false;
};

export const shouldRenderLinks = (links: Link[]) => {
  for(let i = 0; i < links.length; i++) {
    if (links[i].title || links[i].link)
      return true;
  }

  return false;
}

export const shouldRenderProjects = (projects: Project[]) => {
  for(let i = 0; i < projects.length; i++) {
    const project = projects[i];
    if (project.title || project.link || project.description) 
      return true;
  }

  return false;
};

export const shouldRenderLanguages = (languages: Language[]) => {
  for(let i = 0; i < languages.length; i++) {
    const lang = languages[i];
    if (lang.name || lang.fluency) 
      return true;
  }

  return false;
};
