import React, { FC } from 'react';
import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Add, Delete } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { cvInfoActions } from '../../store/cvInfo';
import { TextInput } from "../../components";


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        flexDirection: "column",
        justifyContent: 'center',
    },
    textField: {
        display: 'flex'
    }
  }));

export const AwardsForm: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch();
  const cvInfo = useSelector((state: RootState) => state.cvInfo.byId[id]);
  const classes = useStyles();

  const onChange = (index: number) => (value: string) => {
    dispatch(cvInfoActions.awardsChanged({ index, value }));
  };

  const onAdd = (index: number) => (position: "before" | "after") => {
    dispatch(cvInfoActions.awardsAdded({ index, position }));
  };

  const onDelete = (index: number) => () => {
    dispatch(cvInfoActions.awardsDeleted({ index }));
  };

  return (
    <>
      {cvInfo.awards.map((award, index) => (
        <AwardInputItem
          key={index}
          index={index}
          value={award}
          onChange={onChange(index)}
          onDelete={onDelete(index)}
          onAdd={onAdd(index)}
          shouldRenderDeleteButton={cvInfo.awards.length > 1}
        />
      ))}
    </>
  );
};

type AwardInputItemProps = {
    index: number,
    value: string, 
    onChange: (newValue: string) => void,
    onDelete: () => void,
    onAdd: (position: 'before' | 'after') => void,
    shouldRenderDeleteButton: boolean
}

const AwardInputItem: FC<AwardInputItemProps> = ({ index, value, onChange, onAdd, onDelete, shouldRenderDeleteButton }) => {
    const classes = useStyles();
    
    const handleChange: OutlinedTextFieldProps['onChange'] = ({ target: { value } }) => {
        onChange(value);
    }
    
    const handleAdd = (position: 'before' | 'after') => () => {
        onAdd(position);
    }

    return (
      <Box>
        {index === 0 && <AddButton onClick={handleAdd("before")} />}
        <TextInput
          placeholder={"e.g. 1st place winner at The Game Jam Hackathon 2020"}
          onChange={handleChange}
          value={value}
          rightHandChildren={
            <InputAdornment position="start">
              {shouldRenderDeleteButton && (
                <IconButton
                  onClick={onDelete}
                  color="primary"
                  aria-label="delete"
                >
                  <Delete />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
        <AddButton onClick={handleAdd("after")} />
      </Box>
    );
}

const AddButton: FC<{ onClick: () => void }> = ({ onClick }) => (
    <Box mt={3} mb={3} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Button variant="outlined" color="primary" onClick={onClick} size='small'>
            Add another award here
        </Button>
    </Box>
);