import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TypographyProps } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        flexDirection: "column",
        justifyContent: 'center',
    },
    textField: {
        display: 'flex',
    }
  }));

export const Body1: FC<TypographyProps> = (props) => {
    const classes = useStyles();
    return (
        <Typography variant="subtitle1" {...props} />
    );
}

export const Body2: FC<TypographyProps> = (props) => {
    const classes = useStyles();
    return (
        <Typography variant="subtitle2" {...props} />
    );
}