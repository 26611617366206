import { Provider } from 'react-redux';
import Amplify from 'aws-amplify';

import { createStore } from './store';
import { MainRouter } from './routes';
import { LandingSignup } from './screens/Landing/LandingSignup';
import './App.css';

const devConfig = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:c25430bc-857f-4446-8cad-aee5aac675a7",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_BGNk3aJnj",
    "aws_user_pools_web_client_id": "2fat8ip334d6jcqpj7fnc7ongb",
    "oauth": {
        "domain": "dreamcvgoogle-dev.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/resumes/,https://dreamcv.io/resumes/",
        "redirectSignOut": "http://localhost:3000/,https://dreamcv.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
      {
        "name": "dreamCVApi",
        "endpoint": "https://inupyd5yba.execute-api.eu-west-1.amazonaws.com/dev",
        "region": "eu-west-1"
      }
    ],
    "aws_dynamodb_all_tables_region": "eu-west-1",
    "aws_dynamodb_table_schemas": [
      {
        "tableName": "subscriptions-dev",
        "region": "eu-west-1"
      }
    ]
};

const prodConfig = {
  "aws_project_region": "eu-west-1",
  "aws_cognito_identity_pool_id": "eu-west-1:9212a843-97f4-44aa-8b0c-ca3b1aeb0821",
  "aws_cognito_region": "eu-west-1",
  "aws_user_pools_id": "eu-west-1_kztlwZPrP",
  "aws_user_pools_web_client_id": "31miml79v6qd4d7m8e4oe8sb8l",
  "oauth": {
      "domain": "dreamcvgoogle-prod.auth.eu-west-1.amazoncognito.com",
      "scope": [
          "phone",
          "email",
          "openid",
          "profile",
          "aws.cognito.signin.user.admin"
      ],
      "redirectSignIn": "http://localhost:3000/resumes/,https://dreamcv.io/resumes/",
      "redirectSignOut": "http://localhost:3000/,https://dreamcv.io/",
      "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_cloud_logic_custom": [
      {
          "name": "dreamCVApi",
          "endpoint": "https://lodemqhwmj.execute-api.eu-west-1.amazonaws.com/prod",
          "region": "eu-west-1"
      }
  ],
  "aws_dynamodb_all_tables_region": "eu-west-1",
  "aws_dynamodb_table_schemas": [
      {
          "tableName": "subscriptions-prod",
          "region": "eu-west-1"
      },
      {
          "tableName": "dreamCVResumes-prod",
          "region": "eu-west-1"
      },
      {
          "tableName": "dreamcvResumesDB-prod",
          "region": "eu-west-1"
      }
  ],
  "aws_user_files_s3_bucket": "dreamcv-bucket-pdf214000-prod",
  "aws_user_files_s3_bucket_region": "eu-west-1"
}

const awsConfigPerEnv: { [key in string]: any } = {
  'dev': prodConfig,
  'prod': prodConfig
}

const awsConfig = awsConfigPerEnv[process.env.REACT_APP_BUILD_ENV || 'dev'];

// Assuming you have two redirect URIs, and the first is for localhost and second is for production
const [
  localRedirectSignIn,
  productionRedirectSignIn,
] = awsConfig.oauth.redirectSignIn.split(",");

const [
  localRedirectSignOut,
  productionRedirectSignOut,
] = awsConfig.oauth.redirectSignOut.split(",");

const updatedAwsConfig = {
  ...awsConfig,
  oauth: {
    ...awsConfig.oauth,
    redirectSignIn: process.env.REACT_APP_BUILD_ENV === 'prod' ? productionRedirectSignIn : localRedirectSignIn,
    redirectSignOut: process.env.REACT_APP_BUILD_ENV === 'prod' ? productionRedirectSignOut : localRedirectSignOut,
  }
}

Amplify.configure(updatedAwsConfig);

function App() {
  const store = createStore();
  const shouldRenderLandingPage = false;

  if (shouldRenderLandingPage) return <LandingSignup />;

  return (
    <Provider store={store}>
      <div className={shouldRenderLandingPage ? "LandingPage" : "App"}>
        <MainRouter />
      </div>
    </Provider>
  );
}

export default App;
