import React, { FC, useEffect, useRef, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import Joyride, { Step, TooltipRenderProps } from 'react-joyride';
import {
  createMuiTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import { EdiComponent } from './EditComponent';
import { PreviewResume } from "./PreviewResume";
import { Button, Card, TextInput } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { cvInfoActions } from '../../store/cvInfo';
import { RootState } from "../../store";

interface Props {
    children: React.ReactChild;
    index: number;
    value: number;
    dir: string;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      // Tailwind bg-indigo-600
      main: "rgb(79, 70, 229)",
    },
    secondary: {
      // Tailwind bg-indigo-600
      main: "rgb(79, 70, 229)",
    },
  },
});

const TabPanel: FC<Props> = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      className="focus:outline-none bg-gray-50"
      {...other}
    >
      {value === index && <div className="pl-5 pr-5 pb-10">{children}</div>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
}));

const previewResumeTutorialHasBeenShownKey = 'previewResumeTutorialHasBeenShownKey';

export const Generator: React.FC<{
  id: string;
  className?: string;
  style?: React.CSSProperties;
  previewOnly?: boolean;
}> = ({ id, className, style, previewOnly }) => {
  const previewTutorialHasBeenShown = !!localStorage.getItem(previewResumeTutorialHasBeenShownKey);
  const [shouldRunTutorial, setShouldRunTutorial] = useState(false);
  // Tutorial steps should be reactive in order to display them immediately
  const [steps] = useState<Step[]>([
    {
      title: "Resume preview tab",
      content: "You can preview your resume right away by clicking here!",
      spotlightPadding: 20,
      disableBeacon: true,
      hideFooter: true,
      target: '#full-width-tab-1',
      spotlightClicks: true,
      disableScrolling: true
    },
  ]);

  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const cvInfo = useSelector((state: RootState) => state.cvInfo.byId[id]);
  useEffect(() => {
    dispatch(cvInfoActions.setSelectedId({ id }));
  }, [dispatch]);

  const timeout = useRef<NodeJS.Timeout | undefined>();
  useEffect(() => {
    // We keep resetting the timeout to debounce the call
    if (timeout.current)
      clearTimeout(timeout.current)
    
    if (cvInfo.updateStatus === 'success' && !previewTutorialHasBeenShown) {
      timeout.current = setTimeout(() => {
        setShouldRunTutorial(true);
      }, 1000);
    }

    return () => {
      timeout.current && clearTimeout(timeout.current);
    }
  }, [cvInfo.updateStatus]);

  const closeTutorial = () => {
    localStorage.setItem(previewResumeTutorialHasBeenShownKey, 'shown');
    setShouldRunTutorial(false);
  }

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  return (
    <ThemeProvider theme={theme}>
      <Joyride
        continuous={false}
        run={shouldRunTutorial}
        tooltipComponent={Tooltip}
        showProgress={false}
        showSkipButton={true}
        scrollToFirstStep={false}
        disableScrolling={true}
        steps={steps}
        stepIndex={0}
        locale={{ close: 'Got it!' }}
        styles={{
          options: {
            primaryColor: '#5a67d8',
            zIndex: 10000,
          }
        }}
        callback={(data) => {
          if (data.action === 'close')
            closeTutorial();
        }}
      />
      <Box pt={3} pb={20} className="flex flex-1 justify-center bg-gray-50 h-min-max h-full">
        <div
            className={"flex flex-col flex-1 w-full md:w-screen-9/10 max-w-7xl " + (className ? className : "")}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              aria-label="full width tabs example"
              className="mb-6 ml-3 mr-3"
            >
              <Tab
                label="Edit resume"
                className="focus:outline-none"
                disableRipple
                {...a11yProps(0)}
              />
              <Tab
                label={previewOnly ? "Preview resume" : "Preview & Download"}
                className="focus:outline-none"
                disableRipple
                onClick={() => {
                  if (shouldRunTutorial)
                    closeTutorial();
                }}
                {...a11yProps(1)}
              />
            </Tabs>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={value}
              onChangeIndex={handleChangeIndex}
              className="h-min-screen h-full p-0"
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <div className="flex flex-col">
                <p className="ml-7 mb-4 text-sm text-gray-500">
                  All fields are optional. You can choose to leave them blank, they won't
                  show up in your resume.
                </p>
                <Card
                  headerChildren={
                    <TextInput
                      label="Resume Title"
                      descr="The title won't show up in your resume. It should help you to manage different resumes on the dashboard."
                      className="mb-2"
                      value={cvInfo.title}
                      placeholder="e.g. Resume for Copywriter position @ DreamCV Inc"
                      onChange={(
                        event: React.ChangeEvent<
                          HTMLTextAreaElement | HTMLInputElement
                        >
                      ) => {
                        dispatch(
                          cvInfoActions.stringFieldChanged({
                            field: "title",
                            value: event.target.value,
                          })
                        );
                      }}
                    />
                  }
                >
                  <EdiComponent id={id} />
                </Card>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <PreviewResume id={id} previewOnly={previewOnly} />
              </TabPanel>
            </SwipeableViews>
          </div>
      </Box>
    </ThemeProvider>
  );
};

const Tooltip = ({
  step,
  skipProps,
  tooltipProps,
  closeProps
}: TooltipRenderProps) => {
  return (
    <div {...tooltipProps} className="flex flex-col bg-white min-w-max p-8 rounded-lg justify-center">
      {step.title && <h3 className="text-base text-center font-semibold">{step.title}</h3>}
      {step.content && <p className="text-base text-center mt-2">{step.content}</p>}
      <Button {...closeProps} className="mt-4 self-center">
        Got it!
      </Button>
    </div>
  );
};