import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { API } from 'aws-amplify';
import { loadStripe, Stripe } from '@stripe/stripe-js';

import { RootState } from '..';
import { authActions } from '../auth';
import { plansActions } from '../plans';
import { stripePublicKey } from '../../services';

const stripePromise = loadStripe(stripePublicKey);

export function* selectPlan(action: ReturnType<typeof authActions.planSelected>) {
    const { priceId } = action.payload;
    try {
        const email: string = yield select((rootState: RootState) => rootState.auth.email);
        const { sessionId }: { sessionId: string } = yield call(
            [API, 'post'],
            'dreamCVApi',
            '/checkout-session',
            {
                body: {
                    priceId,
                    email
                }
            }
        );
        const stripe: Stripe = yield stripePromise;
        yield call([stripe, 'redirectToCheckout'], {
            sessionId
        });
        yield put(plansActions.selectPlanSucceeded({ priceId }));
    } catch (e) {
        console.error('Error in selectPlan saga: ', e);
        yield put(plansActions.selectPlanFailed({ priceId, error: e }));
    }
}

export function* plansSagas() {
  yield all([
    takeLatest(plansActions.selectPlanRequested.type, selectPlan),
  ]);
}