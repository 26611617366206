import React, { FC } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        display: 'flex',
        backgroundColor: theme.palette.background.paper,
        justifyContent: 'center',
        justifyItems: 'center',
        alignItems: 'center',
        alignContent: 'center'
    },
    textField: {
        display: 'flex',
    }
}));

export const Success = () => {
    const classes = useStyles();
    
    return (
        <div className={classes.root}>
            <Typography variant="h1" gutterBottom>
                Successfully signed up to DreamCV Pro 🥳
            </Typography>
        </div>
    );
}