import { CheckCircleIcon, ExclamationIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import React from 'react'


type AlertType = "warning" | "success" | "error" | "info";
type Props = {
    type: AlertType;
    className?: string;
}

const iconByType: { [key in AlertType]: JSX.Element } = {
    "warning": <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />,
    "success": <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />,
    "info": <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />,
    "error": <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />,
};

const textColorByType: { [key in AlertType]: string } = {
    "warning": "text-yellow-700",
    "success": "text-green-700",
    "info": "text-blue-700",
    "error": "text-red-700",
};

const backgroundColorByType: { [key in AlertType]: string } = {
    "warning": "bg-yellow-50",
    "success": "bg-green-50",
    "info": "bg-blue-50",
    "error": "bg-red-50",
};

export const Alert: React.FC<Props> = ({ type, children, className }) => {
    let wrapperClassname = "rounded-md p-4 " + backgroundColorByType[type] + " ";
    if (className)
        wrapperClassname += className;

    return (
      <div className={wrapperClassname}>
        <div className="flex">
          <div className="flex-shrink-0">
            {iconByType[type]}
          </div>
          <div className="ml-3">
            <div className={"text-sm " + textColorByType[type]}>
              <p>
                {children}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }