import React, { FC } from 'react';
import ReactPDF, { Document, Page, Text, View, StyleSheet, Canvas, Link, Image, Font } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { CVInfo, EducationItem, ExperienceItem, Language, Project } from '../../../types';
import BebasNeueRegular from './assets/BebasNeue-Regular.ttf';
import HelveticaNeueBold from './assets/HelveticaNeue-Bold.ttf';
import HelveticaNeueThin from './assets/HelveticaNeue-Thin.ttf';
import HelveticaNeueRegular from './assets/HelveticaNeue-Roman.ttf';
import playButtonIcon from './assets/playButton.png';
import githubLogo from './assets/githubLogo.png';
import linkedingLogo from './assets/linkedingLogo.png';
import thumbsUp from './assets/thumbsUp.png';
import { shouldRenderEducations, shouldRenderExperiences, shouldRenderLanguages, shouldRenderLinks, shouldRenderProjects, shouldRenderStringArray } from "./utils";

Font.register({
    family: 'BebasNeue',
    format: "truetype",
    src: BebasNeueRegular
});
Font.register({
    family: 'HelveticaNeueBold',
    format: "truetype",
    src: HelveticaNeueBold
});
Font.register({
    family: 'HelveticaNeueThin',
    format: "truetype",
    src: HelveticaNeueThin
});
Font.register({
    family: 'HelveticaNeueRegular',
    format: "truetype",
    src: HelveticaNeueRegular
});

export const Netflix: FC<{ cvInfo: CVInfo, isPro: boolean }> = ({ cvInfo, isPro }) => {
    const shouldRenderName = cvInfo.firstName || cvInfo.lastName;

    return (
      <Document
        title={`${cvInfo.firstName}${cvInfo.lastName}Resume`}
        author={`${cvInfo.firstName} ${cvInfo.lastName}`}
      >
        <Page size="A4" style={styles.page}>
          <View style={styles.wrapper}>
            {shouldRenderName ? (
              <Text style={styles.nameText}>
                {cvInfo.firstName + " " + cvInfo.lastName}
              </Text>
            ) : null}
            <ContactDetail title="email">{cvInfo.email}</ContactDetail>
            <ContactDetail title="phone">{cvInfo.phone}</ContactDetail>
            <ContactDetail title="address">{cvInfo.address}</ContactDetail>
            {shouldRenderLinks(cvInfo.links) && (
              <View
                style={{ flexDirection: "row", paddingTop: 15, flexWrap: "wrap" }}
              >
                {cvInfo.links.map((link, index) => (
                  <Button
                    index={index}
                    url={link.link}
                    type={getButtonIcon(link.title, index) === playButtonIcon ? 'primary' : 'secondary'}
                    style={{ marginRight: 15, marginBottom: 15 }}
                  >
                    {link.title}
                  </Button>
                ))}
              </View>
            )}
            <View style={{ flexDirection: "row", marginTop: 15 }}>
              {shouldRenderExperiences(cvInfo.experienceItems) && (
                <View style={styles.experience}>
                  <Title style={{ alignSelf: "flex-start", marginBottom: 10 }}>
                    Experience
                  </Title>
                  {cvInfo.experienceItems.map((experienceItem, index) => (
                    <ExperienceCard
                      key={index}
                      index={index}
                      count={cvInfo.experienceItems.length}
                      experienceItem={experienceItem}
                    />
                  ))}
                </View>
              )}
              <View style={{ flex: 0.4 }}>
                {shouldRenderEducations(cvInfo.educationItems) && (
                  <>
                    <Title style={{ alignSelf: "flex-start" }}>Education</Title>
                    <View
                      style={{ justifyContent: "flex-start" }}
                    >
                      {cvInfo.educationItems.map((educationItem, index) => (
                        <EducationCard
                          key={index}
                          educationItem={educationItem}
                        />
                      ))}
                    </View>
                  </>
                )}
                {shouldRenderStringArray(cvInfo.awards) && (
                  <>
                    <Title style={{ alignSelf: "flex-start", marginTop: 30 }}>
                      Awards
                    </Title>
                    <View
                      style={{ justifyContent: "flex-start" }}
                    >
                      {cvInfo.awards.map((award, index) => (
                        <BodyRegular style={{ marginTop: 10 }} key={index}>{award}</BodyRegular>
                      ))}
                    </View>
                  </>
                )}
                {shouldRenderStringArray(cvInfo.skills) && (
                  <>
                    <Title style={{ alignSelf: "flex-start", marginTop: 30 }}>
                      Skills
                    </Title>
                    <View
                      style={{ justifyContent: "flex-start", marginTop: 10 }}
                    >
                      <ArrayTextDisplay array={cvInfo.skills} />
                    </View>
                  </>
                )}
                <ProjectsSection projects={cvInfo.projects} />
                <LanguagesSection languages={cvInfo.languages} />
                {shouldRenderStringArray(cvInfo.hobbies) && (
                  <>
                    <Title style={{ alignSelf: "flex-start", marginTop: 30 }}>
                      Interests & Hobbies
                    </Title>
                    <View
                      style={{ justifyContent: "flex-start", marginTop: 10 }}
                    >
                      <ArrayTextDisplay array={cvInfo.hobbies} />
                    </View>
                  </>
                )}
              </View>
            </View>
          </View>
          {isPro && (
            <Text fixed style={{ position: 'absolute', left: 0, right: 0, bottom: 0, height: 20, textAlign: 'center', fontFamily: 'HelveticaNeueRegular', fontSize: 11, color: textGray }} render={({ pageNumber, totalPages }) => (
                totalPages > 1 ? `Page ${pageNumber} of ${totalPages}` : ''
            )} />
          )}
          {!isPro && (
              <View
              fixed
              style={{
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 0,
                  height: 35,
                  alignItems: "center",
                  justifyContent: "center",
              }}
              >
              <Text
                  style={{
                  fontSize: 11,
                  color: 'white',
                  fontFamily: "HelveticaNeueRegular",
                  }}
              >
                  {"Created using "}
                  <Link src="https://www.dreamcv.io/">
                  <Text
                      style={{
                      fontSize: 11,
                      color: 'white',
                      fontFamily: "HelveticaNeueBold",
                      }}
                  >
                      dreamcv.io
                  </Text>
                  </Link>
              </Text>
              </View>
          )}
        </Page>
      </Document>
    );
}

type ButtonStyle = 'primary' | 'secondary';
const Button: FC<{ url: string, type?: ButtonStyle, style?: Style, children: string, index: number }> = ({ url, type, style, children, index }) => {
    const buttonStyles: { [key in ButtonStyle]: Style } = {
        'primary': styles.primaryButtonStyle,
        'secondary': styles.secondaryButtonStyle
    };
    const textStyles: { [key in ButtonStyle]: Style } = {
        'primary': styles.primaryButtonText,
        'secondary': styles.secondaryButtonText
    };
    const buttonType = type || 'primary';
    const buttonStyle = style ? [buttonStyles[buttonType], style, styles.row] : [buttonStyles[buttonType], styles.row];
    const textStyle = textStyles[buttonType];

    return (
        <Link src={url}>
            <View style={buttonStyle}>
                <Image src={getButtonIcon(children, index)} style={{ width: 15, height: 15, marginRight: 7 }} />
                <Text style={textStyle}>{children}</Text>
            </View>
        </Link> 
    );
}

const getButtonIcon = (text: string, index: number) => {
    if (text.toLocaleLowerCase().includes('github')) {
        return githubLogo;
    }
    if (text.toLocaleLowerCase().includes('linkedin')) {
        return linkedingLogo;
    }
    if (index === 0)
      return playButtonIcon;
    
    return "";
}

const Title: FC<{ children: string, style?: Style }> = (props) => {
    const titleStyle = props.style ? [styles.title, props.style] : styles.title;
    return (
        <View style={titleStyle}>
            <Text style={styles.sectionTitle}>{props.children}</Text>
        </View>
    )
}

const ExperienceCard: FC<{experienceItem: ExperienceItem, style?: Style, key: any, index: number, count: number }> = ({ experienceItem, key, index, count }) => {
    const cardStyle: Style[] = [styles.experienceCard, styles.topBorder];
    if (index === count - 1)
        cardStyle.push(styles.bottomBorder);

    return (
        <View style={cardStyle} key={key}>
            <View style={{ flexDirection: 'row', marginBottom: 5 }}>
                {
                    experienceItem.companyName ? 
                        <Header2>
                            {experienceItem.companyName + ', '}
                        </Header2> : null
                }
                {experienceItem.position ?<Text style={{ fontSize: 11, color: 'white', fontFamily: 'HelveticaNeueThin' }}>
                    {experienceItem.position}
                </Text> : null}
            </View>
            {experienceItem.dates ? <DateText>
                {experienceItem.dates + (experienceItem.location ? ` | ${experienceItem.location}` : '' )}
            </DateText> : null}
            {
                experienceItem.achievements.map(achievement => achievement ? 
                    (
                        <ExperienceAchievement>
                            {achievement}
                        </ExperienceAchievement>
                    ) : null
                )
            }
        </View>
    );
}

const ExperienceAchievement: FC<{ children: string }> = ({ children }) => {
    return (
        <View wrap={false} style={{ flexDirection: 'row', paddingRight: 10, marginTop: 10 }}>
            <Image src={thumbsUp} style={{ width: 10, height: 10, marginRight: 5, marginTop: 2 }} />
            <BodyRegular>
                {children}
            </BodyRegular>
        </View>
    );
}

const EducationCard: FC<{ educationItem: EducationItem, key: any }> = ({ educationItem, key }) => (
    <View key={key} style={{ marginTop: 10 }}>
        {!!educationItem.instituteName && <Header2>
            {educationItem.instituteName}
        </Header2>}
        {!!educationItem.location && <Header2 style={{ marginTop: 5 }}>
            {educationItem.location}
        </Header2>}
        {!!educationItem.degree && <BodyRegular style={{ marginTop: 5 }}>
            {educationItem.degree}
        </BodyRegular>}
        {!!educationItem.dates && <DateText style={{ marginTop: 5 }}>
            {educationItem.dates}
        </DateText>}
    </View>
);

const DateText: FC<{ children: string, style?: Style }> = (props) => {
    const dateTextStyle = props.style ? [styles.dateText, props.style] : styles.dateText;

    return (
        <Text style={dateTextStyle}>
            {props.children}
        </Text>
    );
}

const BodyRegular: FC<{ style?: Style }> = ({ children, style }) => {
    const bodyRegularStyle = style ? [styles.bodyRegular, style] : styles.bodyRegular;
    return (
        <Text style={bodyRegularStyle}>
            {children}
        </Text>
    );
}

const ContactDetail: FC<{ style?: Style, title: string, children: string }> = ({ style, children, title }) => {
    if (!children)
        return null;
        
    return (
        <BodyRegular style={{ marginTop: 0, ...style }}>
            <BodyRegular style={{ color: textGray }}>{`${title}: `}</BodyRegular>
            {children}
        </BodyRegular>
    );
}

const Header2: FC<{ children: string, style?: Style }> = ({ children, style }) => {
    const header2Style = style ? [styles.header2, style] : styles.header2;
    
    return (
        <Text style={header2Style}>
            {children}
        </Text>
    );
}

const ArrayTextDisplay: FC<{ array: string[] }> = ({ array }) => {
    if (!array.length)
        return null;
    
    return (
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start'  }}>
            {array.map((value, index) => {
                const components = [
                    <BodyRegular style={{ marginBottom: 5 }}>
                        {value}
                    </BodyRegular>
                ];

                if (index !== array.length - 1)
                    components.push(
                        <View style={{ marginLeft: 4, marginRight: 4, marginBottom: 5 }}>
                            <BodyRegular style={{ color: red, fontSize: 7 }}>
                                {'•'}
                            </BodyRegular>
                        </View>
                    );
                
                return components;
            })}
        </View>
    )
}

const ProjectsSection: React.FC<{ projects: Project[] }> = ({ projects }) => {
  if (!shouldRenderProjects(projects))
    return null;
  
  return (
    <>
      <Title style={{ alignSelf: "flex-start", marginTop: 30 }}>
        Projects
      </Title>
      <View
        style={{ justifyContent: "flex-start", marginTop: 10 }}
      >
        {projects.map(project => (
          <View style={{ marginTop: 5 }}>
            <Link src={project.link} style={styles.header2}>
              {project.title}
            </Link>
            <BodyRegular style={{ marginTop: 5 }}>
                {project.description}
            </BodyRegular>
          </View>
        ))}
      </View>
    </>
  )
}

const LanguagesSection: React.FC<{ languages: Language[] }> = ({ languages }) => {
  if (!shouldRenderLanguages(languages))
    return null;
  
  return (
    <>
      <Title style={{ alignSelf: "flex-start", marginTop: 30 }}>
        Languages
      </Title>
      <View
        style={{ justifyContent: "flex-start", marginTop: 10 }}
      >
        {languages.map(language => (
          <View style={{ marginTop: 5, flexDirection: 'row' }}>
            <Header2>
              {language.name}
            </Header2>
            <BodyRegular style={{ marginLeft: 5 }}>
                {" -  " + language.fluency}
            </BodyRegular>
          </View>
        ))}
      </View>
    </>
  )
}

const backgroundBlack = 'rgb(24,24,24)';
const red = '#D32F27';
const textGray = '#777777';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: backgroundBlack,
        paddingHorizontal: 30,
        paddingTop: 15,
        paddingBottom: 20
    },
    nameText: {
        color: red,
        fontSize: 36, 
        fontWeight: 'extrabold', 
        fontFamily: 'BebasNeue'
    },
    sectionTitle: {
        fontSize: 18, 
        fontWeight: 'bold', 
        color: 'white', 
        fontFamily: 'HelveticaNeueBold'
    },
    section: {
        padding: 10,
        margin: 0
    },
    wrapper: {
        flex: 1,
    },
    regularText: {
        color: 'white', 
        fontSize: 14 
    },
    primaryButtonText: {
        color: backgroundBlack, 
        fontSize: 11,
        fontFamily: 'HelveticaNeueBold',
        textDecorationColor: 'white',
        height: 15,
        marginTop: 7
    },
    secondaryButtonText: {
        color: 'white', 
        fontSize: 11,
        fontFamily: 'HelveticaNeueBold',
        textDecorationColor: '#6D6D6E',
        height: 15,
        marginTop: 7
    },
    primaryButtonStyle: { 
        height: 27, 
        borderRadius: 3, 
        paddingHorizontal: 15, 
        alignItems: 'center', 
        justifyContent: 'center',
        backgroundColor: 'white',
    },
    secondaryButtonStyle: { 
        height: 27, 
        borderRadius: 3, 
        paddingHorizontal: 15, 
        alignItems: 'center', 
        justifyContent: 'center',
        backgroundColor: '#6D6D6E'
    },
    experience: {
        flex: 0.6,
        paddingRight: 20,
    },
    title: {
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    dateText: { 
        fontSize: 11, 
        color: textGray, 
        fontFamily: 'HelveticaNeueRegular'
    },
    header2: {
        fontSize: 11, 
        color: 'white', 
        flexWrap: 'wrap',
        fontFamily: 'HelveticaNeueBold'
    },
    bodyRegular: {
        fontWeight: 'light', 
        fontSize: 11, 
        color: 'white', 
        flexWrap: 'wrap',
        fontFamily: 'HelveticaNeueRegular'
    },
    row: {
        flexDirection: 'row'
    },
    experienceCard: {
        paddingHorizontal: 12,
        paddingVertical: 20, 
        borderRadius: 15
    },
    topBorder: {
        borderTopWidth: 1, 
        borderTopColor: '#404040', 
    },
    bottomBorder: {
        borderBottomWidth: 1, 
        borderBottomColor: '#404040',
    }
});