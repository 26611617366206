import React, { FC } from 'react';
import ReactPDF, { Document, Page, Text, View, StyleSheet, Canvas, Link, Image, Font } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import ArialBlack from './assets/fonts/ArialBlack.ttf';
import HelveticaNeueBold from '../assets/HelveticaNeue-Bold.ttf';
import HelveticaNeueMedium from './assets/fonts/HelveticaNeue-Medium.ttf';
import verifiedIcon from './assets/images/verifiedIcon.png';
import phoneIcon from './assets/images/phoneIcon.png';
import locationIcon from './assets/images/locationIcon.png';
import linkIcon from './assets/images/linkIcon.png';
import likeRedIcon from './assets/images/likeRedIcon.png';

import { CVInfo, EducationItem, ExperienceItem, Language, Project } from '../../../../types';

import { shouldRenderEducations, shouldRenderExperiences, shouldRenderLanguages, shouldRenderLinks, shouldRenderProjects, shouldRenderStringArray } from '../utils';

Font.register({
    family: 'ArialBlack',
    format: "truetype",
    src: ArialBlack
});
Font.register({
    family: 'HelveticaNeueMedium',
    format: "truetype",
    src: HelveticaNeueMedium
});
Font.register({
    family: 'HelveticaNeueBold',
    format: "truetype",
    src: HelveticaNeueBold
});

const textBlack = 'black';
const textBlue = '#1B95E0';
const textGray = '#5B7083';
const borderGray = '#EBEEF0';
const backgroundGray = '#F7F9FA';

export const Twitter: FC<{ cvInfo: CVInfo, isPro: boolean }> = ({ cvInfo, isPro }) => {
    return (
        <Document
            title={`${cvInfo.firstName}${cvInfo.lastName}Resume`}
            author={`${cvInfo.firstName} ${cvInfo.lastName}`}
        >
            <Page size="A4" style={{ paddingBottom: isPro ? 0 : 35 }}>
                <View style={{ flexDirection: 'row' }}>
                    <View style={{ flex: 0.55 }}>
                        <ContactsSection cvInfo={cvInfo} /> 
                        <ExperienceSection experienceItems={cvInfo.experienceItems} />
                    </View>
                    <View style={{ flex: 0.45, paddingHorizontal: 20, paddingBottom: 20 }}>
                        <EducationSection educationItems={cvInfo.educationItems} />
                        <AwardsSection awards={cvInfo.awards} />
                        <SkillsSection skills={cvInfo.skills} />
                        <ProjectsSection projects={cvInfo.projects} />
                        <LanguagesSection languages={cvInfo.languages} />
                        <HobbiesSection hobbies={cvInfo.hobbies} />
                    </View>
                </View>
                <View fixed style={{ width: 1, backgroundColor: borderGray, top: 0, left: 305, bottom: 0, position: 'absolute', height: '100%' }} />
                {isPro && (
                    <Text fixed style={{ position: 'absolute', left: 0, right: 20, bottom: 0, height: 20, textAlign: 'right', fontFamily: 'HelveticaNeueMedium', fontSize: 11, color: textBlack }} render={({ pageNumber, totalPages }) => (
                        totalPages > 1 ? `${pageNumber} / ${totalPages}` : ''
                    )} />
                )}
                {!isPro && (
                    <View
                        fixed
                        style={{
                            position: "absolute",
                            left: 0,
                            right: 0,
                            bottom: 0,
                            height: 35,
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "white"
                        }}
                        >
                        <Text
                            style={{
                            fontSize: 11,
                            color: textBlue,
                            fontFamily: "HelveticaNeueMedium",
                            }}
                        >
                            {"Created using "}
                            <Link src="https://www.dreamcv.io/">
                            <Text
                                style={{
                                fontSize: 11,
                                color: textBlue,
                                fontFamily: "HelveticaNeueBold",
                                }}
                            >
                                dreamcv.io
                            </Text>
                            </Link>
                        </Text>
                    </View>
                )}
            </Page>
        </Document>
    )
}

const ContactsSection: FC<{ cvInfo: CVInfo }> = ({ cvInfo }) => (
    <View style={{ paddingHorizontal: 20, paddingVertical: 10 }}>
        {(!!cvInfo.firstName || !!cvInfo.lastName) && (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ color: textBlack, fontFamily: 'ArialBlack', fontSize: 20 }}>
                    {`${cvInfo.firstName} ${cvInfo.lastName}`}
                </Text>
                <Image src={verifiedIcon} style={{ width: 23, height: 23, marginLeft: 5 }} />
            </View>
        )}
        {!!cvInfo.email && (
            <Text style={{ fontFamily: 'HelveticaNeueMedium', fontSize: 13, color: textGray, marginTop: 5 }}>
                {cvInfo.email}
            </Text>
        )}
        {!!cvInfo.phone && (
            <View style={{ flexDirection: 'row', marginTop: 7, alignItems: 'center' }}>
                <Image src={phoneIcon} style={{ width: 17, height: 17, marginRight: 5 }} />
                <Text style={{ fontFamily: 'HelveticaNeueMedium', fontSize: 13, color: textGray, marginTop: 9 }}>
                    {cvInfo.phone}
                </Text>
            </View>
        )}
        {!!cvInfo.address && (
            <View style={{ flexDirection: 'row', marginTop: 5, alignItems: 'center' }}>
                <Image src={locationIcon} style={{ width: 17, height: 17, marginRight: 5 }} />
                <Text style={{ fontFamily: 'HelveticaNeueMedium', fontSize: 13, color: textGray, marginTop: 9 }}>
                    {cvInfo.address}
                </Text>
            </View>
        )}
        {shouldRenderLinks(cvInfo.links) && (
            <View style={{ flexDirection: 'row', marginTop: 5, flexWrap: 'wrap' }}>
                {cvInfo.links.map((link) => (
                    <LinkComponent src={link.link}>
                        {link.title}
                    </LinkComponent>
                ))}
            </View>
        )} 
    </View>
);

const LinkComponent: React.FC<{ children: string, src: string }> = ({ children, src }) => (
    <View wrap={false} style={{ flexDirection: 'row', marginRight: 10, alignItems: 'center' }}>
        <Image src={linkIcon} style={{ width: 17, height: 17, marginRight: 5 }} />
        <Link src={src} style={{ fontFamily: 'HelveticaNeueMedium', fontSize: 13, color: textBlue, marginTop: 9 }}>
            {children}
        </Link>
    </View>
);

const ExperienceSection: React.FC<{ experienceItems: ExperienceItem[] }> = ({ experienceItems }) => {
    if (!shouldRenderExperiences(experienceItems))
        return null;

    return (
        <View style={{ marginTop: 10 }}>
            <View style={{ borderBottomColor: borderGray, borderBottomWidth: 1, paddingLeft: 20 }}>
                <View>
                    <Text style={{ fontFamily: 'HelveticaNeueBold', fontSize: 14, color: textBlue, marginBottom: 5 }}>
                        Experience
                    </Text>
                    <View style={{ width: 75, height: 3, borderRadius: 1.5, backgroundColor: textBlue }} />
                </View>
            </View>
            {experienceItems.map(experienceItem => (
                <ExperienceItemComponent experienceItem={experienceItem} />
            ))}
        </View>
    )
}

const ExperienceItemComponent: React.FC<{ experienceItem: ExperienceItem }> = ({ experienceItem }) => (
    <View wrap={false} style={{ paddingHorizontal: 20, paddingVertical: 15, borderBottomWidth: 1, borderBottomColor: borderGray }}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <Link src={experienceItem.website} style={{ fontFamily: 'HelveticaNeueBold', fontSize: 12, color: textBlack }}>
                {experienceItem.companyName + ', '}
            </Link>
            <Text style={{ fontFamily: 'HelveticaNeueMedium', fontSize: 12, color: textGray }}>
                {experienceItem.position}
            </Text>
        </View>
        <Text style={{ fontFamily: 'HelveticaNeueMedium', fontSize: 11, color: textBlack, marginTop: 2 }}>
            {experienceItem.dates + (experienceItem.location ? ` | ${experienceItem.location}` : '')}
        </Text>
        {shouldRenderStringArray(experienceItem.achievements) && (
            experienceItem.achievements.map(achievement => (
                <View style={{ flexDirection: 'row', marginTop: 7 }}>
                    <Image src={likeRedIcon} style={{ width: 15, height: 13, marginRight: 7 }} />
                    <Text style={{ fontFamily: 'HelveticaNeueMedium', fontSize: 11, color: textBlack, marginTop: 2, marginRight: 20 }}>
                        {achievement}
                    </Text>
                </View>
            ))
        )}
    </View>
)

const EducationSection: React.FC<{ educationItems: EducationItem[] }> = ({ educationItems }) => {
    if (!shouldRenderEducations(educationItems))
        return null;
    
    return (
        <RoundedGrayCard title="Education">
            {educationItems.map((educationItem, index) => (
                <EducationItemComponent educationItem={educationItem} />
            ))}
        </RoundedGrayCard>
    );
}

const EducationItemComponent: React.FC<{ educationItem: EducationItem }> = ({ educationItem }) => {
    return (
        <>
            {!!educationItem.instituteName && (
                <Text style={{ fontFamily: 'HelveticaNeueBold', fontSize: 12, color: textBlack }}>
                    {educationItem.instituteName}
                </Text>
            )}
            {!!educationItem.location && (
                <Text style={{ fontFamily: 'HelveticaNeueBold', fontSize: 12, color: textBlack, marginTop: 5 }}>
                    {educationItem.location}
                </Text>
            )}
            {!!educationItem.degree && (
                <Text style={{ fontFamily: 'HelveticaNeueMedium', fontSize: 11, color: textBlack, marginTop: 5 }}>
                    {educationItem.degree}
                </Text>
            )}
            {!!educationItem.dates && (
                <Text style={{ fontFamily: 'HelveticaNeueMedium', fontSize: 11, color: textBlack, marginTop: 5 }}>
                    {educationItem.dates}
                </Text>
            )}
        </>
    );
}

const AwardsSection: React.FC<{ awards: string[] }> = ({ awards }) => {
    if (!shouldRenderStringArray(awards))
        return null;
    
    return (
        <RoundedGrayCard title="Awards">
            {awards.map(award => (
                <Text style={{ fontFamily: 'HelveticaNeueBold', fontSize: 12, color: textBlack }}>
                    {award}
                </Text>
            ))}
        </RoundedGrayCard>
    )
}

const ProjectsSection: React.FC<{ projects: Project[] }> = ({ projects }) => {
    if (!shouldRenderProjects(projects))
        return null;
    
    return (
        <RoundedGrayCard title="Projects">
            {projects.map(project => (
                <>
                    <Link src={project.link} style={{ fontFamily: 'HelveticaNeueBold', fontSize: 12, color: textBlue }}>
                        {project.title}
                    </Link>
                    <Text style={{ fontFamily: 'HelveticaNeueMedium', fontSize: 11, color: textGray, marginTop: 5 }}>
                        {project.description}
                    </Text>
                </>
            ))}
        </RoundedGrayCard>
    )
}

const LanguagesSection: React.FC<{ languages: Language[] }> = ({ languages }) => {
    if (!shouldRenderLanguages(languages))
        return null;
    
    return (
        <RoundedGrayCard title="Languages">
            {languages.map(language => (
                <View style={{ flexDirection: 'row' }}>
                    <Text style={{ fontFamily: 'HelveticaNeueBold', fontSize: 12, color: textBlack }}>
                        {language.name}
                    </Text>
                    <Text style={{ fontFamily: 'HelveticaNeueMedium', fontSize: 11, color: textBlack, marginLeft: 5, marginTop: 1 }}>
                        {" -  " + language.fluency}
                    </Text>
                </View>
            ))}
        </RoundedGrayCard>
    )
}

const SkillsSection: React.FC<{ skills: string[] }> = ({ skills }) => {
    if (!shouldRenderStringArray(skills))
        return null;
    
    return (
        <RoundedGrayCard title="Skills" noBorder>
            <View style={{ flexWrap: 'wrap', flexDirection: 'row' }}>
                {skills.map(skill => (
                    <Chip>
                        {skill}
                    </Chip>
                ))}
            </View>
        </RoundedGrayCard>
    )
}

const HobbiesSection: React.FC<{ hobbies: string[] }> = ({ hobbies }) => {
    if (!shouldRenderStringArray(hobbies))
        return null;
    
    return (
        <RoundedGrayCard title="Hobbies & Interests" noBorder>
            <View style={{ flexWrap: 'wrap', flexDirection: 'row' }}>
                {hobbies.map(hobby => (
                    <Chip>
                        {hobby}
                    </Chip>
                ))}
            </View>
        </RoundedGrayCard>
    )
}

const Chip: React.FC<{ children: string }> = ({ children }) => (
    <View style={{ flexDirection: 'row', backgroundColor: 'white', borderWidth: 1, borderColor: '#C4C4C4', paddingTop: 6, paddingBottom: 2, paddingHorizontal: 9, marginRight: 5, marginTop: 5, alignItems: 'center', alignSelf: 'flex-start', height: 25, borderRadius: 12.5 }}>
        <Text style={{ fontFamily: 'HelveticaNeueMedium', fontSize: 11, color: textBlack }}>
            {children}
        </Text>
        <Text style={{ fontFamily: 'HelveticaNeueMedium', fontSize: 12, color: textBlue, marginLeft: 7 }}>
            +
        </Text>
    </View>
)

const CardSectionWrapper: React.FC<{ noBorder?: boolean }> = ({ children, noBorder }) => {
    const borderStyle = noBorder ? {} : { borderBottomWidth: 1, borderBottomColor: borderGray };
    return (
        <View style={[{ paddingVertical: 12, paddingHorizontal: 20 }, borderStyle]}>
            {children}
        </View>
    );
}

const RoundedGrayCard: React.FC<{ title: string, style?: Style, noBorder?: boolean }> = ({ title, children, style, noBorder }) => {
    const defaultStyle = { backgroundColor: backgroundGray, borderRadius: 16, paddingVertical: 10, marginTop: 20 };
    const wrapperStyle = style ? [defaultStyle, style] : defaultStyle;

    return (
        <View wrap={false} style={wrapperStyle}>
            <View style={{ borderBottomWidth: 1, borderBottomColor: borderGray, paddingBottom: 10, paddingHorizontal: 20 }}>
                <Text style={{ fontFamily: 'ArialBlack', fontSize: 14, color: textBlack }}>
                    {title}
                </Text>
            </View>
            <View>
                {React.Children.map(children, (child) => (
                    <CardSectionWrapper noBorder={noBorder}>
                        {child}
                    </CardSectionWrapper>
                ))}
            </View>
        </View>
    );
}