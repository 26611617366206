import React, { FC } from 'react';
import TextField, { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Add, Delete } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { cvInfoActions, ExperienceItemStringFields } from '../../store/cvInfo';
import { ExperienceItem } from '../../types';
import { Card, TextInput, TextInputProps } from "../../components";
import { PlusIcon as PlusIconSolid } from "@heroicons/react/solid";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        flexDirection: "column",
        justifyContent: 'center',
    },
    textField: {
        display: 'flex'
    }
  }));

export const ExperiencesForm: React.FC<{ id: string }> = ({ id }) => {
  const classes = useStyles();
  const cvInfo = useSelector((state: RootState) => state.cvInfo.byId[id]);
  const shouldRenderDeleteButton = cvInfo.experienceItems.length > 1;

  return (
    <>
      {cvInfo.experienceItems.map((experienceItem, index) => (
        <ExperienceInputItem
          key={index}
          index={index}
          experienceItem={experienceItem}
          shouldRenderDeleteButton={shouldRenderDeleteButton}
        />
      ))}
    </>
  );
};

type FormField = {
  field: ExperienceItemStringFields;
  type?: string;
  multiline?: boolean;
  textInputType: TextInputProps["textInputType"];
};

const fieldToLabel: { [key in ExperienceItemStringFields]: string } = {
    'companyName': 'Company name',
    'position': 'Position',
    'dates': 'Dates',
    'location': 'Location',
    'website': 'Website'
}

const fieldToPlaceHolder: { [key in ExperienceItemStringFields]: string } = {
    'companyName': 'e.g. DreamCV, Inc',
    'position': 'e.g. Software Engineer',
    'dates': 'e.g. Dec 2019 - Present',
    'location': 'e.g. Berlin, Germany',
    'website': 'www.dreamcv.io'
}

const formFields: FormField[] = [
  { field: "companyName", type: "text", textInputType: "default" },
  { field: "position", type: "text", textInputType: "default" },
  { field: "dates", type: "text", textInputType: "default" },
  { field: "location", type: "text", textInputType: "default" },
  { field: "website", type: "text", textInputType: "url" },
];

const shouldShowAddBulletPointButton = 'shouldShowAddBulletPointButton';

const ExperienceInputItem: FC<{ index: number, experienceItem: ExperienceItem, shouldRenderDeleteButton: boolean }> = ({ index, experienceItem, shouldRenderDeleteButton }) => {
    const dispatch = useDispatch();
    const renderAddDetailsPing = !localStorage.getItem(shouldShowAddBulletPointButton);

    const onChangeField = (field: FormField['field']): OutlinedTextFieldProps['onChange'] => ({ target: { value } }) => {
        dispatch(cvInfoActions.experienceChanged({ index, field, value }));
    }
    
    const onChangeAchievement = (achievementIndex: number): OutlinedTextFieldProps['onChange'] => ({ target: { value } }) => {
        dispatch(cvInfoActions.experienceAchievementChanged({ experienceIndex: index, achievementIndex, value }));
    }

    const onDeleteAchievementClick = (achievementIndex: number) => () => {
        dispatch(cvInfoActions.experienceAchievementDeleted({ experienceIndex: index, achievementIndex }));
    }

    const onAddAchievementClick = (achievementIndex: number) => () => {
        if (renderAddDetailsPing) 
          localStorage.setItem(shouldShowAddBulletPointButton, 'true');
        dispatch(cvInfoActions.experienceAchievementAdded({ experienceIndex: index, achievementIndex }));
    }

    const onAddExperienceClick = (position: 'before' | 'after') => () => {
        dispatch(cvInfoActions.experienceAdded({ index, position }));
    }

    const onDeleteExperienceClick = () => {
        dispatch(cvInfoActions.experienceDeleted({ index }));
    }

    return (
      <>
        {index === 0 && <AddButton onClick={onAddExperienceClick("before")} />}
        <Card contentClassName="border border-gray-200">
          {shouldRenderDeleteButton && (
              <Box mb={3}>
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={onDeleteExperienceClick}
                  startIcon={<Delete />}
                >
                  remove
                </Button>
              </Box>
            )}
            {formFields.map((formField) => (
              <Box mb={2} key={formField.field}>
                <TextInput
                  label={fieldToLabel[formField.field]}
                  placeholder={fieldToPlaceHolder[formField.field]}
                  multiline={formField.multiline}
                  type={formField.type}
                  onChange={onChangeField(formField.field)}
                  value={experienceItem[formField.field]}
                  textInputType={formField.textInputType}
                />
              </Box>
            ))}
            {experienceItem.achievements.map((achievement, index) => (
              <Box
                mb={index === experienceItem.achievements.length - 1 ? 0 : 2}
                key={index}
              >
                <TextInput
                  label={"Details bullet point"}
                  placeholder={"e.g. Developed web apps using React"}
                  value={achievement}
                  multiline={true}
                  onChange={onChangeAchievement(index)}
                  rightHandChildren={
                    <>
                      {experienceItem.achievements.length > 1 && (
                        <IconButton
                          onClick={onDeleteAchievementClick(index)}
                          aria-label="delete"
                          color="primary"
                          className="text-indigo-600 focus:outline-none"
                        >
                          <Delete />
                        </IconButton>
                      )}
                      <IconButton
                        onClick={onAddAchievementClick(index)}
                        color="primary"
                        aria-label="add"
                        className="focus:outline-none"
                      >
                        {renderAddDetailsPing && (
                          <span className="animate-ping absolute inline-flex h-7 w-7 rounded-full bg-indigo-600 opacity-75"></span>
                        )}
                        <Add />
                      </IconButton>
                    </>
                  }
                />
              </Box>
            ))}
        </Card>
        <AddButton onClick={onAddExperienceClick("after")} />
      </>
    );
}

const AddButton: FC<{ onClick: () => void }> = ({ onClick }) => (
    <Box mt={3} mb={3} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Button variant="outlined" color="primary" onClick={onClick} size='small'>
            Add another experience here
        </Button>
    </Box>
);