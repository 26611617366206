import { useHistory, useParams } from "react-router-dom";
import { Generator } from "./Generator";
import { Button, Card } from "../../components";
import Container from "@material-ui/core/Container";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useRedirectLoggedOutToHome } from "../../hooks";

export const ResumeScreen = () => {
  useRedirectLoggedOutToHome();
  const { getSavedStatus, byId } = useSelector(
    (state: RootState) => state.cvInfo
  );
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  if (getSavedStatus === "success") {
    if (!(id in byId)) {
      history.push("/resumes");
      return null;
    }

    return (
      <>
        <Generator id={id} />
      </>
    );
  }

  return <LoadingResumeScreen />;
};

const LoadingResumeScreen = () => (
  <div className="bg-gray-50 h-screen">
    <Container maxWidth="lg" className="pt-28">
      <Card>
        <div className="animate-pulse ml-4 mr-4 mb-4 rounded-2xl h-8 bg-gray-300" />
        <div className="flex flex-row flex-1">
          <div className="animate-pulse w-1/5 mt-3 mb-3 mr-4 ml-4 items-center">
            <div className="rounded-2xl h-6 w-full bg-gray-300" />
            <div className="mt-6 rounded-2xl h-6 w-full bg-gray-300" />
            <div className="mt-6 rounded-2xl h-6 w-full bg-gray-300" />
            <div className="mt-6 rounded-2xl h-6 w-full bg-gray-300" />
            <div className="mt-6 rounded-2xl h-6 w-full bg-gray-300" />
            <div className="mt-6 rounded-2xl h-6 w-full bg-gray-300" />
          </div>
          <div className="animate-pulse w-4/5 mt-3 mb-3 mr-4">
            <div className="mt-3 rounded-xl h-8 w-full bg-gray-300" />
            <div className="mt-10 rounded-xl h-8 w-full bg-gray-300" />
            <div className="mt-10 rounded-xl h-8 w-full bg-gray-300" />
            <div className="mt-10 rounded-xl h-8 w-full bg-gray-300" />
            <div className="mt-10 rounded-xl h-10 w-full bg-gray-300" />
          </div>
        </div>
      </Card>
    </Container>
  </div>
);
