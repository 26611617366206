import { usePDF } from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { mapThemeToResumeTemplate } from "./ResumeTemplates";
import { LoadingPDFPreview } from "./LoadingPDFPreview";
import { isProSelector } from "../../store/selectors";
import {
  Document,
  Page,
} from "react-pdf/dist/esm/entry.webpack";
import { CloudDownloadIcon } from "@heroicons/react/outline";
import { isTemplateFree } from "../../utils";


// Create Document Component
export const PDFDocumentWrapper: React.FC<{
  id: string;
  className?: string;
  previewOnly?: boolean;
}> = ({ id, previewOnly }) => {
  const cvInfo = useSelector((state: RootState) => state.cvInfo.byId[id]);
  const isPro = useSelector(isProSelector);

  const Document = mapThemeToResumeTemplate[cvInfo.theme];
  const [instance, updateInstance] = usePDF({
    document: <Document cvInfo={cvInfo} isPro={isPro} />,
  });
  useEffect(updateInstance, [cvInfo]);

  if (!instance.url || instance.loading) return <LoadingPDFPreview previewOnly={previewOnly} />;

  return (
    <>
      <CanvasPDF previewOnly={previewOnly} url={instance.url!} />
      {/* <iframe
        title={"Resume"}
        className={
          "hidden lg:block rounded-tl-lg bg-transparent rounded-bl-lg rounded-tr-lg rounded-br-lg w-full md:rounded-tl-none md:rounded-bl-none h-192"
        }
        src={
          instance.url +
          "#view=Fit&navpanes=0" +
          (previewOnly ? "&toolbar=0" : "&toolbar=1")
        }
      /> */}
    </>
  );
};

const CanvasPDF: React.FC<{ url: string, previewOnly?: boolean }> = ({ url, previewOnly }) => {
  const [numberOfPages, setNumberOfPages] = useState(0);
  
  function onDocumentLoadSuccess({ numPages }: { numPages: number  }) {
    setNumberOfPages(numPages);
  }

  return (
    <>
      {!previewOnly && (
        <a
          target="_blank"
          href={url}
          className="inline-flex self-end items-center px-3 py-2 text-md leading-4 font-medium rounded-md text-indigo-600 hover:text-indigo-700 focus:outline-none mb-2"
        >
          <CloudDownloadIcon className="-ml-0.5 mr-2 h-5 w-5" aria-hidden="true" />
          Download in PDF format
        </a>
      )}
      {/* Small screen */}
      {numberOfPages === 0 && <LoadingPDFPreview previewOnly={previewOnly} />}
      <Document onLoadSuccess={onDocumentLoadSuccess} file={url} className="flex flex-col justify-center bg-gray-300 items-center sm:hidden rounded-lg py-5 ">
        {
          new Array(numberOfPages).fill(0).map((_, index) => <Page pageNumber={index + 1} width={300} renderTextLayer={false} />)
        }
      </Document>
      {/* Medium screen */}
      <Document onLoadSuccess={onDocumentLoadSuccess} file={url} className="hidden sm:flex flex-col bg-gray-300 rounded-lg lg:hidden justify-center items-center py-5 ">
        {
          new Array(numberOfPages).fill(0).map((_, index) => <Page pageNumber={index + 1} width={500} renderTextLayer={false} />)
        }
      </Document>
      {/* Large screen */}
      <Document onLoadSuccess={onDocumentLoadSuccess} file={url} className="hidden lg:flex flex-col bg-gray-300 rounded-lg justify-center items-center py-5 ">
        {
          new Array(numberOfPages).fill(0).map((_, index) => <Page pageNumber={index + 1} width={800} renderTextLayer={false} className="px-2 mb-5" />)
        }
      </Document>
    </>
  )
}

// function useDebouncedProps<T>(props: T) {
//     const timeout = useRef<NodeJS.Timeout | undefined>();
//     const [state, setState] = useState(props);

//     useEffect(() => {
//         if (timeout.current) clearTimeout(timeout.current);
//         timeout.current = setTimeout(() => {
//             setState(props);
//         }, 1000);

//         return () => {
//             if (timeout.current) clearTimeout(timeout.current);
//         };
//     }, [props]);

//     return state;
// }
