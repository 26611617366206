import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import Box from '@material-ui/core/Box';
import { ChipsAndInput } from '../../components';
import { cvInfoActions } from '../../store/cvInfo';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        flexDirection: "column",
        justifyContent: 'center',
    },
    textField: {
        display: 'flex',
        marginTop: theme.spacing(1)
    },
    chip: {
        margin: theme.spacing(0.5)
    }
  }));

export const ToolsAndSkillsForm: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch();
  const cvInfo = useSelector((state: RootState) => state.cvInfo.byId[id]);
  const classes = useStyles();

  const onAddItem = (field: "tools" | "skills") => (item: string) => {
    if (field === "skills") {
      dispatch(cvInfoActions.skillAdded({ value: item }));
    } else {
      dispatch(cvInfoActions.toolAdded({ value: item }));
    }
  };

  const onDeleteItem = (field: "tools" | "skills") => (index: number) => {
    if (field === "skills") {
      dispatch(cvInfoActions.skillDeleted({ index }));
    } else {
      dispatch(cvInfoActions.toolDeleted({ index }));
    }
  };

  return (
    <>
      {formFields.map((formField) => (
        <Box mb={3} key={formField.field}>
          <ChipsAndInput
            title={fieldToTitle[formField.field]}
            placeholder={fieldToPlaceholder[formField.field]}
            chipsData={cvInfo[formField.field]}
            onAddItem={onAddItem(formField.field)}
            onDeleteItem={onDeleteItem(formField.field)}
          />
        </Box>
      ))}
    </>
  );
};

type FieldName = 'tools' | 'skills';
type FormField = {
    field: FieldName,
    type?: string,
    multiline?: boolean,
};

const formFields: FormField[] = [{ field: "skills" }];

const fieldToPlaceholder: { [key in FieldName]: string } = {
    'tools' : 'e.g. Adobe Aftereffect',
    'skills' : 'e.g. Mobile App Development',
}

const fieldToTitle: { [key in FieldName]: string } = {
    'tools' : 'Tools',
    'skills' : 'Skills',
}