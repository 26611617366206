import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import AttachMoney from '@material-ui/icons/AttachMoney';
import ContactSupport from '@material-ui/icons/ContactSupport';
import Dashboard from '@material-ui/icons/BrandingWatermarkSharp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../store/auth';
import { RootState } from '../store';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
    }),
);

export const NavBar = () => {
    const email = useSelector((state: RootState) => state.auth.email);
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <AppBar position="static" style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0 }}>
            <Container maxWidth="lg">
                <Toolbar>
                <Typography variant="h6" noWrap>
                    DreamCV
                </Typography>
                <div className={classes.grow} />
                <div className={classes.sectionDesktop}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    disableElevation 
                    style={{ borderRadius: 0, height: 65 }} 
                    startIcon={<Dashboard />}
                    onClick={() => {
                        history.push('/resume');
                    }}
                >
                    My resume
                </Button>
                <Button 
                    variant="contained" 
                    color="primary" 
                    disableElevation 
                    style={{ borderRadius: 0, height: 65 }} 
                    startIcon={<ContactSupport />}
                    onClick={() => {
                        history.push('/contact');
                    }}
                >
                    Contact Us
                </Button>
                <Button 
                    variant="contained" 
                    color="primary" 
                    disableElevation 
                    style={{ borderRadius: 0, height: 65 }} 
                    startIcon={<AttachMoney />}
                    onClick={() => {
                        history.push('/pricing');
                    }}
                >
                    Pricing
                </Button>
                {!email && <Button 
                    variant="contained" 
                    color="primary" 
                    disableElevation 
                    style={{ borderRadius: 0, height: 65 }} 
                    onClick={() => {
                        console.log('yo: ', history);
                        history.push('/login');
                    }}
                >
                    Log in
                </Button>}
                {!email && <Button 
                    variant="contained" 
                    color="primary" 
                    disableElevation
                    onClick={() => {
                        console.log('yo: ', history);
                        history.push('/signup');
                    }}
                    style={{ borderRadius: 0, height: 65 }} 
                >
                    Sign up
                </Button>}
                {!!email && <Button 
                    variant="contained" 
                    color="primary" 
                    disableElevation
                    onClick={() => {
                        dispatch(authActions.logOutRequested({ history }));
                    }}
                    style={{ borderRadius: 0, height: 65 }} 
                >
                    Sign out
                </Button>}
                </div>
                <div className={classes.sectionMobile}>
                    <IconButton
                        aria-label="show more"
                        aria-haspopup="true"
                        color="inherit"
                    >
                    <MoreIcon />
                    </IconButton>
                </div>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
