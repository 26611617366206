import { Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { images } from '../../assets/images';
import { ResumeTemplate, ResumeDialogue } from '../../components';
import { CVTheme } from '../../types';

const resumeTemplates: ResumeTemplate[] = [
    {
      theme: "Netflix",
      imageUrl: images.netflixResume,
    },
    {
      theme: "Github",
      imageUrl: images.githubResume,
    },
    {
      theme: "Twitter",
      imageUrl: images.twitterResume,
    },
    {
      theme: "Google",
      imageUrl: images.googleResume,
    },
    {
      theme: "Wolt",
      imageUrl: images.woltResume,
    }
  ];

export const RotatingTemplates: React.FC<{ onSelectTheme(theme: CVTheme): void }> = ({ onSelectTheme }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(resumeTemplates[0]);
  const [isDialogueOpen, setIsDialogueOpen] = useState(false);
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0);
  
  useEffect(() => {
    const indexTimer = setInterval(() => {
        setCurrentTemplateIndex((current) => (current + 1)%resumeTemplates.length);
    }, 2500);

    return () => {
        clearInterval(indexTimer);
    };
  }, []);

  const onCloseClick = () => {
    setIsDialogueOpen(false);
  }

  const onSelectClick = (template: ResumeTemplate) => {
    setIsDialogueOpen(false);
    onSelectTheme(template.theme);
  }

  return (
    <div className="self-center relative flex flex-col items-center h-screen-7/10 w-full mr-20 md:-mt-10">
        <div className="absolute h-screen-13/20 w-screen-9/20  transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-105"> 
            <button onClick={() => {
                setIsDialogueOpen(true);
                setSelectedTemplate(resumeTemplates[currentTemplateIndex]);
            }}>
                {
                    resumeTemplates.map((template, index) => (
                        <div className={"absolute h-screen-13/20 w-screen-9/20"}>
                            <Transition
                                as={Fragment}
                                show={index === currentTemplateIndex}
                                enter="transform transition duration-1000"
                                enterFrom="opacity-0 -rotate-45 scale-50"
                                enterTo="opacity-100 rotate-0 scale-100"
                                leave="transform duration-700 transition ease-in-out"
                                leaveFrom="opacity-100 rotate-0 scale-100 "
                                leaveTo="opacity-0 rotate-45 scale-50 "
                            >
                            <img 
                                className="rounded-lg shadow-2xl h-full w-full object-cover border border-gray-200" 
                                src={template.imageUrl}
                            />
                            </Transition>
                        </div>
                    ))
                }
            </button>
        </div>
        <ResumeDialogue 
            template={selectedTemplate}
            isOpen={isDialogueOpen}
            onCloseClick={onCloseClick}
            onSelectClick={onSelectClick}
        />
    </div>
  )
}
