import { SparklesIcon, CheckIcon } from '@heroicons/react/outline';
import { useHistory } from 'react-router-dom';
import { Dialogue } from './Dialogue';

export const UpgradePlanDialogue: React.FC<{ open: boolean, onClose(): void, message: string }> = ({ open, onClose, message }) => {
    const history = useHistory();
    const onConfirmUpgrade = () => {
      onClose();
      history.push("/pricing");
    }
  
    return (
      <Dialogue
        open={open}
        title="Time to go PRO?"
        message={message}
        Icon={SparklesIcon}
        onClose={onClose}
        onConfirm={onConfirmUpgrade}
        confirmButtonTitle="Upgrade my plan"
      >
        <div>
          {['Unlimited resumes', 'Access to all templates', 'Resumes with no DreamCV branding'].map(upsell => (
            <div className="flex row mt-2">
              <CheckIcon  className="h-5 w-5 text-green-600 mr-2" aria-hidden="true" />
              <p className="text-sm text-gray-500">
                {upsell}
              </p>
            </div>
          ))}
        </div>
      </Dialogue>
    )
  }