import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        // We don't want to scroll to top on home page because there's a scroll to templates
        if (pathname !== '/')
            window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
}