import React, { FC } from "react";
import { OutlinedTextFieldProps } from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import Box from "@material-ui/core/Box";
import { Delete } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { cvInfoActions } from "../../store/cvInfo";
import { Link } from "../../types";
import { Card, TextInput, TextInputProps } from "../../components";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
    flexDirection: "column",
    justifyContent: "center",
  },
  textField: {
    display: "flex",
  },
}));

export const LinksForm: React.FC<{ id: string }> = ({ id }) => {
  const cvInfo = useSelector((state: RootState) => state.cvInfo.byId[id]);
  const classes = useStyles();
  const shouldRenderDeleteButton = cvInfo.links.length > 1;

  return (
    <>
      {cvInfo.links.map((linkItem, index) => (
        <LinkInputItem
          key={index}
          index={index}
          linkItem={linkItem}
          shouldRenderDeleteButton={shouldRenderDeleteButton}
        />
      ))}
    </>
  );
};

type FormField = {
  field: keyof Link;
  type?: string;
  textInputType: TextInputProps["textInputType"];
};

const fieldToLabel: { [key in keyof Link]: string } = {
  title: "Title",
  link: "Link",
};

const fieldToPlaceHolder: { [key in keyof Link]: string } = {
  title: "e.g. Website",
  link: "www.my-website.com",
};

const formFields: FormField[] = [
  { field: "title", type: "text", textInputType: "default" },
  { field: "link", type: "text", textInputType: "url" },
];

const LinkInputItem: FC<{
  index: number;
  linkItem: Link;
  shouldRenderDeleteButton: boolean;
}> = ({ index, linkItem, shouldRenderDeleteButton }) => {
  const dispatch = useDispatch();

  const onChangeField =
    (field: FormField["field"]): OutlinedTextFieldProps["onChange"] =>
    ({ target: { value } }) => {
      dispatch(cvInfoActions.linkChanged({ index, field, value }));
    };

  const onAddClick = (position: "before" | "after") => () => {
    dispatch(cvInfoActions.linkAdded({ index, position }));
  };

  const onDeleteClick = () => {
    dispatch(cvInfoActions.linkDeleted({ index }));
  };

  return (
    <>
      {index === 0 && <AddButton onClick={onAddClick("before")} />}
      <Card contentClassName="border border-gray-200">
        {shouldRenderDeleteButton && (
          <Box mb={3}>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={onDeleteClick}
              startIcon={<Delete />}
            >
              Delete
            </Button>
          </Box>
        )}
        {formFields.map((formField, index) => (
          <Box
            mb={index === formFields.length - 1 ? 0 : 2}
            key={formField.field}
          >
            <TextInput
              label={fieldToLabel[formField.field]}
              placeholder={fieldToPlaceHolder[formField.field]}
              type={formField.type}
              onChange={onChangeField(formField.field)}
              value={linkItem[formField.field]}
              textInputType={formField.textInputType}
            />
          </Box>
        ))}
      </Card>
      <AddButton onClick={onAddClick("after")} />
    </>
  );
};

const AddButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <Box
    mt={3}
    mb={3}
    style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
  >
    <Button variant="outlined" color="primary" onClick={onClick} size="small">
      Add another link here
    </Button>
  </Box>
);
