import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequestStatus } from '../types';
import { planIds } from '../services';

export type PlansState = {
    planStatus: { [key in string]: RequestStatus };
    error?: string;
}

const getInitialPlanStatuses = () => {
    const planStatus: { [key in string]: RequestStatus } = {};
    Object.values(planIds).forEach(id => {
        planStatus[id] = 'idle';
    });
    return planStatus;
};

export const isOneOfThePlansLoading = (planStatus: { [key in string]: RequestStatus }) => {
    for (const status in planStatus)
        if (planStatus[status] === 'loading')
            return true;
    return false;
};

const initialState: PlansState = {
    planStatus: getInitialPlanStatuses()
}

const plansSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        selectPlanRequested: (state, action: PayloadAction<{ priceId: string }>) => {
            if (isOneOfThePlansLoading(state.planStatus))
                return;
            const { priceId } = action.payload;
            state.planStatus[priceId] = 'loading';
        },
        selectPlanSucceeded: (state, action: PayloadAction<{ priceId: string }>) => {
            const { priceId } = action.payload;
            state.planStatus[priceId] = 'success';
        },
        selectPlanFailed: (state, action: PayloadAction<{ priceId: string, error: string }>) => {
            const { priceId, error } = action.payload;
            state.planStatus[priceId] = 'error';
            state.error = error;
        },
    }
});

export const plansReducer = plansSlice.reducer;
export const plansActions = plansSlice.actions;