import React from 'react';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { cvInfoActions, StringFields } from '../../store/cvInfo';
import Box from '@material-ui/core/Box';
import { TextInput } from "../../components";
const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        flexDirection: "column",
        justifyContent: 'center',
    },
    textField: {
        display: 'flex',
    }
  }));

export const ContactsForm: React.FC<{ id: string }> = ({ id }) => {
  const dispatch = useDispatch();
  const cvInfo = useSelector((state: RootState) => state.cvInfo.byId[id]);
  const classes = useStyles();
  
  const onChangeField =
    (field: StringFields): OutlinedTextFieldProps["onChange"] =>
    (event) => {
      dispatch(
        cvInfoActions.stringFieldChanged({ field, value: event.target.value })
      );
    };

  return (
    <>
      {formFields.map((formField) => (
        <Box mb={2} key={formField.field}>
          <TextInput
            id={fieldToLabel[formField.field]}
            label={fieldToLabel[formField.field]}
            placeholder={placeholderToLabel[formField.field]}
            multiline={formField.multiline}
            type={formField.type}
            onChange={onChangeField(formField.field)}
            value={cvInfo[formField.field]}
          />
        </Box>
      ))}
    </>
  );
};

type ContactField = Exclude<StringFields, 'tools' | 'skills' | 'lastUpdate'>;
type FormField = {
    field: ContactField,
    type?: string,
    multiline?: boolean,
};

const formFields: FormField[] = [
  { field: "firstName", type: "text" },
  { field: "lastName", type: "text" },
  { field: "email", type: "email" },
  { field: "phone", type: "tel" },
  { field: "address", multiline: true },
];

const fieldToLabel: { [key in ContactField]: string } = {
  firstName: "First name",
  lastName: "Last name",
  email: "email",
  phone: "Phone",
  address: "Address",
  title: "Title",
};

const placeholderToLabel: { [key in ContactField]: string } = {
  firstName: "Your first name",
  lastName: "Your last name",
  email: "e.g. sherlock.holmes@dreamcv.io",
  phone: "e.g. +49 111 22 33 4444",
  address: "e.g. 221B Baker Street",
  title: "My resume title",
};

// const [importDialogueOpen, setImportDialogueOpen] = useState(false);
//   const {acceptedFiles, getRootProps, getInputProps, isFocused, isDragActive} = useDropzone({ accept: 'application/pdf' });

//   useEffect(() => {
//     if (!acceptedFiles.length)
//       return;
    
//     const sendData = async () => {
//       let data = new FormData();
//       const arr = await acceptedFiles[0].arrayBuffer();
//       console.log(acceptedFiles[0].type);
//       data.append('file', acceptedFiles[0], acceptedFiles[0].name);
//       console.log(data, )
//       dispatch(cvInfoActions.importFromLinkedinRequested({ file: data }))
//     }
//     sendData();
//   }, [acceptedFiles, dispatch]);


{/* <div className="flex row justify-between w-full">
        <div />
        <button
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
          onClick={() => {
            setImportDialogueOpen(true);
          }}
        >
          <img src={images.linkedinLogo} className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          Import from Linkedin
        </button>
      </div> */}


{/* <Dialogue 
        hideButtons
        title="Import your resume from Linkedin"
        open={importDialogueOpen} 
        onClose={() => {setImportDialogueOpen(false)}}
        onConfirm={() => {

        }}
      >
        <section>
          <div {...getRootProps()} className={`flex text-gray-500 bg-gray-100 py-10 items-center justify-center rounded-md border-2 border-dashed ${(isFocused || isDragActive) ? 'border-indigo-500' : 'border-gray-200'}`}>
            <input {...getInputProps()} />
            <p className="text-md">Drag 'n' drop some files here, or click to select files</p>
          </div>
        </section>
      </Dialogue> */}