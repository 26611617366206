import React, { FC } from "react";
import {
  Document,
  Page,
  Text,
  View,
  Link,
  Font,
  Svg,
  Path,
  Image,
} from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";

import HelveticaNeueMedium from "../Twitter/assets/fonts/HelveticaNeue-Medium.ttf";
import HelveticaNeueRegular from "../assets/HelveticaNeue-Roman.ttf";
import HelveticaNeueBold from "../assets/HelveticaNeue-Bold.ttf";
import OmnesBold from "./assets/fonts/OmnesBold.ttf";
import deerImage from "./assets/images/deer.png";
import {
  CVInfo,
  EducationItem,
  ExperienceItem,
  Language,
  Project,
} from "../../../../types";

import {
  shouldRenderEducations,
  shouldRenderExperiences,
  shouldRenderLanguages,
  shouldRenderLinks,
  shouldRenderProjects,
  shouldRenderStringArray,
} from "../utils";
import { useState } from "react";
import { useRef } from "react";

Font.register({
  family: "HelveticaNeueMedium",
  format: "truetype",
  src: HelveticaNeueMedium,
});
Font.register({
  family: "HelveticaNeueRegular",
  format: "truetype",
  src: HelveticaNeueRegular,
});
Font.register({
  family: "HelveticaNeueBold",
  format: "truetype",
  src: HelveticaNeueBold,
});
Font.register({
  family: "OmnesBold",
  format: "truetype",
  src: OmnesBold,
});
Font.registerHyphenationCallback((word) =>
  word.length === 1 ? [word] : Array.from(word).map((char) => char)
);

const textBlack = "#333333";
const textBlue = "#009de0";
const textGray = "#202125";
const borderGray = "#E1E4E8";
const backgroundGray = "#F7F9FA";
const borderOrange = "#F9826C";

export const Wolt: FC<{ cvInfo: CVInfo; isPro: boolean }> = ({
  cvInfo,
  isPro,
}) => {
  return (
    <Document
      title={`${cvInfo.firstName}${cvInfo.lastName}Resume`}
      author={`${cvInfo.firstName} ${cvInfo.lastName}`}
    >
      <Page
        size="A4"
        style={{ paddingVertical: 20, backgroundColor: "#FBFBFB" }}
      >
        <View style={{ backgroundColor: "#FBFBFB", flex: 1 }}>
          <View
            style={{
              height: 20,
              backgroundColor: "#ffffff",
              position: "absolute",
              left: 0,
              right: 0,
              top: -20,
              width: "100%",
            }}
          />
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              backgroundColor: "#ffffff",
              borderBottomWidth: 2,
              borderBottomColor: borderGray,
              paddingBottom: 15,
            }}
          >
            <ContactsSection cvInfo={cvInfo} />
            <Image
              src={deerImage}
              style={{
                position: "absolute",
                width: 85,
                right: -5,
                top: -15,
                transform: "rotate(-40deg)",
              }}
            />
          </View>
          <View style={{ flexDirection: "row" }}>
            <View style={{ flex: 0.57 }}>
              <ExperienceSection experienceItems={cvInfo.experienceItems} />
            </View>
            <View
              style={{
                paddingRight: 20,
                paddingBottom: 20,
                flex: 0.37,
                paddingLeft: 20,
              }}
            >
              <EducationSection educationItems={cvInfo.educationItems} />
              <AwardsSection awards={cvInfo.awards} />
              <SkillsSection skills={cvInfo.skills} />
              <ProjectsSection projects={cvInfo.projects} />
              <LanguagesSection languages={cvInfo.languages} />
              <HobbiesSection hobbies={cvInfo.hobbies} />
            </View>
          </View>
        </View>
        {isPro && (
          <Text
            fixed
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              height: 20,
              textAlign: "center",
              fontSize: 11,
              color: textGray,
              fontFamily: "HelveticaNeueRegular",
              backgroundColor: "#FBFBFB",
            }}
            render={({ pageNumber, totalPages }) =>
              totalPages > 1 ? `Page ${pageNumber} of ${totalPages}` : ""
            }
          />
        )}
        {!isPro && (
          <View
            fixed
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              height: 35,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#FBFBFB",
            }}
          >
            <Text
              style={{
                fontSize: 11,
                color: textBlue,
                fontFamily: "HelveticaNeueMedium",
              }}
            >
              {"Created using "}
              <Link
                style={{
                  fontSize: 11,
                  color: textBlue,
                  fontFamily: "HelveticaNeueBold",
                }}
                src="https://www.dreamcv.io/"
              >
                dreamcv.io
              </Link>
            </Text>
          </View>
        )}
      </Page>
    </Document>
  );
};

const ContactsSection: FC<{ cvInfo: CVInfo }> = ({ cvInfo }) => (
  <View
    style={{
      paddingHorizontal: 20,
      backgroundColor: "#ffffff",
      paddingBottom: 15,
    }}
  >
    {(!!cvInfo.firstName || !!cvInfo.lastName) && (
      <View
        style={{ flexDirection: "row", alignItems: "center", marginBottom: 5 }}
      >
        <Text
          style={{ color: textBlack, fontSize: 22, fontFamily: "OmnesBold" }}
        >
          {`${cvInfo.firstName} ${cvInfo.lastName}`}
        </Text>
      </View>
    )}
    {!!cvInfo.email && <ContactField type="email">{cvInfo.email}</ContactField>}
    {!!cvInfo.phone && <ContactField type="phone">{cvInfo.phone}</ContactField>}
    {!!cvInfo.address && (
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Text
          style={{
            fontSize: 13,
            color: textGray,
            marginTop: 5,
            fontFamily: "HelveticaNeueRegular",
          }}
        >
          {cvInfo.address}
        </Text>
      </View>
    )}
    {shouldRenderLinks(cvInfo.links) && (
      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        {cvInfo.links.map((link) => (
          <LinkComponent src={link.link}>{link.title}</LinkComponent>
        ))}
      </View>
    )}
  </View>
);

const ContactField: React.FC<{
  type: "email" | "phone" | "address";
  children: string;
}> = ({ type, children }) => (
  <View style={{ flexDirection: "row", alignItems: "center" }}>
    <Text
      style={{
        fontSize: 13,
        color: textBlue,
        marginTop: 5,
        fontFamily: "HelveticaNeueMedium",
      }}
    >
      {children}
    </Text>
  </View>
);

const Title: React.FC<{ children: string }> = ({ children }) => (
  <View style={{ alignItems: "flex-start" }} wrap={false}>
    <View style={{ flexDirection: "row", alignItems: "center" }}>
      <Text style={{ fontSize: 16, color: textBlack, fontFamily: "OmnesBold" }}>
        {children}
      </Text>
    </View>
  </View>
);

const LinkComponent: React.FC<{ children: string; src: string }> = ({
  children,
  src,
}) => (
  <View
    wrap={false}
    style={{
      marginTop: 7,
      flexDirection: "row",
      marginRight: 10,
      alignItems: "center",
      height: 24,
      backgroundColor: textBlue,
      borderRadius: 14,
      paddingHorizontal: 12,
      justifyContent: "center",
    }}
  >
    <Link src={src} style={{ fontSize: 12, color: "white" }}>
      {children}
    </Link>
  </View>
);

const ExperienceSection: React.FC<{ experienceItems: ExperienceItem[] }> = ({
  experienceItems,
}) => {
  if (!shouldRenderExperiences(experienceItems)) return null;

  return (
    <View style={{ marginTop: 20, paddingLeft: 20 }}>
      <Title>Experience</Title>
      <View>
        {experienceItems.map((experienceItem) => (
          <ExperienceItemComponent experienceItem={experienceItem} />
        ))}
      </View>
    </View>
  );
};

const ExperienceItemComponent: React.FC<{ experienceItem: ExperienceItem }> = ({
  experienceItem,
}) => {
  const renderCount = useRef(0);
  const [currentPage, setCurrentPage] = useState(-1);

  renderCount.current++;

  return (
    <Card wrap={false} style={{ marginTop: 10 }}>
      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
        <Link
          src={experienceItem.website}
          style={{
            marginTop: 3,
            fontSize: 12,
            color: textBlue,
            fontFamily: "HelveticaNeueBold",
          }}
        >
          {experienceItem.companyName + ", "}
        </Link>
        <Text
          style={{
            marginTop: 3,
            fontSize: 12,
            color: textGray,
            fontFamily: "HelveticaNeueMedium",
          }}
        >
          {experienceItem.position}
        </Text>
      </View>
      <Text
        style={{
          fontSize: 11,
          color: textGray,
          marginTop: 5,
          fontFamily: "HelveticaNeueRegular",
        }}
      >
        {experienceItem.dates +
          (experienceItem.location ? ` | ${experienceItem.location}` : "")}
      </Text>
      {shouldRenderStringArray(experienceItem.achievements) &&
        experienceItem.achievements.map((achievement) => (
          <View
            style={{
              flexDirection: "row",
              marginTop: 5,
              flexShrink: 1,
              marginRight: 20,
            }}
          >
            <Tick />
            <Text
              style={{
                fontSize: 11,
                color: textGray,
                fontFamily: "HelveticaNeueRegular",
                marginTop: 4,
              }}
            >
              {achievement}
            </Text>
          </View>
        ))}
      <View
        render={({ pageNumber }) => {
          if (renderCount.current === 3) return <View />;

          setCurrentPage(pageNumber);
          return <View />;
        }}
      />
    </Card>
  );
};

const EducationSection: React.FC<{ educationItems: EducationItem[] }> = ({
  educationItems,
}) => {
  if (!shouldRenderEducations(educationItems)) return null;

  return (
    <View style={{ marginTop: 20 }}>
      <Title>Education</Title>
      <Card style={{ marginTop: 10 }}>
        {educationItems.map((educationItem, index) => (
          <EducationItemComponent educationItem={educationItem} index={index} />
        ))}
      </Card>
    </View>
  );
};

const EducationItemComponent: React.FC<{
  educationItem: EducationItem;
  index: number;
}> = ({ educationItem, index }) => {
  return (
    <View wrap={false} style={{ marginTop: index === 0 ? 0 : 15 }}>
      {!!educationItem.instituteName && (
        <Text
          style={{
            fontSize: 12,
            color: textBlack,
            fontFamily: "HelveticaNeueBold",
          }}
        >
          {educationItem.instituteName}
        </Text>
      )}
      {!!educationItem.location && (
        <Text
          style={{
            fontSize: 12,
            color: textBlack,
            marginTop: 5,
            fontFamily: "HelveticaNeueMedium",
          }}
        >
          {educationItem.location}
        </Text>
      )}
      {!!educationItem.degree && (
        <Text
          style={{
            fontSize: 11,
            color: textGray,
            marginTop: 5,
            fontFamily: "HelveticaNeueRegular",
          }}
        >
          {educationItem.degree}
        </Text>
      )}
      {!!educationItem.dates && (
        <Text
          style={{
            fontSize: 11,
            color: textGray,
            marginTop: 5,
            fontFamily: "HelveticaNeueRegular",
          }}
        >
          {educationItem.dates}
        </Text>
      )}
    </View>
  );
};

const AwardsSection: React.FC<{ awards: string[] }> = ({ awards }) => {
  if (!shouldRenderStringArray(awards)) return null;

  return (
    <View style={{ marginTop: 20 }}>
      <Title>Awards</Title>
      <View>
        {awards.map((award) => (
          <Text
            style={{
              fontSize: 12,
              color: textBlack,
              marginTop: 10,
              fontFamily: "HelveticaNeueMedium",
            }}
            wrap={false}
          >
            {award}
          </Text>
        ))}
      </View>
    </View>
  );
};

const ProjectsSection: React.FC<{ projects: Project[] }> = ({ projects }) => {
  if (!shouldRenderProjects(projects)) return null;

  return (
    <View style={{ marginTop: 20 }}>
      <Title>Projects</Title>
      <Card style={{ marginTop: 10 }}>
        {projects.map((project, index) => (
          <View style={{ marginTop: index > 0 ? 10 : 0 }} wrap={false}>
            <Link
              src={project.link}
              style={{
                fontSize: 12,
                color: textBlue,
                fontFamily: "HelveticaNeueBold",
              }}
            >
              {project.title}
            </Link>
            <Text
              style={{
                fontSize: 11,
                color: textGray,
                marginTop: 5,
                fontFamily: "HelveticaNeueRegular",
              }}
            >
              {project.description}
            </Text>
          </View>
        ))}
      </Card>
    </View>
  );
};

const LanguagesSection: React.FC<{ languages: Language[] }> = ({
  languages,
}) => {
  if (!shouldRenderLanguages(languages)) return null;

  return (
    <View wrap={false} style={{ marginTop: 20 }}>
      <Title>Languages</Title>
      <Card style={{ marginTop: 10 }}>
        {languages.map((language, index) => (
          <View
            style={{ marginTop: index > 0 ? 10 : 0, flexDirection: "row" }}
            wrap={false}
          >
            <Text
              style={{
                fontSize: 12,
                color: textGray,
                fontFamily: "HelveticaNeueBold",
              }}
            >
              {language.name}
            </Text>
            <Text
              style={{
                fontSize: 11,
                color: textGray,
                marginLeft: 5,
                marginTop: 1,
                fontFamily: "HelveticaNeueRegular",
              }}
            >
              {"-  " + language.fluency}
            </Text>
          </View>
        ))}
      </Card>
    </View>
  );
};

const SkillsSection: React.FC<{ skills: string[] }> = ({ skills }) => {
  if (!shouldRenderStringArray(skills)) return null;

  return (
    <View style={{ marginTop: 20 }} wrap={false}>
      <Title>Skills</Title>
      <Card style={{ flexWrap: "wrap", flexDirection: "row", marginTop: 10 }}>
        {skills.map((skill) => (
          <Chip>{skill}</Chip>
        ))}
      </Card>
    </View>
  );
};

const HobbiesSection: React.FC<{ hobbies: string[] }> = ({ hobbies }) => {
  if (!shouldRenderStringArray(hobbies)) return null;

  return (
    <View style={{ marginTop: 20 }} wrap={false}>
      <Title>Hobbies</Title>
      <View style={{ flexWrap: "wrap", flexDirection: "row" }}>
        {hobbies.map((hobby) => (
          <Chip>{hobby}</Chip>
        ))}
      </View>
    </View>
  );
};

const Chip: React.FC<{ children: string }> = ({ children }) => (
  <View
    style={{
      flexDirection: "row",
      backgroundColor: "rgba(0, 157, 224, 0.08)",
      paddingTop: 6,
      paddingBottom: 2,
      paddingHorizontal: 10,
      marginRight: 5,
      marginTop: 7,
      alignItems: "center",
      alignSelf: "flex-start",
      height: 25,
      borderRadius: 16,
    }}
  >
    <Text
      style={{
        fontSize: 11,
        color: textBlue,
        fontFamily: "HelveticaNeueRegular",
      }}
    >
      {children}
    </Text>
  </View>
);

const Card: React.FC<{ style?: Style; wrap?: boolean }> = ({
  children,
  style,
  wrap,
}) => {
  const defaultStyle: Style = {
    borderRadius: 9,
    padding: 12,
    borderBottomWidth: 2,
    borderRightWidth: 2,
    borderColor: borderGray,
    backgroundColor: "#FFFFFF",
  };
  const wrapperStyle = style ? [defaultStyle, style] : defaultStyle;

  return (
    <View wrap={wrap} style={wrapperStyle}>
      {children}
    </View>
  );
};

const Tick = () => (
  <Svg
    width="12"
    height="12"
    style={{
      backgroundColor: textBlue,
      borderRadius: 3,
      marginTop: 3,
      marginRight: 4,
    }}
    viewBox="0 0 24 24"
  >
    <Path
      d="M18.7413 6.24298C18.4744 6.04628 18.1403 5.9637 17.8124 6.01339C17.4846 6.06309 17.19 6.241 16.9933 6.50798L10.7653 14.961L6.78034 11.773C6.43275 11.4824 5.9551 11.4026 5.53192 11.5645C5.10874 11.7263 4.8063 12.1045 4.74142 12.553C4.67655 13.0014 4.8594 13.4498 5.21934 13.725L10.2193 17.725C10.4411 17.9016 10.7168 17.9966 11.0003 17.994C11.3957 17.9952 11.7686 17.8099 12.0063 17.494L19.0063 7.99398C19.2042 7.72694 19.2876 7.39203 19.2378 7.06339C19.1881 6.73475 19.0094 6.43949 18.7413 6.24298Z"
      stroke="#ffffff"
      strokeWidth={3}
    />
  </Svg>
);
