import { all } from 'redux-saga/effects';
import { authSagas } from './authSagas';
import { cvInfoSagas } from './cvInfoSagas';
import { plansSagas } from './plansSagas';

export function* rootSaga() {
  yield all([authSagas(), plansSagas(), cvInfoSagas()]);
}

// API.post('checkout', '/checkout-session', {
    //     body: {
    //       priceId: monthlyID
    //     }
    // }).then(({ sessionId }) => {
    //   stripePromise.then((stripe) => {
    //     stripe?.redirectToCheckout({
    //       sessionId
    //     });
    //   });
    //     console.log('HERE IT IS! ', sessionId);
    //   }).catch(error => {
    //     console.log('ERROR signing up ', error);
    //   });
    // API.get('resumesApi', '/resumes/newUser', '').then((user) => {
    //   console.log('HERE IT IS! ', user);
    // }).catch(error => {
    //   console.log('ERROR signing up ', error);
    // });