import { Plan } from "../types";

const stripeKeysPerEnv: { [key in string]: string } = {
    'dev': 'pk_test_51IUqTYD0KJUGjOmYoUGRAXA5xMaBeS7YIH5ont3ry9KdMXJ9n1LXb5GLVbpuYn9l0Vddm46GonInc8rRxc9RW5gH00fLIxaY8m',
    'prod': 'pk_live_51IUqTYD0KJUGjOmYIluFV4qHJA3zrHymU6eWN5YHSkuINXGlnBgIucytWz4mmXloDa8ZmXKLf2yZ1aAINAw48n7900jcBJ1nt4'
}

export const stripePublicKey = stripeKeysPerEnv[process.env.REACT_APP_BUILD_ENV || 'dev'];

export const stripePlans: Plan[] = [
    {
        id: 'price_1IUqbdD0KJUGjOmYThxOMCIE',
        price: '4.99',
        frequency: 'per month',
        sellingPoints: ['Access to all templates', 'Download PDFs without watermarks', 'Cancell any time']
    },
    {
        id: 'price_1IUqcUD0KJUGjOmYRUaLtr0U',
        price: '29.99',
        frequency: 'per year',
        sellingPoints: ['Access to all templates', 'Download PDFs without watermarks', 'Cancell any time']
    }
];

export const planIds: { [key in 'monthly' | 'annual']: string } = {
    'monthly': process.env.REACT_APP_BUILD_ENV === 'prod' ? 'price_1J6ymXD0KJUGjOmYNhOYVGA5' : 'price_1IUqbdD0KJUGjOmYThxOMCIE',
    'annual': process.env.REACT_APP_BUILD_ENV === 'prod' ? 'price_1J6yllD0KJUGjOmYnDawA5pk' : 'price_1IUqcUD0KJUGjOmYRUaLtr0U'
}