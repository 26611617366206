import React, { Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { usePDF } from "@react-pdf/renderer";
import { LoadingPDFPreview } from "../Generator/LoadingPDFPreview";
import { isProSelector } from "../../store/selectors";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { mapThemeToResumeTemplate } from "../Generator/ResumeTemplates";

export const DownloadResumeDialogue: React.FC<{
  id: string;
  isOpen: boolean;
  onClose(): void;
  onConfirm(): void;
}> = ({ id, isOpen, onClose, onConfirm }) => {
    const byId = useSelector((state: RootState) => state.cvInfo.byId);
    const isPro = useSelector(isProSelector);
    const cvInfo = byId[id];
    
    const Document = mapThemeToResumeTemplate[cvInfo.theme];
    const [instance, updateInstance] = usePDF({
      document: <Document cvInfo={cvInfo} isPro={isPro} />,
    });
    useEffect(updateInstance, [cvInfo]);

    const cancelButtonRef = useRef<HTMLElement | null>(null);

    return (
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={isOpen}
          onClose={onClose}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6">
                <div>
                  {instance.url ? (
                    <RenderPDF url={instance.url} />
                  ) : (
                    <LoadingPDFPreview
                      previewOnly
                      isSmall={true}
                      className="flex flex-col items-center bg-gray-100 rounded-lg h-screen-1/2 w-full overflow-hidden min-w-max"
                    />
                  )}
                </div>
                <div className="mt-10 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  {!!instance.url && (
                    <div className="z-40 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:col-start-2 sm:text-sm">
                      <a
                        href={instance.url!}
                        target="_blank"
                        type="button"
                        onClick={onConfirm}
                      >
                        Download in PDF format
                      </a>
                    </div>
                  )}
                  <button
                    type="button"
                    className="z-40 mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={onClose}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
};

const RenderPDF: React.FC<{ url: string }> = ({ url }) => (
  <>
    <Document
      className="hidden lg:flex justify-center items-center p-0 rounded-lg bg-gray-300 overflow-hidden"
      file={url}
      renderMode="svg"
    >
      <Page width={450} pageNumber={1} className="-mb-24 pt-2" renderTextLayer={false} />
    </Document>
    <Document
      className="hidden sm:flex lg:hidden justify-items-center content-center justify-center py-5 mb-10 items-center rounded-lg bg-gray-300 overflow-hidden"
      file={url}
      renderMode="svg"
    >
      <Page height={470} pageNumber={1} className="-mb-24 pt-2" renderTextLayer={false} />
    </Document>
    <Document
      className="flex sm:hidden justify-items-center content-center justify-center py-5 mb-10 items-center rounded-lg bg-gray-300 overflow-hidden"
      file={url}
      renderMode="svg"
    >
      <Page height={400} pageNumber={1} className="-mb-24 pt-2" renderTextLayer={false} />
    </Document>
  </>
);
