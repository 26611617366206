import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { rootSaga } from './sagas';
import { cvInfoReducer, CVInfoState } from './cvInfo';
import { AuthState, authReducer } from './auth';
import { plansReducer, PlansState } from './plans';

export type RootState = {
    auth: AuthState;
    cvInfo: CVInfoState;
    plans: PlansState
};

export const createStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const store = configureStore<RootState>({
        reducer: {
            auth: authReducer, 
            cvInfo: cvInfoReducer,
            plans: plansReducer
        },
        middleware: [sagaMiddleware]
    });
    sagaMiddleware.run(rootSaga);

    return store;
}