import React, { Fragment, useRef, useState } from "react";
import { Button, Card, CreateNewResumeDialogue, UpgradePlanDialogue } from "../../components";
import {
  CloudDownloadIcon,
  PencilIcon,
  TrashIcon,
  PlusCircleIcon,
  CheckIcon,
  SparklesIcon
} from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { LoadingCVItem } from "./LoadingCVItem";
import { useHistory } from "react-router";
import { cvInfoActions } from "../../store/cvInfo";
import { DownloadResumeDialogue } from "./DownloadResumeDialogue";
import { useRedirectLoggedOutToHome } from "../../hooks";
import { isProSelector } from "../../store/selectors";
import { isTemplateFree } from "../../utils";

export const Dashboard = () => {
  useRedirectLoggedOutToHome();
  const [proDialogueOpen, setProDialogueOpen] = useState(false);
  const [createNewDialogueOpen, setCreateNewDialogueOpen] = useState(false);
  const getSavedStatus = useSelector(
    (state: RootState) => state.cvInfo.getSavedStatus
  );
  const ids = useSelector(
    (state: RootState) => state.cvInfo.ids
  );
  const isPro = useSelector(isProSelector);
  const shouldUpgrade = !isPro && ids.length;

  const onCreateNewResumeClick = () => {
    if (shouldUpgrade) {
      setProDialogueOpen(true);
      return;
    }
    setCreateNewDialogueOpen(true);
  } 

  return (
    <div className="min-h-screen mx-auto bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-48 lg:px-8">
      <div>
        <header>
          <div className="grid align-between grid-cols-1 lg:grid-cols-2 max-w-7xl mx-auto">
            <h1 className="place-self-center lg:place-self-start text-3xl font-bold leading-tight text-gray-900">
              Dashboard
            </h1>
            {getSavedStatus === "success" && (
              <Button
                leftHandChildren={<PlusCircleIcon className="h-5 w-5 mr-2" />}
                className="mt-4 lg:mt-0 place-self-center lg:place-self-end"
                onClick={onCreateNewResumeClick}
              >
                Create new resume
              </Button>
            )}
          </div>
        </header>
        <main>
          <ResumesBody />
        </main>
      </div>
      <UpgradePlanDialogue 
        open={proDialogueOpen} 
        onClose={() => { setProDialogueOpen(false) }} 
        message="You're over the resume limit. Only one resume is available on the free plan. Upgrade your plan and get access to:" 
      />
      <CreateNewResumeDialogue open={createNewDialogueOpen} onClose={() => { setCreateNewDialogueOpen(false) }} />
    </div>
  );
};

const ResumesBody = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { ids, byId, getSavedStatus } = useSelector(
    (state: RootState) => state.cvInfo
  );
  const isPro = useSelector(isProSelector);
  const [selectedId, setSelectedId] = useState<string | undefined>();
  const [isDeleteDialogueOpen, setIsDeleteDialogueOpen] = useState(false);
  const [isDownloadDialogueOpen, setIsDownloadDialogueOpen] = useState(false);
  const [proDialogueOpen, setProDialogueOpen] = useState(false);

  if (getSavedStatus === "success") {
    if (!ids.length) return <EmptyStateComponent />;

    return (
      <ResumesWrapper>
        {ids.map((id) => (
          <Card contentClassName="w-72 max-w-xs">
            <div className="flex-1">
              <a className="block">
                <p className="text-xl font-semibold text-gray-900">
                  {byId[id].title || 'Untitled'}
                </p>
                <p className="mt-3 text-base text-gray-500">{
                  "Theme: " + byId[id].theme
                }</p>
                <p className="mt-3 text-base text-gray-500">
                  {"Last Update: " +
                    new Date(byId[id].lastUpdate).toLocaleString()}
                </p>
              </a>
            </div>
            <div className="mt-2 flex flex-col">
              <Button
                leftHandChildren={<PencilIcon className="h-5 w-5 mr-2" />}
                className="mt-2"
                variant="outlined"
                onClick={() => {
                  history.push("/resumes/" + id);
                }}
              >
                Edit
              </Button>
              <Button
                leftHandChildren={
                  <CloudDownloadIcon className="h-5 w-5 mr-2" />
                }
                className="mt-2"
                variant="outlined"
                onClick={() => {
                  setSelectedId(id);
                  setIsDownloadDialogueOpen(true);
                }}
              >
                Download
              </Button>
              <Button
                leftHandChildren={<TrashIcon className="h-5 w-5 mr-2" />}
                className="mt-2"
                variant="outlined"
                onClick={() => {
                  setSelectedId(id);
                  setIsDeleteDialogueOpen(true);
                }}
              >
                Delete
              </Button>
            </div>
          </Card>
        ))}
        {!!selectedId && isDownloadDialogueOpen && (
          <DownloadResumeDialogue
            id={selectedId}
            isOpen={isDownloadDialogueOpen}
            onClose={() => setIsDownloadDialogueOpen(false)}
            onConfirm={() => {
              setIsDownloadDialogueOpen(false);
            }}
          />
        )}
        <DeleteDialogue
          isOpen={isDeleteDialogueOpen}
          onClose={() => setIsDeleteDialogueOpen(false)}
          onConfirm={() => {
            if (selectedId)
              dispatch(cvInfoActions.deleteCV({ id: selectedId }));
            setIsDeleteDialogueOpen(false);
          }}
        />
        <UpgradePlanDialogue 
          open={proDialogueOpen} 
          onClose={() => { setProDialogueOpen(false) }} 
          message="The selected theme is a part of the PRO package. Upgrade your plan and get access to:" 
        />
      </ResumesWrapper>
    );
  }

  return (
    <ResumesWrapper>
      {[1, 2, 3, 4].map(() => (
        <LoadingCVItem />
      ))}
    </ResumesWrapper>
  );
};

const ResumesWrapper: React.FC<{}> = ({ children }) => (
  <div className="mt-12 max-w-7xl mx-auto grid justify-center justify-between content-between place-items-center lg:place-items-start gap-9 grid-cols-1 lg:grid-cols-3 xl:grid-cols-4">
    {children}
  </div>
);

const DeleteDialogue: React.FC<{ isOpen: boolean, onClose(): void, onConfirm(): void }> = ({ isOpen, onClose, onConfirm }) => {
  const cancelButtonRef = useRef<HTMLElement | null>(null);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={isOpen}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-indigo-100">
                  <TrashIcon
                    className="h-6 w-6 text-indigo-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Are you sure that you'd like to delete this resume?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      All of its contents will be deleted.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:col-start-2 sm:text-sm"
                  onClick={onConfirm}
                >
                  Delete this resume
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={onClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const EmptyStateComponent = () => (
  <div className="flex flex-col mt-12 justify-center	items-center content-center">
    <EmptyStateImage />
    <p className="mt-9 text-xl font-semibold text-gray-900">
      Create your
      <span className="text-indigo-600">{" stand out "}</span>
      resume now
    </p>
    <Button
      leftHandChildren={<PlusCircleIcon className="h-5 w-5 mr-2" />}
      className="mt-9"
    >
      New resume
    </Button>
  </div>
);

const EmptyStateImage = () => (
  <svg
    width="275"
    height="180"
    viewBox="0 0 275 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.0474854"
      y="34.6942"
      width="111.867"
      height="143.83"
      transform="rotate(-4.43023 0.0474854 34.6942)"
      fill="#A0AEC0"
    />
    <rect
      x="12.3979"
      y="43.9734"
      width="46.7231"
      height="5.8943"
      rx="2.94715"
      transform="rotate(-4.43023 12.3979 43.9734)"
      fill="#CBD5E0"
    />
    <rect
      x="13.0808"
      y="52.7885"
      width="35.6413"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 13.0808 52.7885)"
      fill="#CBD5E0"
    />
    <rect
      x="15.4257"
      y="83.0535"
      width="48.5201"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 15.4257 83.0535)"
      fill="#CBD5E0"
    />
    <rect
      x="75.4467"
      y="78.4032"
      width="30.8492"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 75.4467 78.4032)"
      fill="#CBD5E0"
    />
    <rect
      x="76.4028"
      y="90.7443"
      width="34.7428"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 76.4028 90.7443)"
      fill="#CBD5E0"
    />
    <rect
      x="75.9247"
      y="84.5738"
      width="34.7428"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 75.9247 84.5738)"
      fill="#CBD5E0"
    />
    <rect
      x="77.1769"
      y="100.735"
      width="30.8492"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 77.1769 100.735)"
      fill="#CBD5E0"
    />
    <rect
      x="78.1329"
      y="113.076"
      width="34.7428"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 78.1329 113.076)"
      fill="#CBD5E0"
    />
    <rect
      x="77.6549"
      y="106.905"
      width="34.7428"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 77.6549 106.905)"
      fill="#CBD5E0"
    />
    <rect
      x="16.3818"
      y="95.3945"
      width="56.9063"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 16.3818 95.3945)"
      fill="#CBD5E0"
    />
    <rect
      x="15.9038"
      y="89.224"
      width="56.9063"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 15.9038 89.224)"
      fill="#CBD5E0"
    />
    <rect
      x="18.8861"
      y="127.716"
      width="48.5201"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 18.8861 127.716)"
      fill="#CBD5E0"
    />
    <rect
      x="19.8422"
      y="140.057"
      width="56.9063"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 19.8422 140.057)"
      fill="#CBD5E0"
    />
    <rect
      x="19.3641"
      y="133.887"
      width="56.9063"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 19.3641 133.887)"
      fill="#CBD5E0"
    />
    <rect
      x="20.6162"
      y="150.048"
      width="48.5201"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 20.6162 150.048)"
      fill="#CBD5E0"
    />
    <rect
      x="21.5723"
      y="162.389"
      width="56.9063"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 21.5723 162.389)"
      fill="#CBD5E0"
    />
    <rect
      x="21.0942"
      y="156.218"
      width="56.9063"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 21.0942 156.218)"
      fill="#CBD5E0"
    />
    <rect
      x="17.1559"
      y="105.385"
      width="48.5201"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 17.1559 105.385)"
      fill="#CBD5E0"
    />
    <rect
      x="18.1119"
      y="117.726"
      width="56.9063"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 18.1119 117.726)"
      fill="#CBD5E0"
    />
    <rect
      x="17.6339"
      y="111.555"
      width="56.9063"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 17.6339 111.555)"
      fill="#CBD5E0"
    />
    <rect
      x="14.6062"
      y="72.4754"
      width="26.6561"
      height="5.01015"
      rx="2.50508"
      transform="rotate(-4.43023 14.6062 72.4754)"
      fill="#CBD5E0"
    />
    <rect
      x="74.6271"
      y="67.8252"
      width="21.265"
      height="5.01015"
      rx="2.50508"
      transform="rotate(-4.43023 74.6271 67.8252)"
      fill="#CBD5E0"
    />
    <rect
      x="14.037"
      y="65.1295"
      width="35.6413"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 14.037 65.1295)"
      fill="#CBD5E0"
    />
    <rect
      x="13.559"
      y="58.959"
      width="35.6413"
      height="3.53658"
      rx="1.76829"
      transform="rotate(-4.43023 13.559 58.959)"
      fill="#CBD5E0"
    />
    <rect
      x="163.512"
      y="25.5514"
      width="111.867"
      height="143.83"
      transform="rotate(5.82985 163.512 25.5514)"
      fill="#A0AEC0"
    />
    <rect
      x="174.013"
      y="36.8822"
      width="45.5279"
      height="5.8943"
      rx="2.94715"
      transform="rotate(5.82985 174.013 36.8822)"
      fill="#CBD5E0"
    />
    <rect
      x="173.115"
      y="45.6779"
      width="34.7296"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 173.115 45.6779)"
      fill="#CBD5E0"
    />
    <rect
      x="170.031"
      y="75.8766"
      width="47.279"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 170.031 75.8766)"
      fill="#CBD5E0"
    />
    <rect
      x="228.389"
      y="81.835"
      width="30.0601"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 228.389 81.835)"
      fill="#CBD5E0"
    />
    <rect
      x="227.131"
      y="94.149"
      width="33.8541"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 227.131 94.149)"
      fill="#CBD5E0"
    />
    <rect
      x="227.76"
      y="87.9921"
      width="33.8541"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 227.76 87.9921)"
      fill="#CBD5E0"
    />
    <rect
      x="226.114"
      y="104.117"
      width="30.0601"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 226.114 104.117)"
      fill="#CBD5E0"
    />
    <rect
      x="224.856"
      y="116.432"
      width="33.8541"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 224.856 116.432)"
      fill="#CBD5E0"
    />
    <rect
      x="225.485"
      y="110.275"
      width="33.8541"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 225.485 110.275)"
      fill="#CBD5E0"
    />
    <rect
      x="168.774"
      y="88.1906"
      width="55.4507"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 168.774 88.1906)"
      fill="#CBD5E0"
    />
    <rect
      x="169.402"
      y="82.0336"
      width="55.4507"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 169.402 82.0336)"
      fill="#CBD5E0"
    />
    <rect
      x="165.481"
      y="120.442"
      width="47.279"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 165.481 120.442)"
      fill="#CBD5E0"
    />
    <rect
      x="164.224"
      y="132.756"
      width="55.4507"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 164.224 132.756)"
      fill="#CBD5E0"
    />
    <rect
      x="164.852"
      y="126.599"
      width="55.4507"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 164.852 126.599)"
      fill="#CBD5E0"
    />
    <rect
      x="163.206"
      y="142.724"
      width="47.279"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 163.206 142.724)"
      fill="#CBD5E0"
    />
    <rect
      x="161.948"
      y="155.038"
      width="55.4507"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 161.948 155.038)"
      fill="#CBD5E0"
    />
    <rect
      x="162.577"
      y="148.881"
      width="55.4507"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 162.577 148.881)"
      fill="#CBD5E0"
    />
    <rect
      x="167.756"
      y="98.1591"
      width="47.279"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 167.756 98.1591)"
      fill="#CBD5E0"
    />
    <rect
      x="166.499"
      y="110.473"
      width="55.4507"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 166.499 110.473)"
      fill="#CBD5E0"
    />
    <rect
      x="167.127"
      y="104.316"
      width="55.4507"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 167.127 104.316)"
      fill="#CBD5E0"
    />
    <rect
      x="171.109"
      y="65.3217"
      width="25.9743"
      height="5.01016"
      rx="2.50508"
      transform="rotate(5.82985 171.109 65.3217)"
      fill="#CBD5E0"
    />
    <rect
      x="229.466"
      y="71.2802"
      width="20.7211"
      height="5.01016"
      rx="2.50508"
      transform="rotate(5.82985 229.466 71.2802)"
      fill="#CBD5E0"
    />
    <rect
      x="171.857"
      y="57.9919"
      width="34.7296"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 171.857 57.9919)"
      fill="#CBD5E0"
    />
    <rect
      x="172.486"
      y="51.8349"
      width="34.7296"
      height="3.53658"
      rx="1.76829"
      transform="rotate(5.82985 172.486 51.8349)"
      fill="#CBD5E0"
    />
    <rect x="74.1093" width="121.02" height="155.598" fill="#4C4CDC" />
    <rect
      x="85.635"
      y="9.83081"
      width="49.88"
      height="6.51846"
      rx="3.25923"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="19.6085"
      width="38.0495"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="53.1785"
      width="51.7985"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="149.903"
      y="53.1785"
      width="32.9336"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="149.903"
      y="66.8672"
      width="37.0903"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="149.903"
      y="60.0229"
      width="37.0903"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="149.903"
      y="77.9486"
      width="32.9336"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="149.903"
      y="91.6374"
      width="37.0903"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="149.903"
      y="84.793"
      width="37.0903"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="66.8672"
      width="60.7513"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="60.0229"
      width="60.7513"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="102.719"
      width="51.7985"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="116.408"
      width="60.7513"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="109.563"
      width="60.7513"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="127.489"
      width="51.7985"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="141.178"
      width="60.7513"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="134.333"
      width="60.7513"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="77.9486"
      width="51.7985"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="91.6374"
      width="60.7513"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="84.793"
      width="60.7513"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="41.4453"
      width="28.4572"
      height="5.54069"
      rx="2.77034"
      fill="#7F9CF5"
    />
    <rect
      x="149.903"
      y="41.4453"
      width="22.7018"
      height="5.54069"
      rx="2.77034"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="33.2972"
      width="38.0495"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
    <rect
      x="85.635"
      y="26.4529"
      width="38.0495"
      height="3.91107"
      rx="1.95554"
      fill="#7F9CF5"
    />
  </svg>
);