import React, { useState, useMemo } from "react";
import { Dialogue } from "./Dialogue";
import { RadioButtons } from "./RadioButtons";
import { TextInput } from "./TextInput";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CVInfo, CVTheme } from "../types";
import { RootState } from "../store";
import { cvInfoActions } from "../store/cvInfo";

export const CreateNewResumeDialogue: React.FC<{ open: boolean, onClose(): void, cvTheme?: CVTheme }> = ({ open, onClose, cvTheme }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { ids, byId } = useSelector((state: RootState) => state.cvInfo);
  const radioButtonOptions = useMemo(() => {
    const options = [{ name: "Blank", description: "Create an empty resume", id: "blank" }];
    ids.forEach((id) => {
      const title = byId[id].title;
      options.push({
        id,
        name: title,
        description: `Copy the resume information from "${title}"`,
      });
    });

    return options;
  }, [ids, byId]);
  const [selectedOption, setSelectedOption] = useState(radioButtonOptions[0]);
  const [title, setTitle] = useState("");

  const onConfirm = () => {
    onClose();
    let cvInformation: Partial<CVInfo> = { title, theme: cvTheme };
    if (selectedOption.id in byId) {
      const theme = cvTheme || byId[selectedOption.id].theme;
      cvInformation =  { ...byId[selectedOption.id], title, theme, id: undefined, lastUpdate: String(new Date()) };
    }
    dispatch(cvInfoActions.createNewCV({ history, prefill: cvInformation }));
  }

  return (
    <Dialogue
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      closeButtonTitle="Cancel"
      confirmButtonTitle="Create a new resume"
    >
      <div>
        <TextInput
          label="Resume Title"
          descr="The title won't show up in your resume. It should help you to manage different resumes on the dashboard."
          placeholder="e.g. Resume for Copywriter position @ DreamCV Inc"
          onChange={(
            event: React.ChangeEvent<
              HTMLTextAreaElement | HTMLInputElement
            >
          ) => {
            setTitle(event.target.value);
          }}
        />
        {radioButtonOptions.length > 1 && (
            <>
                <label className="mt-3 block text-sm font-medium text-gray-700 text-left">
                    Prefill information
                </label>
                <RadioButtons className="mt-1" options={radioButtonOptions} onSelect={setSelectedOption} />
            </>
        )}
      </div>
    </Dialogue>
  )
}