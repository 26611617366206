import { Netflix } from './Netflix';
import { Google } from './Google/Google';
import { Twitter } from './Twitter/Twitter';
import { Github } from './Github/Github';
import { CVInfo, CVTheme } from '../../../types';
import { FunctionComponent } from 'react';
import { Wolt } from './Wolt/Wolt';

export const mapThemeToResumeTemplate: { [key in CVTheme]: FunctionComponent<{ cvInfo: CVInfo; isPro: boolean; }> }  = {
    'Netflix': Netflix,
    'Google': Google,
    'Twitter': Twitter,
    'Github': Github,
    'Wolt': Wolt
};