import React, { FC } from 'react';
import ReactPDF, { Document, Page, Text, View, StyleSheet, Canvas, Link, Image, Font } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import mailIcon from './assets/mailIconGH.png';
import phoneIcon from './assets/phoneIconGH.png';
import locationIcon from './assets/locationIconGH.png';
import bookmarkIconGH from './assets/bookmarkIconGH.png';
import analyticsIconGH from './assets/analyticsIconGH.png';
import codeIconGH from './assets/codeIconGH.png';
import packagesIconGH from './assets/packagesIconGH.png';
import pullRequestIconGH from './assets/pullRequestIconGH.png';
import starIcon from './assets/starIcon.png';
import bookIconGH from './assets/bookIconGH.png';
import branchesLogoGH from './assets/branchesLogoGH.png';

import HelveticaNeueMedium from '../Twitter/assets/fonts/HelveticaNeue-Medium.ttf';
import HelveticaNeueRegular from '../assets/HelveticaNeue-Roman.ttf';
import HelveticaNeueBold from '../assets/HelveticaNeue-Bold.ttf';
import { CVInfo, EducationItem, ExperienceItem, Language, Project } from '../../../../types';

import { shouldRenderEducations, shouldRenderExperiences, shouldRenderLanguages, shouldRenderLinks, shouldRenderProjects, shouldRenderStringArray } from '../utils';
import { useState } from 'react';
import { useRef } from 'react';

// Font.register({
//     family: 'ArialBlack',
//     format: "truetype",
//     src: ArialBlack
// });
Font.register({
    family: 'HelveticaNeueMedium',
    format: "truetype",
    src: HelveticaNeueMedium
});
Font.register({
    family: 'HelveticaNeueRegular',
    format: "truetype",
    src: HelveticaNeueRegular
});
Font.register({
    family: 'HelveticaNeueBold',
    format: "truetype",
    src: HelveticaNeueBold
});
Font.registerHyphenationCallback(word => word.length === 1 ? [word] : Array.from(word).map(char => char));


const textBlack = '#24292E';
const textBlue = '#0366D6';
const textGray = '#576069';
const borderGray = '#E1E4E8';
const backgroundGray = '#F7F9FA';
const backgroundGreen = '#2EA44F';
const borderOrange = '#F9826C';

export const Github: FC<{ cvInfo: CVInfo, isPro: boolean }> = ({ cvInfo, isPro }) => {
    return (
        <Document
            title={`${cvInfo.firstName}${cvInfo.lastName}Resume`}
            author={`${cvInfo.firstName} ${cvInfo.lastName}`}
        >
            <Page size="A4">
                <View style={{padding: 15}}>
                    <ContactsSection cvInfo={cvInfo} />
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{flex:0.57}}>
                            <ExperienceSection experienceItems={cvInfo.experienceItems} />
                        </View>
                        <View style={{ flex: 0.37, paddingLeft: 15 }}>
                            <EducationSection educationItems={cvInfo.educationItems} />
                            <AwardsSection awards={cvInfo.awards} />
                            <SkillsSection skills={cvInfo.skills} />
                            <ProjectsSection projects={cvInfo.projects} />
                            <LanguagesSection languages={cvInfo.languages} />
                            <HobbiesSection hobbies={cvInfo.hobbies} />
                        </View>
                    </View> 
                </View>
                {!isPro && (
                    <Text fixed style={{ position: 'absolute', left: 0, right: 0, bottom: 0, height: 20, textAlign: 'center',  fontSize: 11, color: textGray, fontFamily: 'HelveticaNeueRegular' }} render={({ pageNumber, totalPages }) => (
                        totalPages > 1 ? `Page ${pageNumber} of ${totalPages}` : ''
                    )} />
                )}
                {isPro && (
                    <View
                        fixed
                        style={{
                            position: "absolute",
                            left: 0,
                            right: 0,
                            bottom: 0,
                            height: 35,
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "white"
                        }}
                        >
                        <Text
                            style={{
                            fontSize: 11,
                            color: textBlue,
                            fontFamily: "HelveticaNeueMedium",
                            }}
                        >
                            {"Created using "}
                            <Link style={{
                                fontSize: 11,
                                color: textBlue,
                                fontFamily: "HelveticaNeueBold",
                                }} src="https://www.dreamcv.io/">
                                dreamcv.io
                            </Link>
                        </Text>
                    </View>
                )}
            </Page>
        </Document>
    )
}

const ContactsSection: FC<{ cvInfo: CVInfo }> = ({ cvInfo }) => (
    <View>
        {(!!cvInfo.firstName || !!cvInfo.lastName) && (
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Text style={{ color: textBlack,  fontSize: 20, fontFamily: 'HelveticaNeueBold' }}>
                    {`${cvInfo.firstName} ${cvInfo.lastName}`}
                </Text>
            </View>
        )}
        {!!cvInfo.email && (
            <ContactField type='email'>
                {cvInfo.email}
            </ContactField>
        )}
        {!!cvInfo.phone && (
            <ContactField type='phone'>
                {cvInfo.phone}
            </ContactField>
        )}
        {!!cvInfo.address && (
            <ContactField type='address'>
                {cvInfo.address}
            </ContactField>
        )}
        {shouldRenderLinks(cvInfo.links) && (
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                {cvInfo.links.map((link) => (
                    <LinkComponent src={link.link}>
                        {link.title}
                    </LinkComponent>
                ))}
            </View>
        )} 
    </View>
);

type ContactType = 'email' | 'phone' | 'address';
const contactTypeToIcon: { [key in ContactType]: string } = {
    'email': mailIcon,
    'address': locationIcon,
    'phone': phoneIcon,
};

const ContactField: React.FC<{ type: 'email' | 'phone' | 'address', children: string }> = ({ type, children }) => (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Image src={contactTypeToIcon[type]} style={{ width: 16, height: 16, marginRight: 5 }} />
        <Text style={{  fontSize: 13, color: textBlack, marginTop: 6, fontFamily: 'HelveticaNeueMedium' }}>
            {children}
        </Text>
    </View>
)

type TitleType = 'experience' | 'education' | 'awards' | 'skills' | 'projects' | 'hobbies' | 'langauges';
const mapTitleTypeToImage: { [key in TitleType]: string } = {
    'experience': bookmarkIconGH,
    'education': bookIconGH,
    'awards': analyticsIconGH,
    'skills': codeIconGH,
    'projects': pullRequestIconGH,
    'hobbies': packagesIconGH,
    'langauges': branchesLogoGH
}
const Title: React.FC<{ type: TitleType, children: string }> = ({ type, children }) => (
    <View style={{ alignItems: 'flex-start' }} wrap={false}>
        <View style={{ flexDirection: 'row', paddingHorizontal: 5, paddingBottom: 4, borderBottomWidth: 2, borderBottomColor: borderOrange, alignItems: 'center' }}>
            <Image src={mapTitleTypeToImage[type]} style={{ width: 16, height: 16, marginRight: 5 }} />
            <Text style={{  fontSize: 14, color: textBlack, fontFamily: 'HelveticaNeueBold', marginTop: 6 }}>
                {children}
            </Text>
        </View>
    </View>
)

const LinkComponent: React.FC<{ children: string, src: string }> = ({ children, src }) => (
    <View wrap={false} style={{ marginTop: 10, flexDirection: 'row', marginRight: 10, alignItems: 'center', height: 27, backgroundColor: backgroundGreen, borderRadius: 6, borderWidth: 1, borderColor: 'rgb(74,141,80)', paddingHorizontal: 10 }}>
        <Link src={src} style={{  fontSize: 14, color: 'white' }}>
            {children}
        </Link>
    </View>
);

const ExperienceSection: React.FC<{ experienceItems: ExperienceItem[] }> = ({ experienceItems }) => {
    if (!shouldRenderExperiences(experienceItems))
        return null;

    return (
        <View style={{ marginTop: 15 }}>
            <Title type='experience'>
                Experience
            </Title>
            <View style={{ marginTop: 5 }}>
                {experienceItems.map(experienceItem => (
                    <ExperienceItemComponent experienceItem={experienceItem} />
                ))}
            </View>
        </View>
    )
}

const ExperienceItemComponent: React.FC<{ experienceItem: ExperienceItem }> = ({ experienceItem }) => {
    const renderCount = useRef(0);
    const [currentPage, setCurrentPage] = useState(-1);

    renderCount.current++;

    return (
        <View wrap={false} style={{ marginTop: 7, padding: 10, borderWidth: 1, borderColor: borderGray, borderRadius: 6 }}>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
                <Image src={bookmarkIconGH} style={{ width: 14, height: 14, marginRight: 5 }} />
                <Link src={experienceItem.website} style={{ marginTop: 3, fontSize: 12, color: textBlue, fontFamily: 'HelveticaNeueBold' }}>
                    {experienceItem.companyName + ', '}
                </Link>
                <Text style={{ marginTop: 3, fontSize: 12, color: textGray, fontFamily: 'HelveticaNeueMedium' }}>
                    {experienceItem.position}
                </Text>
            </View>
            <Text style={{  fontSize: 11, color: textGray, marginTop: 5, fontFamily: 'HelveticaNeueRegular' }}>
                {experienceItem.dates + (experienceItem.location ? ` | ${experienceItem.location}` : '')}
            </Text>
            {shouldRenderStringArray(experienceItem.achievements) && (
                experienceItem.achievements.map(achievement => (
                    <View style={{ flexDirection: 'row', marginTop: 2, flexShrink: 1, marginRight: 20 }}>
                        <Image src={starIcon} style={{ width: 12, height: 12, marginRight: 3, marginTop: 2 }} />
                        
                        <Text style={{  fontSize: 11, color: textGray, fontFamily: 'HelveticaNeueRegular', marginTop: 4 }}>
                            {achievement}
                        </Text>
                    </View>
                ))
            )}
            <View render={({ pageNumber }) => {
                if (renderCount.current === 3)
                    return <View />

                setCurrentPage(pageNumber)
                return <View /> 
            }} />
        </View>
    )
}

const EducationSection: React.FC<{ educationItems: EducationItem[] }> = ({ educationItems }) => {
    if (!shouldRenderEducations(educationItems))
        return null;
    
    return (
        <View style={{ marginTop: 15 }}>
            <Title type='education'>
                Education
            </Title>
            <View style={{ marginTop: 5 }}>
                {educationItems.map((educationItem, index) => (
                    <EducationItemComponent educationItem={educationItem} />
                ))}
            </View>
        </View>
    );
}

const EducationItemComponent: React.FC<{ educationItem: EducationItem }> = ({ educationItem }) => {
    return (
        <View style={{ marginTop: 10,  }} wrap={false}>
            {!!educationItem.instituteName && (
                <Text style={{  fontSize: 12, color: textBlack, fontFamily: 'HelveticaNeueBold' }}>
                    {educationItem.instituteName}
                </Text>
            )}
            {!!educationItem.location && (
                <Text style={{  fontSize: 12, color: textBlack, marginTop: 5, fontFamily: 'HelveticaNeueMedium' }}>
                    {educationItem.location}
                </Text>
            )}
            {!!educationItem.degree && (
                <Text style={{  fontSize: 11, color: textGray, marginTop: 5, fontFamily: 'HelveticaNeueRegular' }}>
                    {educationItem.degree}
                </Text>
            )}
            {!!educationItem.dates && (
                <Text style={{  fontSize: 11, color: textGray, marginTop: 5, fontFamily: 'HelveticaNeueRegular' }}>
                    {educationItem.dates}
                </Text>
            )}
        </View>
    );
}

const AwardsSection: React.FC<{ awards: string[] }> = ({ awards }) => {
    if (!shouldRenderStringArray(awards))
        return null;
    
    return (
        <View style={{ marginTop: 15 }}>
            <Title type='awards'>
                Awards
            </Title>
            <View style={{ marginTop: 5 }}>
                {awards.map(award => (
                    <Text style={{  fontSize: 12, color: textBlack, marginTop: 10, fontFamily: 'HelveticaNeueMedium' }} wrap={false}>
                        {award}
                    </Text>
                ))}
            </View>
        </View>
    )
}

const ProjectsSection: React.FC<{ projects: Project[] }> = ({ projects }) => {
    if (!shouldRenderProjects(projects))
        return null;
    
    return (
        <View style={{ marginTop: 15 }}>
            <Title type='projects'>
                Projects
            </Title>
            <View style={{ marginTop: 5 }}>
                {projects.map(project => (
                    <View style={{ marginTop: 10 }} wrap={false}>
                        <Link src={project.link} style={{  fontSize: 12, color: textBlue, fontFamily: 'HelveticaNeueBold' }}>
                            {project.title}
                        </Link>
                        <Text style={{  fontSize: 11, color: textGray, marginTop: 5, fontFamily: 'HelveticaNeueRegular' }}>
                            {project.description}
                        </Text>
                    </View>
                ))}
            </View>
        </View>
    )
}

const LanguagesSection: React.FC<{ languages: Language[] }> = ({ languages }) => {
    if (!shouldRenderLanguages(languages))
        return null;
    
    return (
        <View style={{ marginTop: 15 }}>
            <Title type='langauges'>
                Languages
            </Title>
            <View style={{ marginTop: 5 }}>
                {languages.map(language => (
                    <View style={{ marginTop: 10, flexDirection: 'row' }} wrap={false}>
                        <Text style={{  fontSize: 12, color: textGray, fontFamily: 'HelveticaNeueBold' }}>
                            {language.name}
                        </Text>
                        <Text style={{  fontSize: 11, color: textGray, marginLeft: 5, marginTop: 1, fontFamily: 'HelveticaNeueRegular' }}>
                            {"-  " + language.fluency}
                        </Text>
                    </View>
                ))}
            </View>
        </View>
    )
}

const SkillsSection: React.FC<{ skills: string[] }> = ({ skills }) => {
    if (!shouldRenderStringArray(skills))
        return null;
    
    return (
        <View style={{ marginTop: 15 }} wrap={false}>
            <Title type='skills'>
                Skills
            </Title>
            <View style={{ flexWrap: 'wrap', flexDirection: 'row', marginTop: 5 }}>
                {skills.map(skill => (
                    <Chip>
                        {skill}
                    </Chip>
                ))}
            </View>
        </View>
    )
}

const HobbiesSection: React.FC<{ hobbies: string[] }> = ({ hobbies }) => {
    if (!shouldRenderStringArray(hobbies))
        return null;
    
    return (
        <View style={{ marginTop: 20 }} wrap={false}>
            <Title type='hobbies'>
                Hobbies
            </Title>
            <View style={{ flexWrap: 'wrap', flexDirection: 'row', marginTop: 5 }}>
                {hobbies.map(hobby => (
                    <Chip>
                        {hobby}
                    </Chip>
                ))}
            </View>
        </View>
    )
}

const Chip: React.FC<{ children: string }> = ({ children }) => (
    <View style={{ flexDirection: 'row', backgroundColor: '#F1F8FF', paddingTop: 6, paddingBottom: 2, paddingHorizontal: 10, marginRight: 5, marginTop: 7, alignItems: 'center', alignSelf: 'flex-start', height: 25, borderRadius: 12.5 }}>
        <Text style={{  fontSize: 11, color: textBlue, fontFamily: 'HelveticaNeueMedium' }}>
            {children}
        </Text>
    </View>
)

const CardSectionWrapper: React.FC<{ noBorder?: boolean }> = ({ children, noBorder }) => {
    const borderStyle = noBorder ? {} : { borderBottomWidth: 1, borderBottomColor: borderGray };
    return (
        <View style={[{ paddingVertical: 12, paddingHorizontal: 20 }, borderStyle]}>
            {children}
        </View>
    );
}

const RoundedGrayCard: React.FC<{ title: string, style?: Style, noBorder?: boolean }> = ({ title, children, style, noBorder }) => {
    const defaultStyle = { backgroundColor: backgroundGray, borderRadius: 16, paddingVertical: 10, marginTop: 20 };
    const wrapperStyle = style ? [defaultStyle, style] : defaultStyle;

    return (
        <View wrap={false} style={wrapperStyle}>
            <View style={{ borderBottomWidth: 1, borderBottomColor: borderGray, paddingBottom: 10, paddingHorizontal: 20 }}>
                <Text style={{  fontSize: 14, color: textBlack }}>
                    {title}
                </Text>
            </View>
            <View>
                {React.Children.map(children, (child) => (
                    <CardSectionWrapper noBorder={noBorder}>
                        {child}
                    </CardSectionWrapper>
                ))}
            </View>
        </View>
    );
}