import { FC } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

import { AuthForm } from './AuthForm';
import { RootState } from '../../store';

export type SignUpProps = {
  onSubmit(args: { email: string; password: string }): void;
  className?: string;
};

export const SignUp: FC<SignUpProps> = ({ onSubmit, className }) => {
    const history = useHistory();
    const signUpStatus = useSelector((root: RootState) => root.auth.signUpStatus);
    let defaultClassname = "mx-3 sm:mx-10 min-w-max sm:w-96";
    if (className) defaultClassname += " " + className;

    return (
        <>
        <h2 className="mt-10 text-center text-3xl font-extrabold text-gray-900">
          Create your account
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Do you already have an account?{" "}
          <button
            className="font-medium text-indigo-600 hover:text-indigo-500"
            onClick={() => {
              history.push("/login");
            }}
          >
            Sign in
          </button>
        </p>
      <div className={defaultClassname}>
        <AuthForm
          type="signup"
          submitButtonTitle="Sign up"
          requestStatus={signUpStatus}
          onSubmit={onSubmit}
          displayForgotPassword={false}
        />
      </div>
      </>
    );
}