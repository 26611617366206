import { FC, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';

import { validateEmail } from '../../utils';
import { RequestStatus } from '../../types';
import { Button, TextInput } from '../../components';
import { useDispatch } from 'react-redux';
import { authActions } from '../../store/auth';


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        flexDirection: "column",
        justifyContent: 'center',
    },
    textField: {
        display: 'flex',
    }
  }));

type Props = {
    type?: 'signin' | 'signup';
    displayForgotPassword?: boolean;
    submitButtonTitle: string;
    requestStatus: RequestStatus;
    onSubmit(args: { email: string, password: string }): void;
};

export const AuthForm: FC<Props> = ({
  submitButtonTitle,
  requestStatus,
  onSubmit,
  type = 'signin',
  displayForgotPassword = true
}) => {
  const [state, setState] = useState({
    email: {
      value: "",
      error: "",
    },
    password: {
      value: "",
      error: "",
    },
  });

  const onChange = (
    field: "email" | "password"
  ): OutlinedTextFieldProps["onChange"] => ({ target: { value } }) => {
    setState({
      ...state,
      [field]: {
        value,
        error: "",
      },
    });
  };

  const validateInputs = () => {
    const { email, password } = state;
    const isEmailValid = validateEmail(email.value);
      const isPasswordEntered = !!password.value;
      console.log(isEmailValid, isPasswordEntered);
    if (!isEmailValid || !isPasswordEntered) {
      setState({
        ...state,
        email: {
          ...email,
          error: !isEmailValid ? "Please, enter a correct e-mail" : "",
        },
        password: {
          ...password,
          error: !isPasswordEntered ? "Please, enter your password" : "",
        },
      });

      return false;
    }

    return true;
  };

  const onSubmitClicked = () => {
    if (requestStatus === "loading") return;
    if (!validateInputs()) return;

    const { email, password } = state;
    onSubmit({ email: email.value, password: password.value });
  };

  const dispatch = useDispatch();

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md mb-20 md:mb-0">
      <div className="bg-white py-8 shadow rounded-lg px-7">
        {/* <button
          className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:outline-none"
          onClick={() => {
            dispatch(
              authActions.loginWithSocialRequested({ provider: "Google" })
            );
          }}
        >
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            viewBox="0 0 24 24"
            width="24"
            height="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
              <path
                fill="#4285F4"
                d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
              />
              <path
                fill="#34A853"
                d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
              />
              <path
                fill="#FBBC05"
                d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
              />
              <path
                fill="#EA4335"
                d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
              />
            </g>
          </svg>
          <span className="ml-5">
            {type === "signin" ? "Sign in with Google" : "Sign up with Google"}
          </span>
        </button>

        <div className="my-4 relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="px-2 bg-white text-gray-500">
              Or continue with
            </span>
          </div>
        </div> */}
        <div className="space-y-5">
          <TextInput
            label="Email"
            type="email"
            autoComplete="email"
            placeholder={"Your e-mail"}
            onChange={onChange("email")}
            error={state.email.error}
          />
          <TextInput
            label="Password"
            type="password"
            autoComplete="current-password"
            onChange={onChange("password")}
            error={state.password.error}
            placeholder={"Your password"}
          />

          {/* {displayForgotPassword && <div className="flex items-center justify-between">
            <div className="text-sm">
              <a
                href="#"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot your password?
              </a>
            </div>
          </div>} */}

          <div>
            <Button
              fullWidth
              onClick={onSubmitClicked}
              isLoading={requestStatus === "loading"}
              className="justify-center"
            >
              {submitButtonTitle}
            </Button>
          </div>
        </div>

        {type === 'signup' && (
          <div className="max-w-xxs sm:max-w-none text-sm font-normal text-gray-700 mt-5">
            By signing up to DreamCV you acknowledge that you agree to{" "}
            <a href="/privacy" className="text-indigo-600">the Terms of Service</a> and{" "}
            <a href="/privacy" className="text-indigo-600">Privacy Policy</a>.
          </div>
        )}
      </div>
    </div>
  );
};

{
  
  
  
  
  
  
  /* <div>
  <Box mt={10} mb={2}>
    <TextField
      className={classes.textField}
      variant="outlined"
      multiline={false}
      helperText={state.email.error}
      
      label={"email"}
      type={"email"}
      placeholder={"Please enter your e-mail"}
      onChange={onChange("email")}
      error={state.email.error}
    />
  </Box>
  <Box mb={2}>
    <TextField
      className={classes.textField}
      variant="outlined"
      helperText={state.password.error}
      multiline={false}
      
      label={"password"}
      type={"password"}
      onChange={onChange("password")}
      error={state.password.error}
      placeholder={"Please enter your password"}
    />
  </Box>
  <Button
    onClick={onSubmitClicked}
    variant="contained"
    color="primary"
    fullWidth
  >
    {requestStatus === "loading" ? (
      <CircularProgress color="inherit" size={35} />
    ) : (
      submitButtonTitle
    )}
  </Button>
</div>; */






}