import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CVInfo,
  CVTheme,
  EducationItem,
  ExperienceItem,
  Language,
  Link,
  Project,
  RequestStatus,
} from "../types";
// react-router history object
import { History } from "history";
import { authActions } from './auth';

type FieldName = keyof CVInfo;

const emptyExperienceItem: ExperienceItem = {
    companyName: '',
    position: '',
    dates: '',
    location: '',
    website: '',
    achievements: [''],
};

const emptyEducationItem: EducationItem = {
    instituteName: '',
    location: '',
    degree: '',
    dates: '',
}

const emptyLink: Link = {
  title: "",
  link: "",
};

const emptyProject: Project = {
  title: "",
  link: "",
  description: ""
}

const emptyLanguage: Language = {
  name: '',
  fluency: ''
}

const emptyAward = '';

const createEmptyCV = (id: string): CVInfo => ({
  id,
  title: "",
  theme: "Google",
  lastUpdate: String(new Date()),
  updateStatus: "idle",
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  address: "",
  tools: [],
  skills: [],
  hobbies: [],
  // We pre-init the arrays because we draw input fields based off of this data and there should be at least 1 input field.
  links: [emptyLink],
  awards: [emptyAward],
  educationItems: [emptyEducationItem],
  experienceItems: [emptyExperienceItem],
  projects: [emptyProject],
  languages: [emptyLanguage]
});

export type CVInfoState = {
  ids: string[];
  byId: { [key in string]: CVInfo };
  selectedId: string;
  selectedEditTabIndex: number;
  selectedTheme?: CVTheme;
  getSavedStatus: RequestStatus;
  importFromLinkedinStatus: RequestStatus;
};

const initialState: CVInfoState = {
  ids: ["1"],
  byId: { ["1"]: createEmptyCV("1") },
  selectedId: "1",
  selectedEditTabIndex: 0,
  getSavedStatus: "idle",
  importFromLinkedinStatus: "idle"
};

export type StringFields = Exclude<
  FieldName,
  | "experienceItems"
  | "educationItems"
  | "awards"
  | "skills"
  | "tools"
  | "hobbies"
  | "updateStatus"
  | "id"
  | "theme"
  | "links"
  | "projects"
  | "languages"
>;
export type ExperienceItemStringFields = Exclude<keyof ExperienceItem, 'achievements'>;

const cvInfoSlice = createSlice({
  name: "cvInfo",
  initialState,
  reducers: {
    stringFieldChanged: (
      state,
      action: PayloadAction<{ field: StringFields; value: string }>
    ) => {
      const { field, value } = action.payload;
      state.byId[state.selectedId][field] = value;
    },

    linkAdded: (
      state,
      action: PayloadAction<{ index: number; position: "before" | "after" }>
    ) => {
      const { index, position } = action.payload;
      const atIndex = position === "before" ? index : index + 1;
      state.byId[state.selectedId].links.splice(
        atIndex,
        0,
        emptyLink
      );
    },
    linkDeleted: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload;
      state.byId[state.selectedId].links.splice(index, 1);
    },
    linkChanged: (state, action: PayloadAction<{ index: number, field: keyof Link, value: string }>) => {
      const { index, field, value } = action.payload;
      state.byId[state.selectedId].links[index][field] = value;
    },

    educationChanged: (
      state,
      action: PayloadAction<{
        index: number;
        field: keyof EducationItem;
        value: string;
      }>
    ) => {
      const { index, field, value } = action.payload;
      state.byId[state.selectedId].educationItems[index][field] = value;
    },
    educationAdded: (
      state,
      action: PayloadAction<{ index: number; position: "before" | "after" }>
    ) => {
      const { index, position } = action.payload;
      const atIndex = position === "before" ? index : index + 1;
      state.byId[state.selectedId].educationItems.splice(
        atIndex,
        0,
        emptyEducationItem
      );
    },
    educationDeleted: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload;
      state.byId[state.selectedId].educationItems.splice(index, 1);
    },

    languageChanged: (
      state,
      action: PayloadAction<{
        index: number;
        field: keyof Language;
        value: string;
      }>
    ) => {
      const { index, field, value } = action.payload;
      state.byId[state.selectedId].languages[index][field] = value;
    },
    languageAdded: (
      state,
      action: PayloadAction<{ index: number; position: "before" | "after" }>
    ) => {
      const { index, position } = action.payload;
      const atIndex = position === "before" ? index : index + 1;
      state.byId[state.selectedId].languages.splice(
        atIndex,
        0,
        emptyLanguage
      );
    },
    languageDeleted: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload;
      state.byId[state.selectedId].languages.splice(index, 1);
    },

    projectChanged: (
      state,
      action: PayloadAction<{
        index: number;
        field: keyof Project;
        value: string;
      }>
    ) => {
      const { index, field, value } = action.payload;
      state.byId[state.selectedId].projects[index][field] = value;
    },
    projectAdded: (
      state,
      action: PayloadAction<{ index: number; position: "before" | "after" }>
    ) => {
      const { index, position } = action.payload;
      const atIndex = position === "before" ? index : index + 1;
      state.byId[state.selectedId].projects.splice(
        atIndex,
        0,
        emptyProject
      );
    },
    projectDeleted: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload;
      state.byId[state.selectedId].projects.splice(index, 1);
    },

    experienceChanged: (
      state,
      action: PayloadAction<{
        index: number;
        field: ExperienceItemStringFields;
        value: string;
      }>
    ) => {
      const { index, field, value } = action.payload;
      state.byId[state.selectedId].experienceItems[index][field] = value;
    },
    experienceAdded: (
      state,
      action: PayloadAction<{ index: number; position: "before" | "after" }>
    ) => {
      const { index, position } = action.payload;
      const atIndex = position === "before" ? index : index + 1;
      state.byId[state.selectedId].experienceItems.splice(
        atIndex,
        0,
        emptyExperienceItem
      );
    },
    experienceDeleted: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload;
      state.byId[state.selectedId].experienceItems.splice(index, 1);
    },
    experienceAchievementChanged: (
      state,
      action: PayloadAction<{
        experienceIndex: number;
        achievementIndex: number;
        value: string;
      }>
    ) => {
      const { experienceIndex, achievementIndex, value } = action.payload;
      state.byId[state.selectedId].experienceItems[
        experienceIndex
      ].achievements[achievementIndex] = value;
    },
    experienceAchievementAdded: (
      state,
      action: PayloadAction<{
        experienceIndex: number;
        achievementIndex: number;
      }>
    ) => {
      const { experienceIndex, achievementIndex } = action.payload;
      state.byId[state.selectedId].experienceItems[
        experienceIndex
      ].achievements.splice(achievementIndex + 1, 0, "");
    },
    experienceAchievementDeleted: (
      state,
      action: PayloadAction<{
        experienceIndex: number;
        achievementIndex: number;
      }>
    ) => {
      const { experienceIndex, achievementIndex } = action.payload;
      state.byId[state.selectedId].experienceItems[
        experienceIndex
      ].achievements.splice(achievementIndex, 1);
    },

    skillAdded: (state, action: PayloadAction<{ value: string }>) => {
      const { value } = action.payload;
      state.byId[state.selectedId].skills.push(value);
    },

    toolAdded: (state, action: PayloadAction<{ value: string }>) => {
      const { value } = action.payload;
      state.byId[state.selectedId].tools.push(value);
    },

    skillDeleted: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload;
      state.byId[state.selectedId].skills.splice(index, 1);
    },

    toolDeleted: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload;
      state.byId[state.selectedId].tools.splice(index, 1);
    },

    awardsChanged: (
      state,
      action: PayloadAction<{ index: number; value: string }>
    ) => {
      const { index, value } = action.payload;
      state.byId[state.selectedId].awards[index] = value;
    },

    awardsAdded: (
      state,
      action: PayloadAction<{ index: number; position: "before" | "after" }>
    ) => {
      const { index, position } = action.payload;
      const atIndex = position === "before" ? index : index + 1;
      state.byId[state.selectedId].awards.splice(atIndex, 0, "");
    },

    awardsDeleted: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload;
      state.byId[state.selectedId].awards.splice(index, 1);
    },

    hobbiesAdded: (state, action: PayloadAction<{ value: string }>) => {
      const { value } = action.payload;
      state.byId[state.selectedId].hobbies.push(value);
    },

    hobbiesDeleted: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload;
      state.byId[state.selectedId].hobbies.splice(index, 1);
    },

    updateRequested: (state) => {
      state.byId[state.selectedId].updateStatus = "loading";
    },

    updateFinished: (state) => {
      state.byId[state.selectedId].updateStatus = "success";
    },

    getSavedRequested: (state) => {
      state.getSavedStatus = "loading";
    },
    getSavedSucceeded: (
      state,
      action: PayloadAction<{ cvInfos: CVInfo[] }>
    ) => {
      const { cvInfos } = action.payload;
      
      for(let i = 0; i < cvInfos.length; i++) {
        if (!cvInfos[i].links)
          cvInfos[i].links = [emptyLink];
        if (!cvInfos[i].projects)
          cvInfos[i].projects = [emptyProject];
        if (!cvInfos[i].languages)
          cvInfos[i].languages = [emptyLanguage];
        if (!cvInfos[i].updateStatus)
          cvInfos[i].updateStatus = 'success';
        if (!cvInfos[i].theme)
          cvInfos[i].theme = 'Google';
      }

      const ids = cvInfos.map(({ id }) => id).sort();
      state.ids = ids;
      cvInfos.forEach((cvInfo) => {
        state.byId[cvInfo.id] = cvInfo;
      });
      state.getSavedStatus = "success";
    },
    setSelectedId: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      state.selectedId = id;
      state.selectedEditTabIndex = 0;
    },
    setSelectedEditTabIndex: (state, action: PayloadAction<{ index: number }>) => {
      const { index } = action.payload;
      state.selectedEditTabIndex = index;
    },
    deleteCV: (state, action: PayloadAction<{ id: string }>) => {
      // We optimistically remove the CV from out local storage
      const idIndex = state.ids.findIndex((id) => id === action.payload.id);
      state.ids.splice(idIndex, 1);
      delete state.byId[action.payload.id];
    },
    createNewCV: (state, action: PayloadAction<{ history?: History<unknown>, prefill: Partial<CVInfo> }>) => {
      const { prefill } = action.payload;
      const { ids } = state;
      
      let nextId = 1;
      let nextIdString = String(nextId);
      if (ids.length) {
        const lastIndex = ids.length - 1;
        // find the largest id and add one
        nextId =
          Number(ids.sort((a, b) => Number(a) - Number(b))[lastIndex]) + 1;
        nextIdString = String(nextId);
      }

      const newCV: CVInfo = {
        ...createEmptyCV(nextIdString),
        ...prefill,
        id: nextIdString,
        theme: prefill.theme || 'Google'
      };
      state.ids.push(nextIdString);
      state.byId[nextId] = newCV;
      state.selectedId = nextIdString;
    },
    themeSelected: (state, action: PayloadAction<{ theme: CVTheme }>) => {
      const { theme } = action.payload;
      state.selectedTheme = theme;
      state.byId[state.selectedId].theme = theme;
    },

    importFromLinkedinRequested: (state, action: PayloadAction<{ file: FormData }>) => {
      state.importFromLinkedinStatus = 'loading';
    },
    importFromLinkedinSucceeded: (state, action: PayloadAction<{ result: any }>) => {
      const { result } = action.payload;
      state.importFromLinkedinStatus = 'success';
      console.log("LINKEDING: ", result);
    },
    importFromLinkedinFailed: (state) => {
      state.importFromLinkedinStatus = 'error';
    },

  },
  // listens for actions outside of this slice
  extraReducers: (builder) => {
    builder.addCase(authActions.logOutSucceeded, (state) => {
      state.ids = ["1"];
      state.byId = {
        ["1"]: createEmptyCV("1"),
      };
      state.selectedId = "1";
      state.selectedTheme = undefined;
    });
  },
});

export const cvInfoActions = cvInfoSlice.actions;
export const cvInfoReducer = cvInfoSlice.reducer;