import React from "react";

const smallArray = [1, 2];
const bigArray = [1, 2, 3, 4];

export const LoadingPDFPreview: React.FC<{
  className?: string;
  isSmall?: boolean;
  previewOnly?: boolean;
}> = ({ className, isSmall, previewOnly }) => {
  const placeHolderArray = isSmall ? smallArray : bigArray;
  const pdfPlaceholderWidth = isSmall
    ? "w-full"
    : "w-4/5 md:w-4/5";

  return (
    <div
      className={
        className ||
        "flex flex-col items-center py-4 px-4 bg-gray-100 rounded-tl-lg rounded-bl-lg rounded-tr-lg rounded-br-lg h-screen-1/2 w-full md:rounded-tl-none md:rounded-bl-none sm:h-screen-2/3 overflow-hidden"
      }
    >
      <div className={pdfPlaceholderWidth + " h-full bg-gray-200 px-4 py-4"}>
        <div className="animate-pulse h-10 w-2/3 pr-5 bg-gray-300" />
        <div className="animate-pulse h-5 mt-2 w-1/3 bg-gray-300" />
        <div className="animate-pulse flex h-5 mt-4 w-3/4">
          <div className="h-6 w-1/3 bg-gray-300" />
          <div className="h-6 ml-2 w-1/3 bg-gray-300" />
          <div className="h-6 ml-2 w-1/3 bg-gray-300" />
        </div>
        <div className="animate-pulse h-8 mt-4 w-1/4 bg-gray-300" />
        {placeHolderArray.map((key) => (
          <div className="animate-pulse mt-5" key={key}>
            <div className="h-6 w-1/2 bg-gray-300" />
            <div className="h-6 mt-2 w-3/4 bg-gray-300" />
            <div className="h-6 mt-2 w-2/3 bg-gray-300" />
          </div>
        ))}
      </div>
    </div>
  );
};
