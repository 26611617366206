import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { RootState } from "../store";

export const useRedirectLoggedOutToHome = () => {
  const { email, loginStatus } = useSelector((state: RootState) => state.auth);
  const history = useHistory();

  useEffect(() => {
    if ((loginStatus === "success" && !email) || loginStatus === 'error') history.push("/");
  }, [loginStatus, email]);
};
