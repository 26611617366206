import { all, call, debounce, put, select, takeLatest } from 'redux-saga/effects';
import { API } from 'aws-amplify';
import { cvInfoActions } from '../cvInfo';
import { RootState } from '..';
import { CVInfo } from '../../types';
import { LOCAL_CV_KEY, SELECTED_PLAN_KEY } from '../../utils/constants';
import axios from 'axios';
import { plansActions } from '../plans';

export function* cvInfoChanged() {
    try {
        const selectedId: number = yield select((rootState: RootState) => rootState.cvInfo.selectedId);
        const cvInfo: Partial<CVInfo> = yield select((rootState: RootState) => rootState.cvInfo.byId[selectedId]);
        let cvInfoMutable = { ...cvInfo, lastUpdate: String(new Date()) };
        delete cvInfoMutable.updateStatus;

        const email: string = yield select((rootState: RootState) => rootState.auth.email);
        if (email) {
          yield put(cvInfoActions.updateRequested());
          yield call([API, 'post'], 'dreamCVApi', `/resumes/`, {
              body: cvInfoMutable
          });
          yield put(cvInfoActions.updateFinished());
        } else {
          // Save locally to retrieve it upon successful signup (for social sigups)
          localStorage.setItem(LOCAL_CV_KEY, JSON.stringify(cvInfoMutable));
          yield put(cvInfoActions.updateFinished());
        }
    } catch (e) {
        console.error('Error in cvInfoChanged saga: ', e);
        yield put(cvInfoActions.updateFinished());
    }
}

// pretty much does the same thing as cvInfoChanged but doesn't update the store, just silently post the new theme
function* themeChanged() {
  try {
    const selectedId: number = yield select((rootState: RootState) => rootState.cvInfo.selectedId);
    const cvInfo: Partial<CVInfo> = yield select((rootState: RootState) => rootState.cvInfo.byId[selectedId]);
    let cvInfoMutable = { ...cvInfo, lastUpdate: String(new Date()) };
    delete cvInfoMutable.updateStatus;

    const email: string = yield select((rootState: RootState) => rootState.auth.email);
    if (email) {
      yield call([API, 'post'], 'dreamCVApi', `/resumes/`, {
          body: cvInfoMutable
      });
    }
  } catch (e) {
      console.error('Error in themeChanged saga: ', e);
  }
}

export function* getSaved() {
    try {
        const email: string = yield select((rootState: RootState) => rootState.auth.email);
        if (email) {
            const cvInfoFromBackend: { data: CVInfo[] } = yield call([API, 'get'], 'dreamCVApi', `/resumes/`, {});
            
            yield put(cvInfoActions.getSavedSucceeded({ cvInfos: cvInfoFromBackend.data }));
            
            if (!cvInfoFromBackend.data.length) {
              const localCV = localStorage.getItem(LOCAL_CV_KEY);
              if (localCV)
                yield put(cvInfoActions.createNewCV({ prefill: JSON.parse(localCV) as CVInfo }));
              
              const selectedPlanId = localStorage.getItem(SELECTED_PLAN_KEY);
              if (selectedPlanId && selectedPlanId !== 'free')
                yield put(plansActions.selectPlanRequested({ priceId: selectedPlanId }));
            }
            localStorage.removeItem(LOCAL_CV_KEY);
            localStorage.removeItem(SELECTED_PLAN_KEY);
        }
    } catch (e) {
        console.error('Error in cvInfo getSaved saga: ', e);
    }
}

function* newCVCreated(action: ReturnType<typeof cvInfoActions.createNewCV>) {
  const { history } = action.payload;
  const selectedId: string = yield select(
    (rootState: RootState) => rootState.cvInfo.selectedId
  );
  if (history) {
    history.push("/resumes/" + selectedId);
  }
}

function* deleteCV(action: ReturnType<typeof cvInfoActions.deleteCV>) {
  const { id } = action.payload;
  try {
    const email: string = yield select(
      (rootState: RootState) => rootState.auth.email
    );
    if (email) {
      yield put(cvInfoActions.updateRequested());
      yield call([API, "del"], "dreamCVApi", `/resumes/`, {
        body: { id },
      });
    }
  } catch (e) {
    console.error("Resume deletion failed: ", e);
  }
}

function* importFromLinkedin(action: ReturnType<typeof cvInfoActions.importFromLinkedinRequested>) {
  const { file } = action.payload;
  console.log(file);
  try {
    const url = 'https://www.visualcv.com/api/v2/cvs/6689852/parse';
    const result: number = yield call([axios, "post"], url, file, {
      headers: {
        'Content-Type': undefined,
      }
    });
    yield put(cvInfoActions.importFromLinkedinSucceeded({ result }));
  } catch (e) {
    yield put(cvInfoActions.importFromLinkedinFailed());
    console.error("Import From Linkedin failed");
  }
}

const ignoredCVInfoActions = [
  cvInfoActions.updateRequested.type,
  cvInfoActions.updateFinished.type,
  cvInfoActions.getSavedRequested.type,
  cvInfoActions.getSavedSucceeded.type,
  cvInfoActions.setSelectedId.type,
  cvInfoActions.deleteCV.type,
  cvInfoActions.themeSelected.type,
  cvInfoActions.importFromLinkedinRequested.type,
  cvInfoActions.setSelectedEditTabIndex.type
];

export function* cvInfoSagas() {
  yield all([
    debounce(
      1000,
      Object.values(cvInfoActions)
        .map((action) => action.type)
        .filter((actionType) => !ignoredCVInfoActions.includes(actionType)),
      cvInfoChanged
    ),
    debounce(
      3000,
      cvInfoActions.themeSelected.type,
      themeChanged
    ),
    takeLatest(cvInfoActions.getSavedRequested.type, getSaved),
    takeLatest(cvInfoActions.createNewCV.type, newCVCreated),
    takeLatest(cvInfoActions.deleteCV.type, deleteCV),
    takeLatest(cvInfoActions.importFromLinkedinRequested.type, importFromLinkedin),
  ]);
}
