import React, { FC } from 'react';

type CardProps = {
  title?: string;
  titleColor?: string;
  contentClassName?: string;
  headerChildren?: React.ReactNode;
};

export const Card: FC<CardProps> = ({ title, titleColor, contentClassName, children, headerChildren }) => {
  let defaultClassname = (title || headerChildren)
    ? "bg-white overflow-hidden shadow-xl rounded-lg divide-y divide-gray-200"
    : "bg-white overflow-hidden shadow-xl rounded-lg";
  
  if (contentClassName)
    defaultClassname += " " + contentClassName;
  
  if (title || headerChildren)
    return (
      <div className={defaultClassname}>
        <div className="px-5 py-4 sm:px-7">
          {headerChildren}
          {!!title && (
            <div
              className={
                "text-lg leading-6 font-medium text-gray-900 " + titleColor
              }
            >
              {title}
            </div>
          )}
        </div>
        <div className="p-5 sm:p-8">{children}</div>
      </div>
    );

  return <div className={defaultClassname + " p-5 sm:p-8"}>{children}</div>;
};