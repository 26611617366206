import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { RootState } from "../../store";
import { authActions, AuthState } from "../../store/auth";
import { Generator } from "../Generator/Generator";

import { Steps, Step, LogoButton, ResumeTemplates } from "../../components";
import { SignUp, SignUpProps } from "./SignUp";
import { SelectPlan, SelectPlanProps } from "../Pricing/SelectPlan";
import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
} from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import { cvInfoActions } from "../../store/cvInfo";

const HorizontalTransition: React.FC<{ show: boolean }> = ({ children, show }) => {
  return (
    <Transition
        show={show}
      enter="transform transition duration-[400ms]"
      enterFrom="opacity-0 translate-x-40"
      enterTo="opacity-100 translate-x-0"
      leave="transform duration-100 transition ease-in-out"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 -translate-x-40"
    >
      {children}
    </Transition>
  );
};

type RenderStepsProps = { activeStep: number } & SelectPlanProps & SignUpProps;
const renderSteps = ({
  activeStep,
  onSelectPlan,
  onSubmit,
  selectedPlanId,
}: RenderStepsProps) => {
    return (
      <>
        <HorizontalTransition show={activeStep === 0}>
          <div className="sm:w-screen-9/10 lg:w-screen-7/10">
            <div className="w-full">
              <p className="mt-10 mb-5 text-center text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                Create your stand out resume
              </p>
              <Generator id={"1"} previewOnly />
            </div>
          </div>
        </HorizontalTransition>
        <HorizontalTransition show={activeStep === 1}>
          <SelectPlan
            onSelectPlan={onSelectPlan}
            selectedPlanId={selectedPlanId}
          />
        </HorizontalTransition>
        <HorizontalTransition show={activeStep === 2}>
          <SignUp onSubmit={onSubmit} className="mt-10" />
        </HorizontalTransition>
      </>
    );
};

export const SignupFlow = () => {
  const dispatch = useDispatch();
  const selectedTheme = useSelector(
    (state: RootState) => state.cvInfo.selectedTheme
  );
  const activeStep = useSelector((state: RootState) => state.auth.signupStep);
  const selectedPlanId = useSelector((state: RootState) => state.auth.signupSelectedPlanId);
  const [steps, setSteps] = useState<Step[]>([
    {
      name: "Create your resume",
      status: "current",
    },
    {
      name: "Select a plan",
      status: "upcoming",
    },
    {
      name: "Sign up",
      status: "upcoming",
    },
  ]);
  useEffect(() => {
    let newSteps = [...steps];
    for (let i = 0; i < newSteps.length; i++) {
      if (i < activeStep) newSteps[i].status = "complete";
      else if (i === activeStep) newSteps[i].status = "current";
      else newSteps[i].status = "upcoming";
    }
    setSteps(newSteps);
  }, [activeStep]);
  const loginStatus = useSelector((root: RootState) => root.auth.loginStatus);
  const history = useHistory();
  useEffect(() => {
    if (loginStatus === "success") {
      history.push("/resumes");
      return;
    }
  }, [loginStatus, history]);

  const onClickStep = (stepIndex: number) => {
    if (stepIndex === 2) {
      if (selectedPlanId) dispatch(authActions.signupStepChanged({ step: 2 }));
      return;
    }
    dispatch(authActions.signupStepChanged({ step: stepIndex as AuthState['signupStep'] }));
  };

  const onSubmit = ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    if (!selectedPlanId) {
      console.error("SignUpFlow: no plan selected");
      return;
    }

    dispatch(
      authActions.signUpRequested({ email, password, priceId: selectedPlanId })
    );
  };

  const handleBack = () => {
    dispatch(authActions.signupStepChanged({ step: (activeStep - 1) as AuthState['signupStep'] }));
  };

  const handleNext = () => {
    dispatch(authActions.signupStepChanged({ step: (activeStep + 1) as AuthState['signupStep'] }));
  };

  const onSelectPlan = (planId: string) => {
    dispatch(authActions.signupSelectedPlanIdChanged({ id: planId }));
    handleNext();
  };

  return (
    <div className="flex flex-col bg-gray-50 min-h-screen items-center">
      <div
        className={
          "flex flex-col flex-1 min-h-screen bg-gray-50 w-96 sm:w-full max-w-7xl min-w-min px-5"
        }
      >
        <LogoButton
          className="flex self-center mb-7 mt-10"
          onClick={() => {
            history.push("/");
          }}
        />
        <div className="self-center sm:w-screen-9/10 lg:w-screen-7/10">
          <Steps steps={steps} onClickStep={onClickStep} />
        </div>
        <div className="flex justify-items-center justify-center">
          {activeStep > 0 ? (
            <button
              onClick={handleBack}
              className="mt-48 mr-0 sm:mr-4 focus:outline-none h-8 w-8"
            >
              <ArrowCircleLeftIcon className="text-indigo-600 h-8 w-8" />
            </button>
          ) : (
            <div className="mr-0 sm:mr-4 h-8 w-8" />
          )}
          {(!selectedTheme && activeStep === 0) ? (
            <div className="mt-5 mb-20">
              <p className="mt-4 text-center text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                Let's start with selecting you a template
              </p>
              <ResumeTemplates
                onSelectTheme={(theme) => {
                  dispatch(cvInfoActions.themeSelected({ theme }));
                }}
              />
            </div>
          ) : (
            renderSteps({
              activeStep,
              onSelectPlan,
              onSubmit,
              selectedPlanId,
            })
          )}
          {(activeStep === 0 && selectedTheme) ||
          (activeStep === 1 && selectedPlanId) ? (
            <button
              onClick={handleNext}
              className="mt-48 ml-0 sm:ml-4 focus:outline-none h-8 w-8"
            >
              <ArrowCircleRightIcon className="text-indigo-600 h-8 w-8" />
            </button>
          ) : (
            <div className="ml-0 sm:ml-4 h-8 w-8" />
          )}
        </div>
      </div>
    </div>
  );
};
