import React, { FC } from 'react';
import { OutlinedTextFieldProps } from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import Box from '@material-ui/core/Box';
import { Delete } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import { cvInfoActions } from '../../store/cvInfo';
import { Project } from '../../types';
import { Card, TextInput } from "../../components";


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        flexDirection: "column",
        justifyContent: 'center',
    },
    textField: {
        display: 'flex'
    }
  }));

export const ProjectsForm: React.FC<{ id: string }> = ({ id }) => {
  const cvInfo = useSelector((state: RootState) => state.cvInfo.byId[id]);
  const classes = useStyles();
  const shouldRenderDeleteButton = cvInfo.projects.length > 1;

  return (
    <>
      {cvInfo.projects.map((project, index) => (
        <ProjectInputItem
          key={index}
          index={index}
          project={project}
          shouldRenderDeleteButton={shouldRenderDeleteButton}
        />
      ))}
    </>
  );
};

type ProjectFields = keyof Project;
type FormField = {
    field: ProjectFields,
    type?: 'text' | 'url',
    multiline?: boolean,
};

const fieldToLabel: { [key in ProjectFields]: string } = {
    'title': 'Title',
    'link': 'Link',
    'description': 'Description'
}

const fieldToPlaceHolder: { [key in ProjectFields]: string } = {
    'title': 'e.g. DreamCV.io',
    'link': 'www.dreamcv.io',
    'description': 'e.g. Resume builder web app built using React, Typescript, TailwindCSS.'
}

const formFields: FormField[] = [
  { field: 'title', type: 'text' },
  { field: 'link', type: 'url' },
  { field: 'description', type: 'text', multiline: true },
];

const ProjectInputItem: FC<{ index: number, project: Project, shouldRenderDeleteButton: boolean }> = ({ index, project, shouldRenderDeleteButton }) => {
    const dispatch = useDispatch();
    
    const onChangeField = (field: FormField['field']): OutlinedTextFieldProps['onChange'] => ({ target: { value } }) => {
        dispatch(cvInfoActions.projectChanged({ index, field, value }));
    }

    const onAddClick = (position: 'before' | 'after') => () => {
        dispatch(cvInfoActions.projectAdded({ index, position }));
    }

    const onDeleteClick = () => {
        dispatch(cvInfoActions.projectDeleted({ index }));
    }

    return (
      <>
        {index === 0 && <AddButton onClick={onAddClick("before")} />}
        <Card contentClassName="border border-gray-200">
          {shouldRenderDeleteButton && (
              <Box mb={3}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={onDeleteClick}
                  startIcon={<Delete />}
                >
                  Delete
                </Button>
              </Box>
            )}
            {formFields.map((formField, index) => (
              <Box
                mb={index === formFields.length - 1 ? 0 : 2}
                key={formField.field}
              >
                <TextInput
                  label={fieldToLabel[formField.field]}
                  placeholder={fieldToPlaceHolder[formField.field]}
                  multiline={formField.multiline}
                  type={formField.type}
                  onChange={onChangeField(formField.field)}
                  value={project[formField.field]}
                  textInputType={formField.type === 'url' ? 'url' : 'default'}
                />
              </Box>
            ))}
        </Card>
        <AddButton onClick={onAddClick("after")} />
      </>
    );
}

const AddButton: FC<{ onClick: () => void }> = ({ onClick }) => (
    <Box mt={3} mb={3} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <Button variant="outlined" color="primary" onClick={onClick} size='small'>
            Add another project here
        </Button>
    </Box>
);