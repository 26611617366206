import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RequestStatus, Subscription } from '../types';
// react-router history object
import { History } from "history";

export type AuthState = {
    email?: string;
    isPro: boolean;
    loginStatus: RequestStatus,
    signUpStatus: RequestStatus,
    subscriptionLoadStatus: RequestStatus,
    subscriptionCancellationStatus: RequestStatus,
    emailChangeStatus: RequestStatus,
    sendMessageStatus: RequestStatus,
    deleteAccountStatus: RequestStatus,
    error?: string;
    subscription?: Subscription;
    
    signupStep: 0 | 1 | 2,
    signupSelectedPlanId?: string | 'free';
}

const initialState: AuthState = {
    isPro: false,
    loginStatus: 'idle',
    signUpStatus: 'idle',
    subscriptionLoadStatus: 'idle',
    subscriptionCancellationStatus: 'idle',
    emailChangeStatus: 'idle',
    sendMessageStatus: 'idle', 
    deleteAccountStatus: 'idle',
    signupStep: 0,   
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginRequested: (state, _: PayloadAction<{ email: string, password: string }>) => {
            if (state.loginStatus === 'loading')
                return;
            state.loginStatus = 'loading';
            state.error = undefined;
            state.isPro = false;
        },
        loginSucceeded: (state, action: PayloadAction<{ email: string }>) => {
            const { email } = action.payload;
            state.loginStatus = 'success';
            state.email = email;
        },
        loginFailed: (state, action: PayloadAction<{ error: string }>) => {
            const { error } = action.payload;
            state.loginStatus = 'error';
            state.error = error;
            state.isPro = false;
        },

        loginWithSocialRequested: (state, _: PayloadAction<{ provider: 'Google' }>) => {
            state.loginStatus = 'loading';
            state.error = undefined;
            state.isPro = false;
        },

        signUpRequested: (state, _: PayloadAction<{ email: string, password: string, priceId: string | 'free' }>) => {
            if (state.signUpStatus === 'loading')
                return;
            state.signUpStatus = 'loading';
            state.error = undefined;
            state.isPro = false;
        },
        signUpSucceeded: (state, action: PayloadAction<{ email: string }>) => {
            const { email } = action.payload;
            state.signUpStatus = 'success';
            state.email = email;
        },
        signUpFailed: (state, action: PayloadAction<{ error: string }>) => {
            const { error } = action.payload;
            state.signUpStatus = 'error';
            state.error = error;
            state.isPro = false;
        },
        localUserInfoRequested: (state) => {
            state.loginStatus = 'loading';
        },
        localUserInfoSucceeded: (state, action: PayloadAction<{ email: string }>) => {
            const { email } = action.payload;
            state.loginStatus = 'success';
            state.email = email;
        },
        logOutRequested: (state, action: PayloadAction<{ history: History }>) => {},
        logOutSucceeded: (state) => {
            state.loginStatus = 'idle';
            state.signUpStatus = 'idle';
            state.subscriptionLoadStatus = "idle";
            state.subscriptionCancellationStatus = "idle";
            state.emailChangeStatus = "idle";
            state.sendMessageStatus = "idle";
            state.email = undefined;
            state.isPro = false;
            state.subscription = undefined;
        },
        planSelected: (_, action: PayloadAction<{ priceId: string }>) => { },
        getSubscriptionStatusRequested: (state) => {
            state.subscriptionLoadStatus = 'loading';
        },
        getSubscriptionStatusSucceeded: (state, action: PayloadAction<{ subscription?: Subscription }>) => {
            const { subscription } = action.payload;
            state.subscription = subscription;
            state.subscriptionLoadStatus = 'success';
        },
        cancelSubscriptionRequested: (state) => {
            state.subscriptionCancellationStatus = 'loading';
        },
        cancelSubscriptionSucceeded: (state) => {
            state.subscriptionCancellationStatus = 'success';
        },
        cancelSubscriptionFailed: (state) => {
            state.subscriptionCancellationStatus = 'error';
        },
        changeEmailRequested: (state, _: PayloadAction<{ email: string }>) => {
            state.emailChangeStatus = 'loading';
        },
        changeEmailSucceded: (state) => {
            state.emailChangeStatus = 'success';
        },
        changeEmailFailed: (state) => {
            state.emailChangeStatus = 'error';
        },
        sendMessageRequested: (state, _: PayloadAction<{ email: string; name: string; message: string }>) => {
            state.sendMessageStatus = 'loading';
        },
        sendMessageSucceded: (state) => {
            state.sendMessageStatus = 'success';
        },
        sendMessageFailed: (state) => {
            state.sendMessageStatus = 'error';
        },

        signupStepChanged: (state, action: PayloadAction<{ step: AuthState['signupStep'] }>) => {
            state.signupStep = action.payload.step;
        },
        signupSelectedPlanIdChanged: (state, action: PayloadAction<{ id: string | 'free' }>) => {
            state.signupSelectedPlanId = action.payload.id;
        },

        deleteAccountRequested: (state, action: PayloadAction<{ history: History }>) => {
            state.deleteAccountStatus = 'loading';
        },
        deleteAccountSucceded: (state) => {
            state.deleteAccountStatus = 'success';
        },
        deleteAccountFailed: (state) => {
            state.deleteAccountStatus = 'error';
        },

    }
});

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;