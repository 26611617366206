import { FC } from 'react';
import { CheckIcon, CheckCircleIcon, BanIcon } from '@heroicons/react/outline'
import { planIds, stripePlans } from '../../services';

export type SelectPlanProps = {
    selectedPlanId?: string;
    onSelectPlan(planId: string): void;
}

const hobbyFeatures = [
  {
    title: "1 resume",
    isPositive: false,
  },
  {
    title: "Access to all templates",
    isPositive: false,
  },
  {
    title: "DreamCV branding on your resume",
    isPositive: false,
  },
];
const scaleFeatures = [
  "Unlimited resumes",
  "Access to all templates",
  "No branding",
];
const growthFeatures = [
  "30% OFF",
  "Unlimited resumes",
  "Access to all templates",
  "No branding",
];

const monthlyPlan = planIds.monthly;
const yearlyPlan = planIds.annual;
  
export const SelectPlan: FC<SelectPlanProps> = ({ onSelectPlan, selectedPlanId }) => {
    return (
      <div>
        <div className="bg-gray-50 my-10 lg:mt-20 h-full">
          <div className="relative z-0">
            <div className="max-w-7xl bg-gray-50 mx-auto px-4 sm:px-6 lg:px-8">
              <div className="relative lg:grid lg:grid-cols-7">
                <div className="mx-auto max-w-md lg:mx-0 lg:max-w-none lg:col-start-1 lg:col-end-3 lg:row-start-2 lg:row-end-3">
                  <div className="h-full flex flex-col rounded-lg shadow-xl overflow-hidden lg:rounded-none lg:rounded-l-lg">
                    <div className="flex-1 flex flex-col">
                      <div className="bg-white px-6 py-12">
                        <div>
                          <h3
                            className="text-center text-2xl font-medium text-gray-900"
                            id="tier-hobby"
                          >
                            Free Plan
                          </h3>
                          <div className="mt-4 flex items-center justify-center">
                            <span className="px-3 flex items-start text-6xl tracking-tight text-gray-900">
                              <span className="mt-2 mr-2 text-4xl font-medium">
                                €
                              </span>
                              <span className="font-extrabold">0</span>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                        <ul className="space-y-4">
                          {hobbyFeatures.map(({ title, isPositive }) => (
                            <li key={title} className="flex items-start">
                              <div className="flex-shrink-0">
                                <CheckIcon
                                  className={`flex-shrink-0 h-6 w-6 ${isPositive ? 'text-green-500' : 'text-gray-300'}`}
                                  aria-hidden="true"
                                />
                              </div>
                              <p className="ml-3 text-base font-medium text-gray-500">
                                {title}
                              </p>
                            </li>
                          ))}
                        </ul>
                        <div className="mt-8">
                          <div className="rounded-lg shadow-md">
                            <button
                              className="flex flex-row justify-center items-center w-full rounded-lg border border-transparent bg-white px-6 py-3 text-base font-medium text-indigo-600 hover:bg-gray-50 focus:outline-none"
                              aria-describedby="tier-hobby"
                              onClick={() => onSelectPlan('free')}
                            >
                              {selectedPlanId === 'free' && (
                                <CheckCircleIcon className="h-6 w-6 mr-4" />
                              )}
                              Select this plan
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 max-w-lg mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-start-3 lg:col-end-6 lg:row-start-1 lg:row-end-4">
                  <div className="relative z-10 rounded-lg shadow-xl">
                    <div
                      className="pointer-events-none absolute inset-0 rounded-lg border-2 border-indigo-600"
                      aria-hidden="true"
                    />
                    <div className="absolute inset-x-0 top-0 transform translate-y-px">
                      <div className="flex justify-center transform -translate-y-1/2">
                        <span className="inline-flex rounded-full bg-indigo-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">
                          Most popular
                        </span>
                      </div>
                    </div>
                    <div className="bg-white rounded-t-lg px-6 pt-12 pb-10">
                      <div>
                        <h3
                          className="text-center text-3xl font-semibold text-gray-900 sm:-mx-6"
                          id="tier-growth"
                        >
                          Annual
                        </h3>
                        <div className="mt-4 flex items-center justify-center">
                          <span className="px-3 flex items-start text-6xl tracking-tight text-gray-900 sm:text-6xl">
                            <span className="mt-2 mr-2 text-4xl font-medium">
                              €
                            </span>
                            <span className="font-extrabold">5.59</span>
                          </span>
                          <span className="text-2xl font-medium text-gray-500">
                            /month
                          </span>
                        </div>
                        <p className="mt-5 text-lg text-center text-gray-500">
                          Billed yearly (€67)
                        </p>
                      </div>
                    </div>
                    <div className="border-t-2 border-gray-100 rounded-b-lg pt-10 pb-8 px-6 bg-gray-50 sm:px-10 sm:py-12">
                      <ul className="space-y-4">
                        {growthFeatures.map((feature) => (
                          <li key={feature} className="flex items-start">
                            <div className="flex-shrink-0">
                              <CheckIcon
                                className="flex-shrink-0 h-6 w-6 text-green-500"
                                aria-hidden="true"
                              />
                            </div>
                            <p className="ml-3 text-base font-medium text-gray-500">
                              {feature.includes("%") ? (
                                <strong>{feature}</strong>
                              ) : (
                                feature
                              )}
                            </p>
                          </li>
                        ))}
                      </ul>
                      <div className="mt-10 lg:mt-24">
                        <div className="rounded-lg shadow-md">
                          <button
                            className="flex justify-center items-center w-full rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-indigo-700 focus:outline-none"
                            aria-describedby="tier-growth"
                            onClick={() => onSelectPlan(yearlyPlan)}
                          >
                            {selectedPlanId === yearlyPlan && (
                              <CheckCircleIcon className="h-6 w-6 mr-4" />
                            )}
                            Select this plan
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-10 mx-auto max-w-md lg:m-0 lg:max-w-none lg:col-start-6 lg:col-end-8 lg:row-start-2 lg:row-end-3">
                  <div className="h-full flex flex-col rounded-lg shadow-xl overflow-hidden lg:rounded-none lg:rounded-r-lg">
                    <div className="flex-1 flex flex-col">
                      <div className="bg-white px-6 py-10">
                        <div>
                          <h3
                            className="text-center text-2xl font-medium text-gray-900"
                            id="tier-scale"
                          >
                            Monthly
                          </h3>
                          <div className="mt-4 flex items-center justify-center">
                            <span className="px-3 flex items-start text-6xl tracking-tight text-gray-900">
                              <span className="mt-2 mr-2 text-4xl font-medium">
                                €
                              </span>
                              <span className="font-extrabold">7.99</span>
                            </span>
                            <span className="text-xl font-medium text-gray-500">
                              /month
                            </span>
                          </div>
                          <p className="mt-5 text-lg text-center text-gray-500">
                            Billed monthly (€7.99)
                          </p>
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col justify-between border-t-2 border-gray-100 p-6 bg-gray-50 sm:p-10 lg:p-6 xl:p-10">
                        <ul className="space-y-4">
                          {scaleFeatures.map((feature) => (
                            <li key={feature} className="flex items-start">
                              <div className="flex-shrink-0">
                                <CheckIcon
                                  className="flex-shrink-0 h-6 w-6 text-green-500"
                                  aria-hidden="true"
                                />
                              </div>
                              <p className="ml-3 text-base font-medium text-gray-500">
                                {feature.includes("%") ? (
                                  <strong>{feature}</strong>
                                ) : (
                                  feature
                                )}
                              </p>
                            </li>
                          ))}
                        </ul>
                        <div className="mt-8">
                          <div className="rounded-lg shadow-md">
                            <button
                              className="flex justify-center items-center w-full rounded-lg border border-transparent bg-white px-6 py-3 text-base font-medium text-indigo-600 hover:bg-gray-50 focus:outline-none"
                              aria-describedby="tier-scale"
                              onClick={() => onSelectPlan(monthlyPlan)}
                            >
                              {selectedPlanId === monthlyPlan && (
                                <CheckCircleIcon className="h-6 w-6 mr-4" />
                              )}
                              Select this plan
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}