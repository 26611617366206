import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, TypographyProps } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        flexDirection: "column",
        justifyContent: 'center',
    },
    textField: {
        display: 'flex',
    }
  }));

export const Header1: FC<TypographyProps> = (props) => {
    const classes = useStyles();
    return (
        <Typography variant="h1" {...props} />
    );
}

export const Header2: FC<TypographyProps> = (props) => {
    const classes = useStyles();
    return (
        <Typography variant="h3" {...props} />
    );
}

export const Header3: FC<TypographyProps> = ({ children, ...props }) => {
    const classes = useStyles();
    return (
        <h1 className="place-self-center md:place-self-start text-3xl font-bold leading-tight text-gray-900">
            {children}
        </h1>
    );
}