import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { NavBar } from "./NavBar";
import { Footer } from "./Footer";

import { Login, SignupFlow } from '../screens/Auth';
import { Pricing } from '../screens/Pricing';
import { Success } from '../screens/Success';
import { Settings } from '../screens/Settings/Settings';
import { Contact } from '../screens/Contact/Contact';
import { Home } from '../screens/Home/Home';
import { Dashboard } from "../screens/Dashboard/Dashboard";
import { About } from "../screens/About/About";
import { Privacy } from "../screens/Privacy/Privacy";
import { Terms } from "../screens/Terms/Terms";
import { ResumeScreen } from "../screens/Generator/ResumeScreen";
import { authActions } from "../store/auth";
import { ScrollToTop } from "./ScrollToTop";

export const MainRouter = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(authActions.localUserInfoRequested())
  }, [dispatch]);

  return (
    <Router>
      <ScrollToTop />
      <NavBar />
      <div>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/signup">
            <SignupFlow />
          </Route>
          <Route path="/pricing">
            <Pricing />
          </Route>
          <Route path="/success">
            <Success />
          </Route>
          <Route path="/account">
            <Settings />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/resumes/:id">
            <ResumeScreen />
          </Route>
          <Route path="/resumes">
            <Dashboard />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </div>
      <Footer />
    </Router>
  );
}