import React, { FC, useEffect, useState, Fragment, useRef, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationIcon } from '@heroicons/react/outline'
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router';
import clsx from 'clsx';

import { RootState } from '../../store';
import { authActions } from '../../store/auth';
import { Header3 } from '../../components/Header';
import { RequestStatus, Subscription } from '../../types';
import { Body1, Button, Card, LoadingIndicator, TextInput } from '../../components';
import { useRedirectLoggedOutToHome } from "../../hooks";
import { DeleteAccountModal } from './DeleteAccountModal';


const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        flexDirection: "column",
        justifyContent: 'center',
        marginTop: theme.spacing(4)
    },
    title: {
        marginLeft: theme.spacing(4),
    },
    textField: {
        display: 'flex',
    },
    rowSpaceBetween: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    marginTop2: {
        marginTop: theme.spacing(2)
    }
  }));

export const Settings = () => {
    useRedirectLoggedOutToHome();
    const history = useHistory();
    const deleteAccountStatus = useSelector((state: RootState) => state.auth.deleteAccountStatus);
    const email = useSelector((state: RootState) => state.auth.email);
    const subscription = useSelector((state: RootState) => state.auth.subscription);
    const subscriptionLoadStatus = useSelector((state: RootState) => state.auth.subscriptionLoadStatus);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isDeleteDialogueOpen, setIsDeleteDialogueOpen] = useState(false);

    const onDeleteAccountClick = () => {
      setIsDeleteDialogueOpen(true);
    }

    const onConfirmAccountDeletion = () => {
      setIsDeleteDialogueOpen(false);
      dispatch(authActions.deleteAccountRequested({ history }));
    }

    const onCloseDeleteAccountDialogue = () => {
      setIsDeleteDialogueOpen(false);
    }

    return (
        <div className="flex justify-center w-full bg-gray-50 min-h-screen">
            <div className="mt-20 mb-20 max-w-6xl px-4">
                <Header3 className={classes.title}>
                    Account Settings
                </Header3>
                <Box mt={4}>
                    <Card title="Plan">
                        <SubscriptionComponent subscription={subscription} loadStatus={subscriptionLoadStatus} />
                    </Card>
                </Box>
                <Box mt={4}>
                    <Card title="Account">
                        <TextInput className="text-gray-500" value={email} label="email" disabled={true} />
                        <div className={clsx(classes.rowSpaceBetween, classes.marginTop2)}>
                            <div />
                            <Button 
                                variant="text" 
                                onClick={() => {
                                    history.push("/contact");
                                }} 
                                className="text-indigo-600"
                            >
                                Contact support to change email
                            </Button>
                        </div>
                    </Card>
                </Box>
                {/* <Box mt={4}>
                    <Card title="Email notifications">
                        <div className={classes.rowSpaceBetween}>
                            <div>
                                <Body1>
                                    Updates and Offers
                                </Body1>
                                <Body2 color="textSecondary">
                                    Discounts, special offers, new features and more
                                </Body2>
                            </div>
                            <Switch />
                        </div>
                        <div className={clsx(classes.rowSpaceBetween, classes.marginTop2)}>
                            <div />
                            <Button color="primary">
                                Save
                            </Button>
                        </div>
                    </Card>
                </Box> */}
                <Box mt={4}>
                    <Card title="Danger zone" titleColor="">
                        <div className="flex flex-row justify-between items-start">
                            <Body1 color="textSecondary">
                            Once you delete your account, it cannot be undone. All of your data, including your subscriptions and resumes, will be deleted from our system.
                            </Body1>
                            <Button variant="text" className="text-red-600 ml-4 lg:h-10 hover:text-red-700" onClick={onDeleteAccountClick}>
                                Delete account
                            </Button>
                        </div>
                    </Card>
                </Box>
            </div>
            <DeleteAccountModal open={isDeleteDialogueOpen} onConfirm={onConfirmAccountDeletion} onClose={onCloseDeleteAccountDialogue} isLoading={deleteAccountStatus === 'loading'} />
        </div>
    )
}


const SubscriptionComponent: FC<{ subscription?: Subscription, loadStatus: RequestStatus }> = ({ subscription, loadStatus }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const isLoadded = loadStatus === "success";
    const [isCancellationDialogueOpen, setIsCancellationDialogueOpen] = useState(false);
    const { isSnackBarOpen, setIsSnackBarOpen } = useSnackbar();
    const cancellationStatus = useSelector((state: RootState) => state.auth.subscriptionCancellationStatus);
    useEffect(() => {
        if (cancellationStatus === 'success' || cancellationStatus === 'error') {
            setIsCancellationDialogueOpen(false);
            setIsSnackBarOpen(true);
        }
    }, [cancellationStatus]);

    const onButtonClick = () => {
        const buttonTitle = getSubscriptionButtonTitle(subscription);
        if (buttonTitle === 'Cancel') {
            setIsCancellationDialogueOpen(true);
        } else {
            history.push('/pricing');
        }   
    }

    const onConfirmCancellation = () => {
        dispatch(authActions.cancelSubscriptionRequested());
    }

    const onCancelCancellation = () => {
        setIsCancellationDialogueOpen(false);
    }
    
    return (
      <div className={classes.rowSpaceBetween}>
        <div className="flex items-center">
          <p className="text-3xl mr-4">
            {isLoadded ? (
                getSubscriptionEmoji(subscription)
            ) : (
                <div className="animate-pulse rounded-lg mb-1 h-10 w-10 pr-5 bg-gray-300" />
            )
            }
          </p>
          <div className="w-full">
            <h2 className="text-md font-medium text-gray-900">
              {isLoadded ? (
                getSubscriptionTitle(subscription)
              ) : (
                <div className="animate-pulse rounded-lg mb-1 h-7 w-2/3 sm:w-1/3 pr-5 bg-gray-300" />
              )}
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              {isLoadded ? (
                getSubscriptionSubtitle(subscription)
              ) : (
                <div className="animate-pulse rounded-lg mb-2 h-6 w-screen-1/6 sm:w-screen-2/5 pr-5 bg-gray-300" />
              )}
            </p>
          </div>
        </div>
        {isLoadded ? (
            <Button
                variant={
                getSubscriptionButtonTitle(subscription) === "Cancel"
                    ? "text"
                    : "contained"
                }
                onClick={onButtonClick}
                color={getSubscriptionButtonColor(subscription)}
                className={
                getSubscriptionButtonTitle(subscription) === "Cancel"
                    ? "text-red-600 ml-4 lg:h-10 hover:text-red-700 h-10"
                    : "ml-4 h-10"
                }
            >
                {getSubscriptionButtonTitle(subscription)}
            </Button>
        ) : (
            <div className="animate-pulse rounded-lg mb-2 h-6 w-14 bg-gray-300" />
        )}
        <CancelSubscriptionDialogue 
            open={isCancellationDialogueOpen} 
            title="Are you sure about cancelling your PRO subscription?" 
            message={`If you cancel your subscription now, you will lose access to PRO features after ${new Date(
                subscription?.end_date || 0
              ).toLocaleDateString()}.`} 
            onClose={onCancelCancellation}
            onConfirm={onConfirmCancellation}
            isLoading={cancellationStatus === "loading"}
        />
        <Snackbar
          open={isSnackBarOpen}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={cancellationStatus as "success" | "error"}>
            Successfully cancelled your subscription
          </Alert>
        </Snackbar>
      </div>
    );
}

const useSnackbar = () => {
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (isSnackBarOpen) {
            timeout = setTimeout(() => {
                setIsSnackBarOpen(false);
            }, 2000);
        }

        return () => {
            clearTimeout(timeout);
        };
    }, [isSnackBarOpen]);

    return {
        isSnackBarOpen,
        setIsSnackBarOpen
    };
}

const getSubscriptionEmoji = (subscription?: Subscription): string => {
    if (!subscription)
        return "🧢";
    if (subscription.status === 'canceled' && new Date() > new Date(subscription.end_date))
        return "🧢";
    
    return "👑";
};

const getSubscriptionTitle = (subscription?: Subscription): string => {
    if (!subscription)
        return 'Free';
    if (subscription.status === 'canceled' && new Date() > new Date(subscription.end_date))
        return 'Free';
    
    let title;
    switch (subscription.interval) {
        case 'month':
            title = `Monthly Plan - €${subscription.price}/Month`;
            break;
        case 'year':
            title = `Yearly Plan - €${subscription.price}/Year`;
            break;
    }

    return title + (subscription.status === 'canceled' ? ' (Cancelled)' : '');
};

const getSubscriptionSubtitle = (subscription?: Subscription) => {
    if (!subscription)
        return 'You are on the free plan. You can create & save resumes with watermarks. Upgrade for PDF downloads without watermarks.';
    if (subscription.status === 'canceled' && new Date() > new Date(subscription.end_date))
        return 'You are on the free plan. You can create & save resumes with watermarks. Upgrade for PDF downloads without watermarks.';
    if (subscription.status === 'canceled') {
        switch (subscription.interval) {
            case 'month':
                return `You have cancelled your subscription. You will have access to the pro features until ${new Date(subscription.end_date).toLocaleDateString()}.`;
            case 'year':
                return `You have cancelled your subscription. You will have access to the pro features until ${new Date(subscription.end_date).toLocaleDateString()}.`;
        }    
    }
    
    switch (subscription.interval) {
        case 'month':
            return `Your subscription will automatically renew each month. The next billing date is ${new Date(subscription.end_date).toLocaleDateString()}. You can cancel anytime.`;
        case 'year':
            return `Your subscription will automatically renew each year. The next billing date is ${new Date(subscription.end_date).toLocaleDateString()}. You can cancel anytime.`;
    }
}

const getSubscriptionButtonTitle = (subscription?: Subscription): 'Cancel' | 'Upgrade' => isSubscriptionActive(subscription) ? 'Cancel' : 'Upgrade';

const getSubscriptionButtonColor = (subscription?: Subscription): 'primary' | 'secondary' => isSubscriptionActive(subscription) ? 'secondary' : 'primary';

const isSubscriptionActive = (subscription?: Subscription) =>
  subscription?.status === "active" || subscription?.status === "incomplete";

const CancelSubscriptionDialogue: React.FC<{ open: boolean, onClose(): void, onConfirm(): void, title: string, message: string, isLoading: boolean }> = ({ open, onClose, onConfirm, title, message, isLoading }) => {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6">
              <div>
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {message}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:ml-3 sm:text-sm"
                  onClick={onConfirm}
                >
                 {isLoading && <LoadingIndicator className="mr-3"/>}
                  Yes, cancel my subscription
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:text-sm"
                  onClick={onClose}
                  ref={cancelButtonRef}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}