import { FC, useEffect, useState } from 'react';
import { Box, Chip, makeStyles, TextFieldProps } from '@material-ui/core';
import { Transition } from "@headlessui/react";

import { Card } from "./Card";
import { TextInput } from "./TextInput";

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
        flexDirection: "column",
        justifyContent: 'center',
    },
    textField: {
        display: 'flex',
        marginTop: theme.spacing(1)
    },
    chip: {
        margin: theme.spacing(0.5)
    }
  }));

type ChipsAndInputProps = {
  title: string;
  key?: string;
  placeholder?: string;
  chipsData: string[];
  onDeleteItem: (index: number) => void;
  onAddItem: (item: string) => void;
};

export const ChipsAndInput: FC<ChipsAndInputProps> = ({
  key,
  title,
  placeholder,
  chipsData,
  onAddItem,
  onDeleteItem,
}) => {
  const [showHint, setShowHint] = useState(false);
  const [currentValue, setCurrentValue] = useState("");
  const classes = useStyles();
  useEffect(() => {
    setShowHint(!!currentValue);
  }, [currentValue]);

  const onKeyPress: TextFieldProps["onKeyPress"] = (event) => {
    const value = (event.target as any).value;
    if (event.key === "Enter" && value) {
      onAddItem(value);
      setCurrentValue("");
    }
  };

  const onChange: TextFieldProps["onChange"] = ({ target: { value } }) => {
    setCurrentValue(value);
  };

  const onDelete = (index: number) => () => {
    onDeleteItem(index);
  };

  return (
    <Card title={title} contentClassName="border border-gray-200">
      {!!chipsData.length && (
        <Box mb={3}>
          {chipsData.map((data, index) => (
            <Chip
              key={index}
              label={data}
              className={classes.chip}
              onDelete={onDelete(index)}
            />
          ))}
        </Box>
      )}
      <Transition
        show={showHint}
        enter="transform transition duration-[400ms]"
        enterFrom="opacity-0 -translate-y-5"
        enterTo="opacity-100 translate-y-0"
        leave="transform duration-300 transition ease-in-out"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-0"
      >
        <div className="flex items-center">
          <EnterKey /> <p className="ml-2 text-sm text-gray-500"> to add a new item </p>
        </div>
      </Transition>
      <TextInput
        className={"mt-2"}
        key={key}
        placeholder={placeholder}
        onChange={onChange}
        onKeyPress={onKeyPress}
        value={currentValue}
      />
    </Card>
  );
};

const EnterKey = () => (
  <div className="py-0.5 px-1.5 max-w-min shadow-lg rounded-sm bg-gradient-to-tr from-gray-100 to-gray-200" style={{ boxShadow: "inset 0 -2px 0 0 #cdcde6, inset 0 0 1px 1px #fff, 0 1px 2px 1px rgb(30 35 90 / 40%)" }}>
    <svg width="15" height="15"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2"><path d="M12 3.53088v3c0 1-1 2-2 2H4M7 11.53088l-3-3 3-3"></path></g></svg>
  </div>
)